<template>
  <div>
    <ContactForm :contact="editedContact" :contactCategories="contactCategories" @update:value="contactValue = $event"></ContactForm>
    <v-btn color="info" @click.native="save">Edit contact</v-btn>
  </div>
</template>

<script>

  import {mapState, mapActions, mapGetters} from 'vuex'
  import ContactForm from './ContactForm'

  export default {
    name: 'ContactEdit',
    components: {
      ContactForm
    },
    mixins: [],
    props: {
      id: {}
    },
    data() {
      return {
        contactValue: {}
      }
    },
    computed: {
      ...mapState('contactCategory', ['contactCategories']),
      ...mapState('contact', ['contacts', 'editedContact']),
    },
    watch: {},
    async created() {},
    mounted() {
      this.fetchContact(this.id)
      !this.contactCategories.length ? this.getContactCategories() : false
    },
    methods: {
      async save(data) {
        this.loading = true
        const result = await this.patchContact(this.contactValue)

        if (result) {
          this.$router.push({'name': 'contacts-index'})
        } else {
          this.$validator.validateAll();
        }
        this.loading = false
      },
      ...mapActions('contact', ['patchContact', 'fetchContact']),
      ...mapActions('contactCategory', ['getContactCategories']),
    }
  }
</script>

<style></style>
