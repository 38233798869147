<template>
	<div class="container">
    <div class="row">
      <div class="col-md-12">
        <v-card>
          <v-card-title>
            Tours
          </v-card-title>
          <v-card-text>
            <div v-for="tour in tours">
              {{ tour.id }} | <b><router-link :to="{'name': 'tour-edit', params: {id: tour.id}}">{{ tour.name }}</router-link></b>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr>
        <v-btn :to="{'name': 'tour-create'}" color="info">Create tour</v-btn>
      </div>
    </div>
	</div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

	export default {
		name: 'ToursIndex',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {}
		},
    computed: {
      ...mapState('tour', ['tours']),
    },
    methods: {
		  ...mapActions('tour', ['GET_TOURS'])
    },
    created() {
      this.GET_TOURS()
    }
	}
</script>

<style></style>
