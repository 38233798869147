import Dashboard from '../components/Dashboard'
import Error403 from '../components/Misc/Error403'
import Error404 from '../components/Misc/Error404'

//Calendars
import CalendarIndex from '../components/Calendar/CalendarIndex'
import CalendarList from '../components/Calendar/CalendarList'

//Tours
import TourIndex from '../components/Tours/TourIndex'
import TourCreate from '../components/Tours/TourCreate'
import TourEdit from '../components/Tours/TourEdit'

import CityIndex from '../components/Cities/CityIndex'
import CityCreate from '../components/Cities/CityCreate'
import CityEdit from '../components/Cities/CityEdit'

import SettingsIndex from '../components/Settings/SettingsIndex'
import ContactsIndex from "../components/Contacts/ContactsIndex";
import ContactCreate from "../components/Contacts/Items/ContactCreate";
import ContactEdit from "../components/Contacts/Items/ContactEdit";

// Tracks
import TracksShareEmail from '../components/Artists/Tracks/Share/EmailTo';
import BudgetIndex from "../components/Budgets/BudgetIndex";
import BudgetCreate from "../components/Budgets/BudgetCreate";
import BudgetShow from "../components/Budgets/BudgetShow";
import UserIndex from "../components/Users/UserIndex";
import UserCreate from "../components/Users/UserCreate";
import UserEdit from "../components/Users/UserEdit";
import BudgetEdit from "../components/Budgets/BudgetEdit";
import ProjectIndex from "../components/Projects/ProjectIndex";
import ProjectCreate from "../components/Projects/ProjectCreate";
import ProjectShow from "../components/Projects/ProjectShow";
import EarningIndex from "../components/Earnings/EarningIndex";

import ChecklistsTemplates from "../components/Checklists/Templates";
import ChecklistsTemplateCreate from "../components/Checklists/TemplateCreate";
import ChecklistsTemplateShow from "../components/Checklists/TemplateShow";
import ChecklistsTemplateEdit from "../components/Checklists/TemplateEdit";

export default [
  {path: '/', component: Dashboard, name: 'dashboard'},
  {
    name: '403',
    path: '/403',
    component: Error403,
  },
  {
    name: 'calendar-index',
    path: '/calendars',
    component: CalendarIndex,
    meta: {
      title: 'Calendar Events',
    },
  },
  {
    name: 'calendar-list',
    path: '/calendars/list/:id?',
    component: CalendarList,
    meta: {
      title: 'Calendar Todo',
    },
  },
	{
    name: 'tour-index',
    path: '/tours',
    component: TourIndex,
    meta: {
      title: 'Tours',
    },
	},
  {
    name: 'tour-create',
    path: '/tours/create',
    component: TourCreate,
    meta: {
      title: 'New Tour',
    },
  },
  {
    name: 'tour-edit',
    path: '/tours/:id/edit',
    component: TourEdit,
    props: true,
    meta: {
      title: 'Edit Tour',
    },
  },
  {
    name: 'city-index',
    path: '/cities',
    component: CityIndex,
    meta: {
      title: 'Cities Index',
    },
  },
  {
    name: 'city-create',
    path: '/cities/create',
    component: CityCreate,
    meta: {
      title: 'New City',
    },
  },
  {
    name: 'city-edit',
    path: '/cities/:id/edit',
    component: CityEdit,
    props: true,
    meta: {
      title: 'Edit City',
    },
  },
  {
    name: 'earning-index',
    path: '/earnings',
    component: EarningIndex,
    meta: {
      title: 'Earnings Index',
    },
  },
  {
    name: 'budget-index',
    path: '/budgets',
    component: BudgetIndex,
    meta: {
      title: 'Budgets Index',
    },
  },
  {
    name: 'budget-create',
    path: '/budgets/create',
    component: BudgetCreate,
    meta: {
      title: 'New Budget',
    },
  },
  {
    name: 'budget-show',
    path: '/budgets/:id',
    component: BudgetShow,
    props: true,
    meta: {
      title: 'Budget',
    },
  },
  {
    name: 'budget-edit',
    path: '/budgets/:id/edit',
    component: BudgetEdit,
    props: true,
    meta: {
      title: 'Budget',
    },
  },
  {
    name: 'user-index',
    path: '/users',
    component: UserIndex,
    meta: {
      title: 'Users Index',
    },
  },
  {
    name: 'user-create',
    path: '/users/create',
    component: UserCreate,
    meta: {
      title: 'New User',
    },
  },
  {
    name: 'user-edit',
    path: '/users/:id/edit',
    component: UserEdit,
    props: true,
    meta: {
      title: 'Edit User',
    },
  },
  {
    name: 'checklists-templates',
    path: '/checklists/templates',
    component: ChecklistsTemplates,
    meta: {
      title: 'Projects Index',
    },
  },
  {
    name: 'checklists-template-create',
    path: '/checklists/templates/create',
    component: ChecklistsTemplateCreate,
    meta: {
      title: 'New Template',
    },
  },
  {
    name: 'checklists-template-show',
    path: '/checklists/templates/:id',
    component: ChecklistsTemplateShow,
    props: true,
    meta: {
      title: 'Template',
    },
  },
  {
    name: 'checklists-template-edit',
    path: '/checklists/templates/:id/edit',
    component: ChecklistsTemplateEdit,
    props: true,
    meta: {
      title: 'Template Edit',
    },
  },
  {
    name: 'project-index',
    path: '/projects',
    component: ProjectIndex,
    meta: {
      title: 'Projects Index',
    },
  },
  {
    name: 'project-create',
    path: '/projects/create',
    component: ProjectCreate,
    meta: {
      title: 'New Project',
    },
  },
  {
    name: 'project-show',
    path: '/projects/:id',
    component: ProjectShow,
    props: true,
    meta: {
      title: 'Project',
    },
  },
  {
    name: 'contacts-index',
    path: '/contacts',
    component: ContactsIndex,
    meta: {
      title: 'Contacts Index',
    },
  },
  {
    name: 'contacts-create',
    path: '/contacts/create',
    component: ContactCreate,
    meta: {
      title: 'New Contact',
    },
  },
  {
    name: 'contacts-edit',
    path: '/contacts/:id/edit',
    component: ContactEdit,
    props: true,
    meta: {
      title: 'Edit Contact',
    },
  },
  {
    name: 'settings-Index',
    path: '/settings',
    component: SettingsIndex,
    meta: {
      title: 'Settings',
    },
  },
  {
    name: 'tracks-share-email',
    path: '/tracks/:track_id/share/email/:contact_category?',
    component: TracksShareEmail,
    props: true,
    meta: {
      title: 'Share Track',
    },
  },
  {
    name: '404',
    path: '/404',
    component: Error404,
    meta: {
      title: '404 Not Found',
    },
  },
  {
    name: 'catch-all',
    path: '*',
    component: Error404,
  },

]
