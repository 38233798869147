<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12" v-if="loading">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>

      <div class="col-md-12">
        <v-alert
          v-model="status.visible"
          dismissible
          :type="status.error ? 'error' : 'success'"
        >
          {{ status.message }}
        </v-alert>
        <br>
      </div>

      <div class="col-sm-8 order-xs2 order-sm1">
        <v-card>
          <v-card-title>Share track</v-card-title>
          <v-card-text>
            <div v-if="track">
            <b>TITLE:</b> <i>new single: {{track.title}}</i> <br>
            <hr>
            <TipTapEditor :content.sync="message"></TipTapEditor>

            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-sm-4 order-xs1 order-sm2">
        <v-card>
          <v-card-title>Send To</v-card-title>
          <v-card-text>
            <v-select
              attach
              :items="contactCategories"
              v-model="contact_id"
              item-text="name"
              label="Category"
              item-value="id"
              :error-messages="errors.collect('contact_id')"
            >
            </v-select>

            <v-btn block @click="save" color="primary" :loading="loading">Send</v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
	</div>
</template>

<script>
  import TipTapEditor from '../../../Misc/TipTapEditor'
  import {mapState, mapActions, mapGetters} from 'vuex'
	export default {
		name: 'TrackEmailTo',
		components: {
      TipTapEditor
    },
		mixins: [],
		props: {
		  track_id: {
		    required: true
      },
      contact_category: {},
    },
		data() {
			return {
			  track: false,
        contact_id: false,
        loading: false,
        status: {
			    error: false,
			    visible: false,
          message: ''
        },
        message: '',
      }
		},
		computed: {
      ...mapGetters('track', ['getTrackById']),
      ...mapState('contactCategory', ['contactCategories']),
    },
		watch: {
		  'contact_id': function (newVal, oldVal) {
		    if (oldVal) {
          this.initialMessage()
        }
      }
    },
		mounted() {
      this.contact_id = parseInt(this.contact_category)
      this.init()
      !this.contactCategories.length ? this.getContactCategories() : false
		},
		methods: {
		  async init() {
        const track = await this.fetchTrackById(this.track_id)
        this.track = track.track
        this.initialMessage()
      },
      initialMessage() {
		    const CONTACT_TYPE_TV = 2;

        let message = `Здравейте, <br>
        изпращам новия ${this.contact_id == CONTACT_TYPE_TV ? 'видео клип' : 'сингъл'} '${this.track.title}' на
        ${ this.track.artists.length > 0 ? this.track.artists[0].artist_name : '' }. <br>
        Можете да го изтеглите като кликнете на този линк: <br>

        ${this.contact_id == CONTACT_TYPE_TV ?
          `<a href="${this.track.url_video_tv}" target="_blank">${this.track.url_video_tv}</a> <br>` :
          `<a href="${this.track.url_wave}" target="_blank">${(this.track.url_wave && this.track.url_wave.length > 0) ? this.track.url_wave.substr(0, 80) : '-'}</a> <br>`
        }

        ${ this.track.url_youtube ?
        `<br>Можете да гледате клипа в канала ни в YouTube: <br>
        https://youtu.be/${this.track.url_youtube}` : ''}
        <br><br> Мета данни: <br>
        ${this.track.description ? this.track.description : ''}
        Audio ISRC: ${this.track.audio_isrc} <br>
        Video ISRC: ${this.track.video_isrc} <br> <br>
        Поздрави, <br>
        Пламен <br><br>
        Facing The Sun Ltd. <br>
        Knyaz Boris I 121 <br>
        1301 Sofia, Bulgaria <br>
        info@facingthesun.eu <br>
        0882 211 511`;

        this.message = message
      },
      async save() {
        this.loading = true;
        const data = {
          message: this.message,
          track_id: this.track_id,
          contact_id: this.contact_id
        }

        try {
          const response = await this.$http.post('/api/tracks/email-share', data)
          this.status.visible = true
          this.status.error = false
          this.status.message = "Mails sent"
        } catch($e) {
          this.status.visible = true
          this.status.error = true
          this.status.message = $e.response.data.message
        }

        this.loading = false;
      },
      ...mapActions('track', ['fetchTrackById']),
      ...mapActions('contactCategory', ['getContactCategories']),
    }
	}
</script>

<style></style>
