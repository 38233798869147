<template>
  <div>
      <div class="row">
        <div class="col-md-12">
          <v-checkbox
            class="mt-0 mb-0"
            v-model="item.status"
            :label="item.title"
            @click="$emit('updateStatus', item.id)"
          ></v-checkbox>
          <div class="ml-10 mt-0 mb-0" v-for="(sub, index) in item.subs">
            <ShowChecklistItem :item="sub" @updateStatus="emitUpdateChild"></ShowChecklistItem>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ShowChecklistItem from "./ShowChecklistItem"

export default {
  name: "ShowChecklistItem",
  components: {
    ShowChecklistItem
  },
  mixins: [],
  props: {
    item: {}
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {},
  mounted() {
  },
  methods: {
    emitUpdateChild(id) {
      this.$emit('updateStatus', id)
    }
  }
}
</script>

<style scoped>

</style>
