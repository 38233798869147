import { checklistResource } from '@/api/API'
export const namespaced = true

export const state = {
  checklists: [],
  checklistsTotal: 0,
  checklist: {}
}

export const mutations = {
  ADD_CHECKLIST(state, checklist) {
    state.checklists.push(checklist)
  },
  SET_CHECKLISTS(state, checklists) {
    state.checklists = checklists
  },
  REMOVE_CHECKLIST(state, checklist) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeChecklist({ state, commit, rootState }, checklist) {
    const status = await checklistResource.create({ data: checklist })

    if (status) {
      commit('ADD_CHECKLIST', checklist)
    }

    return status
  },
  async deleteChecklist({ state }, id) {
    return await checklistResource.remove({params: {id: id}})
  },
  async getChecklists({ state, commit, rootState }) {
    const checklists = await checklistResource.read()
    if (checklists) commit('SET_CHECKLISTS', checklists.data)
  },
  async fetchChecklist({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await checklistResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateChecklist({ state, commit, rootState }, checklist) {
    const status = await checklistResource.update({ data: checklist, params: {id: checklist.id}})
    return status
  }

}

export const getters = {
  checklists: state => state.checklists
}
