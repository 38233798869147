import { projectResource } from '@/api/API'
export const namespaced = true

export const state = {
  projects: [],
  projectsTotal: 0,
  project: {}
}

export const mutations = {
  ADD_PROJECT(state, project) {
    state.projects.push(project)
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  REMOVE_PROJECT(state, project) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeProject({ state, commit, rootState }, project) {
    const status = await projectResource.create({ data: project })

    if (status) {
      commit('ADD_PROJECT', project)
    }

    return status
  },
  async deleteProject({ state }, id) {
    return await projectResource.remove({params: {id: id}})
  },
  async getProjects({ state, commit, rootState }) {
    const projects = await projectResource.read()
    if (projects) commit('SET_PROJECTS', projects.data)
  },
  async fetchProject({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await projectResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateProject({ state, commit, rootState }, project) {
    const status = await projectResource.update({ data: project, params: {id: project.id}})
    return status
  }

}

export const getters = {
  projects: state => state.projects
}
