<template>
  <div class="container">
    <v-card>
      <v-card-title>Project</v-card-title>
      <v-card-text>
        <v-text-field
          label="Project name"
          v-model="project.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-autocomplete attach v-model="project.client_id" :items="companies" item-text="company_name"
                        item-value="id" label="Client"></v-autocomplete>

        <v-autocomplete attach v-model="project.contractor_id" :items="companies" item-text="company_name"
                        item-value="id" label="Contractor"></v-autocomplete>

        <v-text-field
          label="Transaction ID"
          v-model="project.transaction_id"
          v-validate="'required|max:200'"
          data-vv-name="transaction_id"
          :error-messages="errors.collect('transaction_id')"
        ></v-text-field>

        <v-textarea
          label="Tags"
          v-model="project.tags"
          v-validate="'required|max:200'"
          data-vv-name="tags"
          :error-messages="errors.collect('tags')"
        ></v-textarea>

        <v-text-field
          label="Documents Folder"
          v-model="project.documents_folder"
          v-validate="'required|max:200'"
          data-vv-name="documents_folder"
          :error-messages="errors.collect('documents_folder')"
        ></v-text-field>

        <v-textarea
          label="Deal Memo"
          v-model="project.deal_memo"
          data-vv-name="deal_memo"
          :error-messages="errors.collect('deal_memo')"
        ></v-textarea>

        <v-autocomplete attach v-model="project.budget_id" :items="budgets" item-text="title"
                        item-value="id" label="Budget"></v-autocomplete>

        <v-autocomplete
          v-model="project.artists"
          :items="artists"
          item-text="artist_name"
          item-value="id"
          label="Select Artists"
          multiple
          chips
          :return-object="false"
        ></v-autocomplete>

        <v-btn color="info" @click.native="save" :loading="loading">Add Project</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'ProjectCreate',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        project: {
          name: '',
          client_id: '',
          contractor_id: '',
          transaction_id: '',
          tags: '',
          documents_folder: '',
          deal_memo: '',
          artists: []
        },
        loading: false
      }
    },
    computed: {
      ...mapState('errors', ['errors']),
      ...mapState('company', ['companies']),
      ...mapState('artist', ['artists']),
      ...mapState('budget', ['budgets']),
    },
    watch: {},
    mounted() {
      !this.companies.length ? this.getCompanies() : false
      !this.artists.length ? this.getArtists() : false
      !this.budgets.length ? this.getBudgets() : false
    },
    methods: {
      async save() {
        this.loading = true
        const result = await this.storeProject(this.project)
        if (result) {
          this.$router.push({'name': 'project-show', params: {id: result.data.id}})
        }
        this.loading = false
      },
      ...mapActions('project', ['storeProject']),
      ...mapActions('company', ['getCompanies']),
      ...mapActions('artist', ['getArtists']),
      ...mapActions('budget', ['getBudgets']),
    }
  }
</script>

<style></style>
