<template>
	<div>
		<v-card>
			<v-card-title class="justify-content-between">
				Remainder Payment

				<div>
					<v-switch
							v-model="manualMode"
							:label="`Manual mode: ${manualMode.toString()}`"
					></v-switch>
				</div>

			</v-card-title>
			<v-card-text>
				<b>Amount: {{ dataFromStep1.remainder_payment.fee }}</b>
				<b>Payment method: {{ dataFromStep1.remainder_payment.fee == 1 ? 'Bank' : 'Cash' }}</b>

				<table class="table table-hover">
					<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">Rate</th>
						<th scope="col">Amount</th>
						<th scope="col">Advance</th>
						<th scope="col">Remainder</th>
					</tr>
					</thead>
					<tbody>
					<tr v-if="event.agent">
						<th scope="row">Booking Agent | {{ event.agent.agent_name }}</th>

						<template v-if="event.agent_fee">
							<td>{{ event.agent_fee.fee }} {{ event.agent_fee.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
						</template>
						<template v-else>
							<td>{{ event.agent.share_shows }} {{ event.agent.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
						</template>

						<td>{{ agentFee }}</td>
						<td> {{ agent_advance.fee }} </td>
						<td>
							<v-text-field
									label="Fee"
									v-model.number="remainder.agent.value"
							></v-text-field>
						</td>
					</tr>

					<tr v-if="dataFromStep1.promoter.paymentType == promoterPaymentTypes.INCLUSIVE && ((agentFee - remainder.agent.value) < promoterFee)">
						<td colspan="5" class="text-center">
							<v-chip color="red" text-color="white">(Promoter Payment Included) Not enough money for Promoter</v-chip>
						</td>
					</tr>

					<tr v-if="event.promoter">
						<th scope="row">Promoter | {{ event.promoter.agent_name }}</th>
						<td>{{ event.promoter_fee.fee }} {{ event.promoter_fee.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
						<td>{{ promoterFee }}</td>
						<td> {{ promoter_advance.fee }} </td>
						<td>
							<v-text-field
									label="Fee"
									v-model.number="remainder.promoter.value"
							></v-text-field>
						</td>
					</tr>



					<tr>
						<td colspan="5" style="text-align: center; font-weight: bold;">NON Permanent Crew</td>
					</tr>
					<tr v-for="(member, index) in _.filter(crew, ['role', roleTypes.NONPERMANENT])">
							<th scope="row">{{ member.position }} | {{ member.member.artist_name }}</th>
							<td>{{ member.fee }} {{ member.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
							<td>{{ member.fee }} {{ member.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
							<td>
								{{ member.advance_payment.fee }}
							</td>
							<td>
								<v-text-field
										label="Fee"
										v-model.number="remainder.crew.nonPermanent[index].fee"
								></v-text-field>
							</td>

					</tr>

					<tr>
						<td colspan="5" style="text-align: center; font-weight: bold;">Permanent Crew</td>
					</tr>

					<tr v-for="(member, index) in _.filter(crew, ['role', roleTypes.PERMANENT])">
							<th scope="row">{{ member.position }} | {{ member.member.artist_name }}</th>
							<td>{{ member.fee }} {{ member.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
							<td>{{ permanentCrewMoney(member.fee) }}</td>
							<td>
								{{ member.advance_payment.fee }}
							</td>
							<td>
								<v-text-field
								label="Fee"
								v-model.number="remainder.crew.permanent[index].fee"
								:disabled="!manualMode"
								></v-text-field>
							</td>
					</tr>

					<tr v-for="(member, index) in _.filter(crew, ['role', roleTypes.LABEL])">
							<th scope="row">Label | {{ member.member.label_name }}</th>
							<td>{{ member.fee }} {{ member.fee_type == feeTypes.FIXED ? 'FIXED' : '%' }}</td>
							<td>{{ permanentCrewMoney(member.fee) }}</td>
							<td>
								{{ member.advance_payment.fee }}
							</td>
							<td>
								<v-text-field
										label="Fee"
										v-model.number="remainder.crew.label[index].fee"
								></v-text-field>
							</td>
					</tr>

          <tr>
            <td colspan="4" style="text-align: center; font-weight: bold;">Escrow</td>
          </tr>
          <tr>
            <th scope="row">Escrow</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <v-text-field
                label="Escrow"
                v-model.number="escrow.fee"
              ></v-text-field>
            </td>
          </tr>

					</tbody>
				</table>

				<v-textarea
						label="Note"
						auto-grow
						v-model="remainder.note"
				></v-textarea>

				<b>TOTAL: {{ total }}</b>

				<div v-if="dataFromStep1.remainder_payment.fee - total != 0">
					<v-chip color="red" text-color="white">There is a problem with the calculation. The expenses must be equal to the total amount of the remainder!</v-chip>
				</div>
        <div v-if="escrow.fee > 0">
          <v-chip color="red" text-color="white">There is a problem with the calculation. The Escrow must be 0!</v-chip>
        </div>
				<div>
					<v-btn color="primary" @click="save()">Save</v-btn>
					<v-btn color="primary" @click="save()">Save & Generate Bank Transfer forms</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>
<style>
.justify-content-between {
	justify-content: space-between;
}
</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {
			artist: {
				type: Object
			},
      escrow: {
			  type: Object,
        default: () => {fee: 0}
      },
			event: {
				type: Object
			},
			crew: {
				type: Array
			},
			dataFromStep1: {
				type: Object
			},
			promoter_advance: {
				type: Object,
				default: function () {
					return {fee: 0}
				}
			},
			agent_advance: {
				type: Object
			},
		},
		computed: {
			total() {
				let total = 0;
				_.forEach(this.remainder.crew.label, (label) => {
					total += label.fee;
				});

				_.forEach(this.remainder.crew.nonPermanent, (nonPermanent) => {
					total += nonPermanent.fee;
				});

				_.forEach(this.remainder.crew.permanent, (permanent) => {
					total += permanent.fee;
				});

				total += this.remainder.agent.value;
				total += this.remainder.promoter.value;

        total += Number(this.escrow.fee);

				return total;

			},
			agentAdvance() {
				//Add logic for custom fee! Only with default for now
				//Or better, move this to the backend!
				let agentAdvance = this.agentFee;
				// let DIVIDER = 0.5;
				// if(this.dataFromStep1.advance_payment.paymentType == this.paymentTypes.CASH && this.dataFromStep1.remainder_payment.paymentType == this.paymentTypes.CASH) {
				// 	// ot kaparoto all
				// 	DIVIDER = 1;
				// } else if (this.dataFromStep1.advance_payment.paymentType == this.paymentTypes.CASH && this.dataFromStep1.remainder_payment.paymentType == this.paymentTypes.BANK) {
				// 	// Ot reminder-a
				// 	DIVIDER = 0;
				// } else if (this.dataFromStep1.advance_payment.paymentType == this.paymentTypes.BANK && this.dataFromStep1.remainder_payment.paymentType == this.paymentTypes.CASH) {
				// 	// Ot kaparoto all
				// 	DIVIDER = 1;
				// } else {
				// 	// Na 2 puti kato e procent ot Advance & Reminder
				// 	// Ami ako ne e procentna stoinost? 50/50 za momenta
				// 	DIVIDER = 0.5
				// }
				//
				// agentAdvance = agentAdvance * DIVIDER;

				return agentAdvance;
			},
			agentFee() {
				let agentFee = 0;

				if(this.event.agent_fee) {
					if(this.event.agent_fee.fee_type == this.feeTypes.FIXED) {
						agentFee = this.event.agent_fee.fee
					} else {
						agentFee = this.event.fee * this.event.agent_fee.fee / 100
					}
				} else if(this.event.agent) {
					if(this.event.agent.fee_type == this.feeTypes.FIXED) {
						agentFee = this.event.agent.share_shows
					} else {
						agentFee = this.event.fee * this.event.agent.share_shows / 100
					}
				}

				return agentFee;
			},
			agentRemainder() {
				let val = 0;

				// Ugly hack if we don't have promoter...
				let promoter_advance = 0;
				if(this.promoter_advance) {
					promoter_advance = this.promoter_advance.fee;
				}

				if(this.event.agent) {
					val = this.agentFee - this.agent_advance.fee - (this.promoterFee - promoter_advance);

					if(val < 0) {
						val = 0;
					}
				}
				return val;
			},
			promoterFee() {
				let fee = 0;

				if(this.event.promoter_id) {
					if(this.event.promoter_fee.fee_type == this.feeTypes.FIXED) {
						fee = this.event.promoter_fee.fee;
					} else {
						fee = this.event.fee * this.event.promoter_fee.fee / 100;
					}
				}

				return fee;
			},
			promoterRemainder() {
				let fee = 0;

				if(this.event.promoter_id && this.promoter_advance) {
					fee = this.promoterFee - this.promoter_advance.fee;
				}

				return fee;
			},
			nonPermanentCrewExpenses() {
				let nonPermanentCrewExpenses = 0;
				_.forEach(this.remainder.crew.nonPermanent, (item) => {
					nonPermanentCrewExpenses = nonPermanentCrewExpenses + Number(item.fee)
				});

				return nonPermanentCrewExpenses;
			},
			moneyForPermanentCrew() {
				let sum = 0;
				sum = Number(this.dataFromStep1.remainder_payment.fee) - (Number(this.nonPermanentCrewExpenses) + Number(this.remainder.promoter.value) + Number(this.remainder.agent.value))

				sum = sum < 0 ? 0 : sum;
				return sum;
			}
		},
		data() {
			return {
				manualMode: false,
				promoterPaymentTypes: {
					STANDALONE: 1,
					INCLUSIVE: 2
				},
				roleTypes: {
					'PERMANENT': 1,
					'NONPERMANENT': 2,
					'LABEL': 3
				},
				paymentTypes: {
					'CASH': 1,
					'BANK': 2
				},
				feeTypes: {
					'FIXED': 1,
					'PERCENT': 2,
				},
				remainder: {
					agent: {
						value: 0,
					},
					promoter: {
						value: 0,
					},
					crew: {
						permanent: [],
						nonPermanent: [],
						label: []
					},
					note: '',
				}
			}
		},
		watch: {
			'remainder.agent.value': function () {
				this.calculateInputValues();
			},
			'remainder.promoter.value': function () {
				this.calculateInputValues();
			},
			'remainder.crew.nonPermanent': {
				handler: function() {
					this.calculateInputValues();
				},
				deep: true
			},
			'remainder.crew.permanent': {
				handler: function(val) {
					// console.log(val);
					this.calculateInputValues();
				},
				deep: true
			}
		},
		components: {},
		methods: {
			init() {
				this.remainder.agent.value = this.agentRemainder;
				this.remainder.promoter.value = this.promoterRemainder;

				_.forEach(_.filter(this.crew, ['role', this.roleTypes.PERMANENT]), (item) => {
					//Fallback if for some reason there is no record for advance payment (we assume it is 0)
					item.advance_payment = item.advance_payment || {fee: 0}

					this.remainder.crew.permanent.push({
						name: item.member.name,
						member_id: item.member_id,
						member_type: item.member_type,
						fee: 0,
						'default': {
							fee: item.fee,
							fee_type: item.fee_type
						}
					});
				})
				_.forEach(_.filter(this.crew, ['role', this.roleTypes.NONPERMANENT]), (item) => {
					//Fallback if for some reason there is no record for advance payment (we assume it is 0)
					item.advance_payment = item.advance_payment || {fee: 0}

					this.remainder.crew.nonPermanent.push({name: item.member.name, member_id: item.member_id, member_type: item.member_type, fee: 0,
						'default': {
							fee: item.fee,
							fee_type: item.fee_type
						}});
				})
				_.forEach(_.filter(this.crew, ['role', this.roleTypes.LABEL]), (item) => {
					this.remainder.crew.label.push({name: item.member.label_name, member_id: item.member_id, member_type: item.member_type, fee: 0,
						'default': {
							fee: item.fee,
							fee_type: item.fee_type
						}});
				})

				_.forEach(this.remainder.crew.nonPermanent, (item, index) => {
					let member = _.find(this.crew, { 'member_id': item.member_id, 'member_type': item.member_type });
					member.advance_payment = member.advance_payment || {fee: 0}
					// this.remainder.crew.nonPermanent[index].fee = item.default.fee - Number(member.advance_payment.fee);
					item.fee = item.default.fee - Number(member.advance_payment.fee);
				});

				this.calculateInputValues();
				// advance.crew.permanent = _.filter(crew, ['role', roleTypes.PERMANENT]);
				// advance.crew.label = _.filter(crew, ['role', roleTypes.LABEL])
			},
			permanentCrewMoney(val) {
				return this.moneyForPermanentCrew * val / 100;
			},
			calculateInputValues() {
				if(this.manualMode) {
					return;
				}

				_.forEach(this.remainder.crew.permanent, (item, index) => {
					this.remainder.crew.permanent[index].fee = this.permanentCrewMoney(item.default.fee);
				});

				_.forEach(this.remainder.crew.label, (item, index) => {
					this.remainder.crew.label[index].fee = this.permanentCrewMoney(item.default.fee);
				});
			},
			save() {

				let data = {
					calendar_id: this.event.id,
					crew_id: this.event.crew_id,
					crew: this.remainder.crew,
					agent: this.remainder.agent,
					promoter: this.remainder.promoter,
					advance: this.dataFromStep1.advance_payment,
					remainder: this.dataFromStep1.remainder_payment,
					note: this.remainder.note,
					date: this.dataFromStep1.date,
          escrow: this.escrow.fee
				}

				this.$http.post('/calendars/payments/remainder', data).then((result) => {
					console.log(result);
					this.$emit('next', result.data);
				});
			}
		},
		created: function () {
			this.init();
		},
		mounted: function () {
		}
	}
</script>
