import { companyResource } from '@/api/API'
export const namespaced = true

export const state = {
  companies: [],
  company: {}
}

export const mutations = {
  ADD_COMPANY(state, tour) {
    state.events.push(tour)
  },
  SET_COMPANIES(state, data) {
    state.companies = data
  }
}

export const actions = {
  async getCompanies({ state, commit, rootState }) {
    const companies = await companyResource.read()
    if (companies) commit('SET_COMPANIES', companies.data)
  },
}

export const getters = {

}
