var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-card',[_c('v-card-title',[_vm._v("\n      Earnings - Last uploaded reports:   "),_c('b',[_vm._v("Youtube:")]),_vm._v("   "+_vm._s(_vm.earnings.lastUpdateMonth)+" /\n      "+_vm._s(_vm.earnings.lastUpdateYear)+"\n      "),_c('br'),_vm._v(" "),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"info","href":"/earnings/show-reports"}},[_vm._v("Show Reports")]),_vm._v(" "),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"info","href":"/earnings/revenues"}},[_vm._v("Import Revenues")]),_vm._v(" "),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"info","href":"/earnings/index/create"}},[_vm._v("Manual Payment")])],1),_vm._v(" "),_c('v-card-text',[_c('h3',[_vm._v("Artists")]),_vm._v(" "),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_artists),callback:function ($$v) {_vm.search_artists=$$v},expression:"search_artists"}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers_artists,"items":_vm.earnings.artists,"search":_vm.search_artists,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.artist.artist_name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/artists/'+item.artist.id}},[_vm._v(_vm._s(item.artist.artist_name)+" ")])]}},{key:"item.total_payment",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.total_payment,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.last_payment_price,'0,000'))+" EUR\n        ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.sum,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("moment")(item.last_payment.created_at,"DD.MM.YYYY"))+"\n        ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.sum > 0)?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.paymentPost('artist', item.parent_id, item.sum)}}},[_vm._v("\n              Pay\n            ")]):_vm._e()],1)]}}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_vm._v(" "),_c('th',[(_vm.totalArtist)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalArtist,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th',[(_vm.totalArtistRevenue)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalArtistRevenue,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th')])])],2)],1),_vm._v(" "),_c('v-card-text',[_c('h3',[_vm._v("Agents")]),_vm._v(" "),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_agents),callback:function ($$v) {_vm.search_agents=$$v},expression:"search_agents"}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers_agents,"items":_vm.earnings.agents,"search":_vm.search_agents,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.agent.agent_name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/agents/'+item.agent.id}},[_vm._v(_vm._s(item.agent.agent_name)+" ")])]}},{key:"item.total_payment",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.total_payment,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.last_payment.price,'0,000'))+" EUR\n        ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.sum,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("moment")(item.last_payment.created_at,"DD.MM.YYYY"))+"\n        ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.sum > 0)?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.paymentPost('agent', item.parent_id, item.sum)}}},[_vm._v("\n              Pay\n            ")]):_vm._e()],1)]}}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_vm._v(" "),_c('th',[(_vm.totalAgent)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalAgent,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th',[(_vm.totalAgentRevenue)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalAgentRevenue,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th')])])],2)],1),_vm._v(" "),_c('v-card-text',[_c('h3',[_vm._v("Labels")]),_vm._v(" "),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_labels),callback:function ($$v) {_vm.search_labels=$$v},expression:"search_labels"}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers_labels,"items":_vm.earnings.labels,"search":_vm.search_labels,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.label.label_name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/labels/'+item.label.id}},[_vm._v(_vm._s(item.label.label_name)+" ")])]}},{key:"item.total_payment",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.total_payment,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.last_payment.price,'0,000'))+" EUR\n        ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("numeralFormat")(item.sum,'0,000'))+" EUR\n        ")]}},{key:"item.last_payment.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm._f("moment")(item.last_payment.created_at,"DD.MM.YYYY"))+"\n        ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.sum > 0)?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.paymentPost('labels', item.parent_id, item.sum)}}},[_vm._v("\n              Pay\n            ")]):_vm._e()],1)]}}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_vm._v(" "),_c('th',[(_vm.totalLabel)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalLabel,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th',[(_vm.totalLabelRevenue)?[_vm._v(_vm._s(_vm._f("numeralFormat")(_vm.totalLabelRevenue,'0,000'))+" EUR")]:_vm._e()],2),_vm._v(" "),_c('th')])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }