<template>
  <section>
    <transition name="fade" mode="out-in">
      <form
        :action="form_action"
        :method="form_method ? form_method : 'POST'"
        :enctype="enctype ? 'multipart/form-data' : ''"
        :class="[
                    {'element-loader loading': loading}
                ]"
        v-if="!success"
        key="form">

        <input v-if="csrf" type="hidden" name="_token" v-model="csrf">
        <div class="columns flex-wrap mt20">
          <div
            :class="[
                            'column', 'is-flex', 'justify-end', 'direction-column',
                            ['is-' + field.columns + '-desktop is-12-tablet']
                        ]"
            v-for="(field, i) in fields"
            :key="'field-' + i"
            v-if="field.depends_on ? (field_values[field.depends_on.name] == field.depends_on.value) : true">

            <p class="is-error" v-if="field.error_message">
              <i class="mdi mdi-alert"></i> {{ field.error_message }}
            </p>

            <!-- RADIO FIELD -->
            <div
              :class="[
                                'input-field-holder', 'is-dirty',
                                {'has-error': field.error_message}
                            ]"
              v-if="field.type == 'radio'">

              <label class="placeholder" :for="'input-' + i">
                {{ field.placeholder }}
              </label>
              <div
                class="radio-field"
                v-for="(option, j) in field.options"
                :key="'field-' + i + '-' + j">

                <input
                  :id="'field-' + i + '-' + j"
                  :type="field.type"
                  :name="field.name"
                  :value="option.value"
                  v-model="field_values[field.name]">
                <label
                  :for="'field-' + i + '-' + j">

                  {{ option.option }}
                </label>
              </div>
            </div>

            <!-- CHECKBOX FIELD -->
            <div
              :class="[
                                'input-field-holder', 'is-dirty', 'has-no-bckg',
                                {'has-error': field.error_message}
                            ]"
              v-else-if="field.type == 'checkbox'">

              <div class="radio-field is-checkbox">

                <input
                  :id="'field-' + i"
                  :type="field.type"
                  :name="field.name"
                  v-model="field_values[field.name]">
                <label
                  :for="'field-' + i"
                  v-html="field.placeholder">
                </label>
              </div>
            </div>

            <!-- SELECT FIELD -->
            <div
              :class="[
                                'input-field-holder',
                                {'is-dirty': field_focuses[field.name] || (field_values[field.name] && field_values[field.name].toString().length > 0)},
                                {'is-focused': field_focuses[field.name]},
                                {'has-error': field.error_message}
                            ]"
              v-else-if="field.type == 'select'">

              <label class="placeholder" :for="'input-' + i">
                {{ field.placeholder }}
              </label>
              <select
                :id="'input-' + i"
                :type="field.type"
                :name="field.name"
                :required="field.require"
                v-model="field_values[field.name]"

                @focus="field_focuses[field.name] = true"
                @blur="field_focuses[field.name] = false">

                <option
                  :value="option.value"
                  v-for="(option, j) in field.options"
                  :key="'field-' + i + '-' + j"
                  selected>

                  {{ option.option }}
                </option>
              </select>
              <i class="select-caret mdi mdi-chevron-down"></i>
            </div>

            <!-- DATEPICKER FIELD -->
<!--            <div-->
<!--              :class="[-->
<!--                                'input-field-holder',-->
<!--                                {'is-dirty': field_focuses[field.name] || (field_values[field.name] && field_values[field.name].toString().length > 0)},-->
<!--                                {'is-focused': field_focuses[field.name]},-->
<!--                                {'has-error': field.error_message}-->
<!--                            ]"-->
<!--              v-else-if="field.type == 'date'">-->

<!--              <label class="placeholder" :for="'input-' + i">-->
<!--                {{ field.placeholder }}-->
<!--              </label>-->
<!--              <datepicker-->
<!--                :id="'input-' + i"-->
<!--                :name="field.name"-->
<!--                :required="field.require"-->
<!--                v-model="field_values[field.name]"-->
<!--                :monday-first="true"-->

<!--                @selected="field_focuses[field.name] = true"-->
<!--                @opened="field_focuses[field.name] = true">-->
<!--              </datepicker>-->
<!--            </div>-->

            <!-- TEXTAREA FIELD -->
            <div
              :class="[
                                'input-field-holder', 'textarea-field',
                                {'is-dirty': field_focuses[field.name] || (field_values[field.name] && field_values[field.name].toString().length > 0)},
                                {'is-focused': field_focuses[field.name]},
                                {'has-error': field.error_message}
                            ]"
              v-else-if="field.type == 'textarea'">

              <label class="placeholder" :for="'input-' + i">
                {{ field.placeholder }}
              </label>
              <textarea
                :id="'input-' + i"
                :type="field.type"
                :name="field.name"
                :required="field.require"
                v-model="field_values[field.name]"
                rows="5"

                @focus="field_focuses[field.name] = true"
                @blur="field_focuses[field.name] = false"></textarea>
            </div>

            <!-- File INPUT FIELD -->
            <div
              :class="[
                                'input-field-holder',
                                {'is-dirty': field_focuses[field.name] || (field_values[field.name] && field_values[field.name].toString().length > 0)},
                                {'is-focused': field_focuses[field.name]},
                                {'has-error': field.error_message}
                            ]"
              v-else-if="field.type == 'file'">

              <label class="placeholder" :for="'input-' + i">
                {{ field.placeholder }}
              </label>
              <input
                :id="'input-' + i"
                class="file-upload"
                :type="field.type"
                :name="field.name"
                :required="field.require"
                v-model="field_values[field.name]"

                @focus="field_focuses[field.name] = true"
                @blur="field_focuses[field.name] = false">
              <label class="input-field" :for="'input-' + i">
                {{ getFileOnly(field_values[field.name]) }} &#8203
              </label>
            </div>

            <!-- NORMAL INPUT FIELD -->
            <div
              :class="[
                                'input-field-holder',
                                {'has-no-bckg': !field.type},
                                {'is-dirty': field_focuses[field.name] || (field_values[field.name] && field_values[field.name].toString().length > 0)},
                                {'is-focused': field_focuses[field.name]},
                                {'has-error': field.error_message}
                            ]"
              v-else>

              <label class="placeholder" :for="'input-' + i" v-if="field.type">
                {{ field.placeholder }}
              </label>
                <v-text-field
                  :id="'input-' + i"
                  :type="field.type"
                  :name="field.name"
                  :maxlength="field.maxlength"
                  :min="field.min"

                  :label="field.label"
                  :placeholder="field.placeholder"

                  v-model="field_values[field.name]"
                  :required="field.require"
                  data-vv-name="phone"
                  :error-messages="errors.collect('name')"
                  v-if="field.type"

                  @focus="field_focuses[field.name] = true"
                  @blur="field_focuses[field.name] = false"
                ></v-text-field>
            </div>

          </div>
        </div>

        <!-- <input type="hidden" name="captcha" v-model="captcha">
		<vue-recaptcha sitekey="6LcYmJ4UAAAAAHOMvx6RmZhKA2AoHRJ2nulX-RVX" @verify="verifyCaptcha"></vue-recaptcha> -->

        <button type="submit" class="button is-link" v-if="!axios">
                    <span class="button-text">
                        {{ submit_text }}
                    </span>
        </button>
        <a
          href="javascript:;"
          class="button is-link"
          @click="submit()"
          v-else>

          {{ submit_text }}
        </a>
      </form>
      <div v-else key="success">
        <p class="has-text-link" v-html="success_text">
        </p>
        <a
          href="javascript:;"
          class="button is-link mt50"
          @click="reset()">

          {{ reset_text }}
        </a>
      </div>
    </transition>


<!--    <v-select-->
<!--      attach-->
<!--      :items="contactCategories"-->
<!--      v-model="contact.category_id"-->
<!--      item-text="name"-->
<!--      label="Category"-->
<!--      item-value="id"-->
<!--      :error-messages="errors.collect('category_id')"-->
<!--    >-->
<!--    </v-select>-->
<!--    <v-text-field-->
<!--      label="Name"-->
<!--      v-model="contact.name"-->
<!--      data-vv-name="name"-->
<!--      :error-messages="errors.collect('name')"-->
<!--    ></v-text-field>-->

<!--    <v-text-field-->
<!--      label="Email"-->
<!--      v-model="contact.email"-->
<!--      v-validate="'required|max:200'"-->
<!--      data-vv-name="email"-->
<!--      :error-messages="errors.collect('email')"-->
<!--    ></v-text-field>-->
  </section>
</template>

<style scoped lang="sass"></style>


<script>
  export default {
    props: {
      form_title: String,
      required_text: String,
      form_action: String,
      form_method: String,
      fields: Array,
      csrf: {
        type: String,
        default: ''
      },
      enctype: {
        type: Boolean,
        default: false
      },
      submit_text: {
        type: String,
        default: 'Изпрати'
      },
      axios: {
        type: Boolean,
        default: false
      },
      success_text: {
        type: String,
        default: ''
      },
      reset_text: {
        type: String,
        default: ''
      },
    },

    components: {},
    data: function () {
      return {
        field_values: {},
        field_focuses: {},
        email_focused: false,
        captcha: 0,
        loading: false,
        success: false,
      }
    },
    methods: {
      submit: function () {
        this.loading = true
        axios
          .post(this.form_action, this.field_values)
          .then(res => {
            this.loading = false

            if (res.data.success) {
              this.success = true
            } else {
              this.success = false

              this.fields.forEach((fld, i) => {
                let field = fld
                field.error_message = res.data.errors[field.name]
                Vue.set(this.field_values, i, field)
              });
            }
          })
          .then(err => {
            console.log(err)
          }).catch(error => {
          this.loading = false
          this.fields.forEach((fld, i) => {
            let field = fld

            if(error.response.data.errors.hasOwnProperty(field.name) && error.response.data.errors[field.name].length > 0) {
              field.error_message = error.response.data.errors[field.name][0]
            } else {
              field.error_message = false
            }

            Vue.set(this.field_values, i, field)
          });
        })
        // setTimeout(() => {
        //     this.loading = false
        //     this.success = false
        //     let errors = {
        //         name: 'Има грешка в името',
        //         phone: 'Липсва телефон',
        //     }
        //     this.fields.forEach((fld, i) => {
        //         let field = fld
        //         field.error_message = errors[field.name]
        //         Vue.set(this.field_values, i, field)
        //     });
        // }, 1000)
      },
      reset: function () {
        this.loading = false
        this.success = false
        this.initFieldValues()
        this.initFieldFocuses()
      },

      initFieldValues: function () {
        this.fields.forEach(field => {
          Vue.set(this.field_values, field.name, field.old_value)
        });
      },
      initFieldFocuses: function () {
        this.fields.forEach(field => {
          Vue.set(this.field_focuses, field.name, false)
        });
      },
      getFileOnly: function (file_path) {
        let segments = file_path.split('\\')

        return segments[segments.length - 1]
      },
      verifyCaptcha: function(response) {
        this.captcha = 1
      }
    },
    computed: {

    },
    created: function () {
      this.initFieldValues()
      this.initFieldFocuses()
    }
  }
</script>
