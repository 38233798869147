<template>
	<div class="">
		<div class="row">
			<div class="col-xs-1">
				<v-btn dark fab small color="pink tiny" @click="remove">
					<v-icon>remove</v-icon>
				</v-btn>
			</div>
			<div class="col-xs-3">
				<v-select
						attach
						:items="artists"
						v-model="customRate.artist_id"
						item-text="artist_name"
						label="Artist"
						item-value="id"
				>
				</v-select>
			</div>
			<template v-show="customRate.artist_id">
				<div class="col-xs-2">
					<v-text-field
							label="Rate"
							v-model="customRate.fee"
					></v-text-field>
				</div>
				<div class="col-xs-3">
					<v-select
							attach
							:items="rate_values"
							label="Value type"
							item-text="title"
							v-model="customRate.fee_type"
							:manu-props="{value: customRate.fee_type }"
					></v-select>
				</div>
			</template>
			<div class="col-xs-3">
				<v-btn @click="save()">Save</v-btn>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12">
				<div v-for="error in localErrors">
					<span class="badge badge-danger">{{ error[0] }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: ['customRate', 'artists'],
		computed: {},
		data() {
			return {
				localErrors: [],
				rate_values: [
					{title: 'Flat rate', value: 1},
					{title: 'Pct. %', value: 2}
				],
			}
		},
		watch: {},
		components: {},
		methods: {
			init() {

			},
			save() {
				this.$http.post('/api/agent-fees', this.customRate).then((response) => {
					this.localErrors = null;
				}).catch((error) => {
					this.localErrors = error.response.data.errors;
				});
			},
			remove() {
				if(this.customRate.hasOwnProperty('id')) {
					this.$http.delete('/api/agent-fees/' + this.customRate.id).then((response) => {

					}).catch((error) => {
						console.log(error);
					});
				}

				this.$emit('remove', this.customRate);
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
