import { budgetResource } from '@/api/API'
export const namespaced = true

export const state = {
  budgets: [],
  budgetsTotal: 0,
  budget: {}
}

export const mutations = {
  ADD_BUDGET(state, budget) {
    state.budgets.push(budget)
  },
  SET_BUDGETS(state, budgets) {
    state.budgets = budgets
  },
  REMOVE_BUDGET(state, budget) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeBudget({ state, commit, rootState }, budget) {
    const status = await budgetResource.create({ data: budget })

    if (status) {
      commit('ADD_BUDGET', budget)
    }

    return status
  },
  async deleteBudget({ state }, id) {
    return await budgetResource.remove({params: {id: id}})
  },
  async getBudgets({ state, commit, rootState }) {
    const budgets = await budgetResource.read()
    if (budgets) commit('SET_BUDGETS', budgets.data)
  },
  async fetchBudget({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await budgetResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateBudget({ state, commit, rootState }, budget) {
    const status = await budgetResource.update({ data: budget, params: {id: budget.id}})
    return status
  }

}

export const getters = {
  budgets: state => state.budgets
}
