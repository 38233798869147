<template>
	<div class="container">
		<div class="row">
      <div class="col-sm-6">
        <v-card>
          <v-card-title>Contact Categories</v-card-title>
          <v-card-text>
            <div v-for="category in contactCategories">
              <router-link to="">{{ category.name }}</router-link>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-sm-6">
        <v-card>
          <v-card-text>
            <v-btn color="info" :to="{name: 'contacts-create'}">Create contact</v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        <v-card>
          <v-card-text>
            <div v-for="(contact, index) in contacts">
              {{ index + 1 }} | <b>Name:</b> {{contact.name}} | <b>Email:</b> {{contact.email}} | <b>Phone: </b> {{contact.phone}} ({{ contact.category.name }}) - <router-link :to="{name: 'contacts-edit', params: {id: contact.id}}">EDIT</router-link>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
	</div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
	export default {
		name: 'ContactsIndex',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {}
		},
		computed: {
      ...mapState('contact', ['contacts']),
      ...mapState('contactCategory', ['contactCategories']),
    },
		watch: {},
		mounted() {
      !this.contacts.length ? this.getContacts() : false
      !this.contactCategories.length ? this.getContactCategories() : false
		},
		methods: {
      ...mapActions('contact', ['getContacts']),
      ...mapActions('contactCategory', ['getContactCategories']),
    }
	}
</script>

<style></style>
