<template>
	<div class="container">
    <template v-if="dirtyEvents.length > 0 && !loading">
      <v-card>
        <v-card-text>
          <div v-for="event in dirtyEvents">
            <a :href="`/calendars/${event.id}/edit`">{{ event.calendar_title }}</a> <v-btn depressed small color="error" @click="deleteEvent(event.id)">delete</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <tour-form :loading="loading" :tour.sync="tour" :artists="artists" :companies="companies" :cities="cities" :disabled="disableEdit" @save="save()" btn-text="Update Tour"></tour-form>
	</div>
</template>

<script>
  import TourForm from './TourForm'
  import {mapState, mapActions} from 'vuex'
  export default {
    name: 'TourEdit',
    components: {
      TourForm
    },
    mixins: [],
    props: {
      id: {
        required: true
      }
    },
    data() {
      return {
        loading: false,
        tour: {
          tour: {},
          artists: [],
          dates: [],
          tourEvents: []
        },
      }
    },
    computed: {
      ...mapState('artist', ['artists']),
      ...mapState('company', ['companies']),
      ...mapState('city', ['cities']),
      dirtyEvents() {
        return _.filter(this.tour.tourEvents, ['is_dirty', true])
      },
      disableEdit() {
        return !!this.dirtyEvents.length
      }
    },
    watch: {},
    async created() {
      this.loading = true
      const result = await this.fetchTour(this.id)
      this.tour.tour = result.data.tour
      this.tour.artists = result.data.tour.artists
      this.tour.dates = result.data.tour.tour_date
      this.tour.tourEvents = result.data.tourEvents
      this.loading = false
    },
    mounted() {
      !this.artists.length ? this.getArtists() : false
      !this.companies.length ? this.getCompanies() : false
      !this.cities.length ? this.getCities() : false
    },
    methods: {
      async save() {
        this.loading = true
        const result = await this.patchTour(this.tour, this.id)
        this.loading = false
        if (result) {
          this.$router.push('/tours')
        }
      },
      async deleteEvent(event_id) {
        if(!confirm('This event was changed recently. Are you sure you want to delete it and loose all changes?')) {
          return;
        }

        const result = await this.deleteCalendar(event_id)
        console.log(result)
        if(result) {
          this.tour.tourEvents.splice(_.findIndex(this.tour.tourEvents, ['id', event_id]), 1)
        }
      },
      ...mapActions('tour', ['patchTour', 'fetchTour']),
      ...mapActions('artist', ['getArtists']),
      ...mapActions('company', ['getCompanies']),
      ...mapActions('city', ['getCities']),
      ...mapActions('calendar', ['deleteCalendar'])
    }
  }
</script>

<style></style>
