<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Add Event @ {{ eventData.start | moment("DD-MM-YYYY") }}</span>
				</v-card-title>
				<v-card-text>
					<v-container fluid grid-list-lg class="pa-0">
						<v-layout wrap>
							<v-flex v-if="sameCityEvents.length">
								<v-alert
										:value="true"
										color="warning"
										icon="priority_high"
								>
									The artist has recent events in this city! <v-btn small outline @click="showSameCityEvents = !showSameCityEvents">Show events</v-btn>
								</v-alert>
							</v-flex>
							<v-flex xs12 v-for="(event, index) in sameCityEvents" :key="index" v-if="showSameCityEvents">
								({{ event.start | moment("from", eventDateMoment) }}) {{ event.start | moment("DD-MM-YYYY") }} {{ event.artist.artist_name }} @ {{ event.venue.venue_name }}
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
							<v-select
									:items="types"
									v-model="eventData.type"
									label="Type"
									required
							></v-select>
							</v-flex>
							<v-flex xs12 sm6>
								<v-autocomplete
										v-model="eventData.artist_id"
										:items="artists"
										item-text="artist_name"
										item-value="id"
										label="Artist"
										data-vv-name="artist_id"
										v-validate="'required'"
										:error-messages="errors.collect('artist_id')"
								></v-autocomplete>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type != 'task'">
								<v-text-field label="Fee"
											  v-model="eventData.fee"
											  required></v-text-field>
							</v-flex>

              <v-flex sm12 v-if="eventData.type == 'performance'">
                <v-switch
                  v-model="eventData.is_tour"
                  label="Venue / Tour"
                ></v-switch>
              </v-flex>

							<v-flex xs12 sm6 v-if="eventData.type == 'performance' && !eventData.is_tour">
								<v-autocomplete
										v-model="eventData.venue_id"
										:items="venues"
										item-text="venue_name"
										item-value="id"
										label="Venue"
										clearable
								></v-autocomplete>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance' && !eventData.is_tour">
								<v-text-field label="New venue"
											  v-model="eventData.venue_name"
								></v-text-field>
							</v-flex>

              <!-- TOURS -->
              <v-flex xs12 sm6 v-if="eventData.type == 'performance' && eventData.is_tour">
                <v-autocomplete
                  v-model="selected_tour"
                  :items="tours"
                  item-text="name"
                  label="Tour"
                  clearable
                  return-object
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 v-if="eventData.type == 'performance' && eventData.is_tour && selected_tour">
                <v-autocomplete
                  v-model="eventData.tourDate"
                  :items="selected_tour.tour_date"
                  item-text="city.name"
                  item-value="id"
                  label="City"
                  clearable
                ></v-autocomplete>
              </v-flex>
              <!-- TOURS END -->

							<v-flex xs12 sm6 v-if="eventData.type != 'task'">
								<v-autocomplete
										v-model="eventData.agent_id"
										:items="agents"
										item-text="agent_name"
										item-value="id"
										label="Agent"
										clearable
								></v-autocomplete>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance'">
								<v-autocomplete
										v-model="eventData.promoter_id"
										:items="agents"
										item-text="agent_name"
										item-value="id"
										label="Promoter"
										clearable
								></v-autocomplete>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance'">
								<v-text-field label="Soundcheck"
											  v-model="eventData.soundcheck"
											  required></v-text-field>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance'">
								<v-text-field label="Show start time"
											  v-model="eventData.start_time"
											  required></v-text-field>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance'">
								<v-switch
										v-model="eventData.dancers"
										label="Dancers"
								></v-switch>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'performance'">
								<v-switch
										v-model="eventData.travel_exp"
										label="Travel EXP"
								></v-switch>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'project'">
								<v-autocomplete
										v-model="eventData.company_id"
										:items="companies"
										item-text="company_name"
										item-value="id"
										label="Company"
										clearable
								></v-autocomplete>
							</v-flex>
							<v-flex xs12 v-if="eventData.type != 'performance'">
								<v-textarea
										label="Description"
										auto-grow
										v-model="eventData.description"
								></v-textarea>
							</v-flex>
							<v-flex xs12 sm6 v-if="eventData.type == 'task'">
								<v-autocomplete
										v-model="eventData.user_id"
										:items="users"
										item-text="name"
										item-value="id"
										label="Administrator"
										clearable
								></v-autocomplete>
							</v-flex>

							<template v-for="(error, index) in errors.collect('global')">
								<v-flex xs12>
									<v-alert
											:value="true"
											color="error"
											icon="warning"
											outline
									>
										{{ error }}
									</v-alert>
								</v-flex>
							</template>
						</v-layout>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<template v-if="loading">
						<v-progress-linear :indeterminate="true"></v-progress-linear>
					</template>
					<template v-else>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="close">Close</v-btn>
						<v-btn color="blue darken-1" text @click="save">Save</v-btn>
					</template>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
	export default {
		name: 'EventCreate',
		components: {},
		mixins: [],
		props: {
			agents: {
				type: Array,
				required: true
			},
			artists: {
				type: Array,
				required: true,
			},
			companies: {
				type: Array,
				required: true,
			},
			dialog: {
				type: Boolean,
				required: true
			},
			eventDate: {
				required: true
			},
			events: {
				type: Array
			},
			users: {
				type: Array,
				required: true
			},
			venues: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				loading: false,
				types: ["performance", "project", "task"],
				eventData: {
					type: "performance",
					artist_id: null,
					agent_id: null,
					company_id: null,
					description: null,
					end: null,
					fee: null,
					venue_id: null,
					venue_name: null,
					promoter_id: null,
					stage_size: null,
					soundcheck: null,
					start: null,
					start_time: null,
					dancers: null,
					travel_exp: null,
					title: null,
					user_id: null,
          is_tour: false
				},
				eventDateMoment: null,
				sameCityEvents: [],
				showSameCityEvents: false,
        selected_tour: {}
			}
		},
		computed: {
      ...mapState('tour', ['tours']),
			sameCityTolerance() {
        const artist = _.find(this.artists, ['id', this.eventData.artist_id])
				return artist.same_city_days ? artist.same_city_days : 40
			}
		},
		watch: {
			'eventDate': {
				handler: function() {
					this.eventData.start = this.eventDate.start
					this.eventData.end = this.eventDate.end
					this.eventDateMoment = this.$moment(this.eventDate.start)
				},
				deep: true
			},
			'eventData.artist_id': function() {
				this.sameCityEvents = this.sameCityCheck()
			},
			'eventData.venue_id': function() {
				this.sameCityEvents = this.sameCityCheck()
			}
		},
		mounted() {
      !this.tours.length ? this.fetchTours() : false
    },
		methods: {
      ...mapActions('tour', ['fetchTours']),
			sameCityCheck() {
				if (!this.eventData.artist_id || !this.eventData.venue_id) {
					return []
				}
				const selectedVenueID = this.eventData.venue_id
				const selectedVenue = _.find(this.venues, ['id', selectedVenueID]);

				let events = this.events;
				events = _.filter(events, ['artist_id', this.eventData.artist_id])
				events = _.filter(events, ['venue.city', selectedVenue.city])

				events = _.filter(events, (o) => {
					let x = this.$moment(o.start);
					let s = this.$moment.duration(this.eventDateMoment.diff(x))
					if ((s.as('days') > 0 && s.as('days') <= this.sameCityTolerance) || (s.as('days') < 0 && s.as('days') > -this.sameCityTolerance)) {
						return o;
					}
				})

				return events
			},
			close(){
				this.$emit('update:dialog', false)
				this.eventData = {
					type: "performance",
					artist_id: null,
					agent_id: null,
					company_id: null,
					description: null,
					end: null,
					fee: null,
					venue_id: null,
					venue_name: null,
					promoter_id: null,
					stage_size: null,
					soundcheck: null,
					start: null,
					start_time: null,
					dancers: null,
					travel_exp: null,
					title: null,
					user_id: null,
				};
			},
			async save() {
				const isValid = await this.$validator.validate()
				if (!isValid) {
					return
				}


				this.loading = true
				this.errors.clear()

        let data = this.eventData;

				if(this.eventData.is_tour) {
				  data.venue_id = this.selected_tour.id
          data.venue_type = 'Tours'
        }

				this.$http.post('/calendars', this.eventData).then((response) => {
					this.$emit('eventCreated')
					this.close()
				}).catch((error) => {

					this.errors.add({
						field: 'global',
						msg: error.response.data.message
					});
				}).finally(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style></style>
