<template>
  <div class="container">
    <v-card>
      <v-card-title>City Edit</v-card-title>
      <v-card-text>
        <v-text-field
          label="City name"
          v-model="city.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-btn color="info" @click.native="save" :loading="loading">Update City</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  export default {
    name: 'CityEdit',
    components: {},
    mixins: [],
    props: {
      id: {}
    },
    data() {
      return {
        loading: false,
        city: {}
      }
    },
    computed: {
    },
    watch: {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const result = await this.fetchCity(parseInt(this.id))
        this.city = result.data
      },
      async save() {
        this.loading = true
        const result = await this.updateCity(this.city, this.city.id)
        this.loading = false
      },
      ...mapActions('city', ['updateCity', 'fetchCity']),
    }
  }
</script>

<style></style>
