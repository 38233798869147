<template>
  <div class="container">
    <v-card>
      <v-card-title>Contact</v-card-title>
      <v-card-text>
        <ContactForm :contact="contact" :contact-categories="contactCategories" @update:value="contact = $event"></ContactForm>
        <v-btn color="info" @click.native="save">Add Contact</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import ContactForm from './ContactForm'
	export default {
		name: 'ContactCreate',
		components: {
      ContactForm
    },
		mixins: [],
		props: {},
		data() {
			return {
			  loading: false,
        contact: {},
        contactValue: {}
      }
		},
		computed: {
      ...mapState('contactCategory', ['contactCategories']),
    },
		watch: {},
		mounted() {
      !this.contactCategories.length ? this.getContactCategories() : false
		},
		methods: {
      async save() {
        this.loading = true
        const result = await this.storeContact(this.contact)

        if (result) {
          this.$router.push({'name': 'contacts-index'})
        } else {
          this.$validator.validateAll();
        }
        this.loading = false
      },
      ...mapActions('contact', ['storeContact']),
      ...mapActions('contactCategory', ['getContactCategories']),
    }
	}
</script>

<style></style>
