<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Budgets
        <v-btn class="ml-auto" color="info" :to="{'name': 'budget-create'}">Create budget</v-btn>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="3" v-for="budget in budgets" :key="budget.id">
        <v-card>
          <v-card-title>
            <router-link :to="{'name': 'budget-show', params: { id: budget.id }}">{{ budget.title }}</router-link>
          </v-card-title>
          <v-card-text>
            <br/>
            <p>
              <b>Client</b>
              : {{ budget.client.company_name }}
            </p>
            <p>
              <b>Agency</b>
              : {{ budget.agency.company_name }}
            </p>
            <p>
              <b>Budget</b>
              : {{ budget.budget | numeralFormat('0,000') }} {{ budget.currency }}
            </p>
            <p>
              <b>Current Balance</b>
              : {{ budget.balance | numeralFormat('0,000') }} {{ budget.currency }}
            </p>
            <p v-if="budget.last_income">
              <b>Last Income</b>
              : {{ budget.last_income.created_at | moment("DD.MM.YYYY") }}
              {{ budget.last_income.value | numeralFormat('0,000') }} {{ budget.currency }} {{budget.last_income.description}}
            </p>
            <p v-if="budget.last_expense">
              <b>Last Expense</b>
              : {{ budget.last_expense.created_at | moment("DD.MM.YYYY") }}
              {{ budget.last_expense.value | numeralFormat('0,000') }} {{ budget.currency }} {{budget.last_expense.description}}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BudgetIndex",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("budget", ["budgets"]),
  },
  methods: {
    ...mapActions("budget", ["getBudgets"]),
  },
  created() {
    this.getBudgets();
  },
};
</script>

<style></style>
