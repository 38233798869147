<template>
  <div>
    <v-card>
      <v-card-text>

          <div class="row">
            <div class="col-12 col-sm-4">

            <v-autocomplete
              v-model="displayArtists"
              :items="$store.state.artist.artists"
              outlined
              dense
              chips
              small-chips
              label="Filter Artists"
              multiple
              item-value="id"
              item-text="artist_name"
            ></v-autocomplete>
            </div>
            <div class="col-12 col-sm-8">
              <v-btn-toggle
                tile
                color="deep-purple accent-3"
                group
              >
                <v-btn :to="{'name': 'calendar-list', params: {id: 'new'}}" >New Events</v-btn>
                <v-btn :to="{'name': 'calendar-list', params: {id: 'advance_payment'}}">Advance payment</v-btn>
                <v-btn :to="{'name': 'calendar-list', params: {id: 'logistics'}}">Logistics</v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <template v-if="fetchLoading">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </template>
          <template v-else>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <div class="event-urgent-status">&nbsp;</div>
                <th class="text-left">ID</th>
                <th class="text-left" v-if="done_type == 1 || done_type == 2">Status</th>
                <th class="text-left">Date</th>
                <th class="text-left">Artist @ Venue</th>
                <th class="text-left" v-if="done_type == 1 || done_type == 2">FEE</th>

                <th class="text-left" v-if="done_type == 3">Dancers</th>
                <th class="text-left" v-if="done_type == 3">Travel Exp. Covered</th>
                <th class="text-left" v-if="done_type == 3">Soundcheck</th>
                <th class="text-left" v-if="done_type == 3">Show Start</th>
                <th class="text-left" v-if="done_type == 3">Hotel</th>
                <th class="text-left" v-if="done_type == 3">Collect At</th>


                <th class="text-left"><v-icon small color="green lighten-2">fas fa-phone</v-icon></th>
                <th class="text-left" v-if="done_type == 1">Date Created</th>
                <th class="text-left" v-if="done_type == 1 || done_type == 2">FEE</th>
                <th class="text-left">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in resourceObject.data" :key="item.name">
                <div class="event-urgent-status" :class="calculateUrgencyClass(item)">&nbsp;</div>
                <td><a :href="`/calendars/${item.id}/edit`" target="_blank">{{ item.id }}</a></td>
                <td class="small" v-if="done_type == 1 || done_type == 2">
                  <v-select
                    :items="calendar_status_list"
                    v-model="item.status"
                    label="Status"
                    @change="update(item)"
                    outlined
                    dense
                  ></v-select>
                </td>
                <td class="small">{{ item.start | DD-MM-YYYY }}</td>
                <td class="small">{{ typeof item.artist != 'undefined' ? item.artist.artist_name : '-'}} @ {{getVenueName(item)}}, {{ getCityName(item) }}</td>
                <td :class="item.fee ? '' : 'missing'" v-if="done_type == 1 || done_type == 2">{{ item.fee ? item.fee : 'FEE?'}}</td>
                <td class="text-left" v-if="done_type == 3">{{ item.dancers == null  ? 'N/A' : item.dancers == 1 ? 'Yes' : 'No' }}</td>
                <td class="text-left" v-if="done_type == 3">{{ item.travel_exp == null  ? 'N/A' : item.travel_exp == 1 ? 'Yes' : 'No' }}</td>
                <td class="text-left" v-if="done_type == 3">{{ item.soundcheck ? item.soundcheck : '-' }}</td>
                <td class="text-left" v-if="done_type == 3">{{ item.start_time ? item.start_time : '-' }}</td>
                <td class="text-left" v-if="done_type == 3">{{ !item.notes ? '-' : item.notes.hotel ? item.notes.hotel : '-' }}</td>
                <td class="text-left" v-if="done_type == 3">{{ !item.notes ? '=' : item.notes.collect_at ? item.notes.collect_at : '-' }}</td>
                <td>
                  <template v-if="getVenuePhone(item)">
                    <v-icon small color="green lighten-2">fas fa-phone</v-icon>
                  </template>
                  <template v-else>
                    <v-icon small color="red lighten-2">fas fa-phone-slash</v-icon>
                  </template>
                </td>
                <td class="small" v-if="done_type == 1">{{ item.created_at }}</td>
                <td v-if="done_type == 1 || done_type == 2">
                  <template v-if="done_type == 1">
                    <v-text-field label="Fee" v-model="item.fee" @change="update(item)" outlined dense></v-text-field>
                  </template>
                  <template v-if="done_type == 2">
                    <v-text-field label="Advance" v-model="item.deposit" @change="update(item)" outlined dense></v-text-field>
                    <v-text-field label="Remainder" v-model="item.remainder" @change="update(item)" outlined dense></v-text-field>
                  </template>
                </td>
                <td>
                  <v-btn :href="`/calendars/${item.id}/payments/advance/create`" target="_blank" small color="green lighten-3" dark depressed v-if="done_type != 3">Adv.</v-btn>
                  <v-btn :href="`/calendars/${item.id}/payments/remainder/create`" target="_blank" small color="green lighten-3" dark depressed v-if="done_type != 3">Rem.</v-btn>
                  <v-btn @click="markAsDone(index, item.id)" small color="green lighten-2" dark depressed :loading="loading[index]">DONE</v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-pagination
            v-model="resourceObject.current_page"
            :length="resourceObject.last_page"
            :total-visible="5"
            @input="loadEvents"
          ></v-pagination>
          </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  export default {
    name: 'CalendarIndex',
    components: {},
    mixins: [],
    props: {},
    watch: {
      $route: function (to, from) {
        this.setDoneType(to.params.id)
      },
      'displayArtists': function () {
        this.loadEvents(1);
      }
    },
    data() {
      return {
        done_type: 1,
        displayArtists: [],
        loading: [],
        fetchLoading: true,
        resourceObject: {
          current_page: 1,
          next_page_url: '/api/calendars/todo-list'
        }
      }
    },
    computed: {
      ...mapGetters('calendar', ['getPerformancesByDateCreated', 'getPerformancesByDate']),
      ...mapState('calendar', ['calendar_status_list']),
    },
    created() {
      !this.$store.state.artist.artists.length ? this.getArtists() : true
    },
    mounted() {
      this.setDoneType(this.$route.params.id)
    },
    methods: {
      loadEvents(page) {

        this.fetchLoading = true
        let data = {
          type: this.done_type,
          filter_artists: this.displayArtists
        }
        this.resourceObject.current_page = page || 1

        this.axios.post('/api/calendars/todo-list?page=' + this.resourceObject.current_page, data).then((result) => {
          this.resourceObject = result.data
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          this.fetchLoading = false
        })
      },
      getVenueName(item) {
        if (item.hasOwnProperty('venue') && item.venue) {
          return item.venue.venue_name
        }

        return '-'
      },
      getVenuePhone(item) {
        if (item.hasOwnProperty('venue') && item.venue) {
          return item.venue.phone
        }

        return false
      },
      getCityName(item) {
        if (item.hasOwnProperty('venue') && item.venue && item.venue.hasOwnProperty('city_name')) {
          return item.venue.city_name.name
        }

        return '-'
      },
      setDoneType(route_param) {
        if(route_param == 'advance_payment') {
          this.done_type = 2
        }

        if(route_param == 'logistics') {
          this.done_type = 3
        }

        if(route_param == 'new') {
          this.done_type = 1
        }

        this.loadEvents()
      },
      calculateUrgencyClass(item) {
        if (this.done_type == 1) {
          return;
        }

        const today = moment()
        const diff = -today.diff(item.start, 'days')
        let color = "green"

        if(this.done_type == 2) {
          if(diff > 7 && diff < 4*7) {
            color = "yellow"
          }

          if(diff <= 7) {
            color = "red"
          }
        }

        if(this.done_type == 3) {
          if(diff > 2 && diff <= 5) {
            color = "yellow"
          }

          if(diff <= 2) {
            color = "red"
          }
        }

        return color
      },
      async update(item) {
        await this.updateCalendar(item)
      },
      async markAsDone(index, item_id) {
        this.$set(this.loading, index, true)
        await this.markEventAsDone({id: item_id, done_type: this.done_type || 1})
        this.resourceObject.data.splice(index, 1)
        this.$set(this.loading, index, false)
      },
      ...mapActions('calendar', ['fetchCalendar', 'markEventAsDone', 'updateCalendar']),
      ...mapActions('artist', ['getArtists'])
    }
  }
</script>

<style scoped>
  .missing {
    display: inline-block;
    background: gray;
    color: white;
    vertical-align: baseline;
  }

  .v-input >>> .v-text-field__details {
    display: none !important;
  }

  .event-urgent-status {
    display: table-cell;
  }
</style>
