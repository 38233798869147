<template>
  <div>
    <v-container>
      <v-row v-if="loading">
        <v-col>
          <v-progress-linear indeterminate rounded></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-btn color="primary" outlined :to="{'name': 'checklists-templates', params: { id: template.id }}">
            <v-icon small>keyboard_arrow_left</v-icon> Back
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card height="100%">
            <v-card-title>
              Template # {{ template.name }}
              <v-spacer></v-spacer>
              <v-btn icon :to="{'name': 'checklists-template-edit', params: { id: template.id }}">
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn icon @click="copy">
                <v-icon small>content_copy</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="col-md-6">
                <p><b>Checklists</b></p>

                <template v-for="item in template.checklists">
                  <ShowChecklistItem :item="item" @updateStatus="updateStatus"></ShowChecklistItem>
                </template>
              </div>

              <div class="col-md-6">
                <p><b>Tags:</b> {{ template.tags }}</p>
                <br/>
                <p><b>Notes:</b> {{ template.notes }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistsTemplateShow',
  components: {},
  mixins: [],
  props: {
    id: {}
  },
  data() {
    return {
      template: {},
      loading: true,
    }
  },
  computed: {
    ...mapState('errors', ['errors']),
  },
  watch: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const result = await this.fetchChecklistTemplate(parseInt(this.id))
      this.template = result.data;
      this.loading = false
    },
    async updateStatus(id) {
      this.$http.post('/api/checklists/status/' + id).then((response) => {
      }).catch((error) => {
        alert('error');
      })
    },
    async copy() {
      this.$http.post('/api/templates/copy/' + this.id).then((response) => {
        if (response) {
          this.template = response.data;
          this.$router.push({'name': 'checklists-template-show', params: {id: response.data.id}})
        }
      }).catch((error) => {
        alert('error');
      })
    },
    ...mapActions('checklistTemplate', ['fetchChecklistTemplate']),
  }

}
</script>

<style></style>
