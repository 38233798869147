<template>
	<div class="row">
		<div class="col-md-6">
			<v-card>
				<v-card-title>
					<template v-if="advanceDetails">
					Advance paid on {{ advanceDetails.date | DD-MM-YYYY }}
					</template>
					<template v-else>
						Advance
					</template>
					<v-btn fab dark small top right absolute color="pink" :href="'/calendars/'+ calendar_id +'/payments/advance/create'">
						<v-icon dark>edit</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>

					<table class="table table-hover">
						<thead>
						<tr>

							<th scope="col">Company</th>
							<th scope="col">IBAN</th>
							<th scope="col">Amount</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="transfer in payments">
							<template v-if="transfer.payment_type == 1">
								<th scope="row">{{transfer.company}}</th>
								<td>{{transfer.iban}}</td>
								<td>{{transfer.amount}}</td>
							</template>
						</tr>
						</tbody>
            <tfoot>
            <tr>
              <th scope="col"><b>TOTAL</b></th>
              <th scope="col"></th>
              <th scope="col">{{remainderTotal}}</th>
            </tr>
            </tfoot>
					</table>

					<hr>
					<template v-if="advanceDetails">
					<b>Note:</b> {{ advanceDetails.note }}
					</template>

				</v-card-text>
			</v-card>
		</div>
		<div class="col-md-6">
			<v-card>
				<v-card-title>
					<template v-if="remainderDetails">
					Remainder paid on {{ remainderDetails.date | DD-MM-YYYY}}
					</template>
					<template v-else>
						Remainder
					</template>
					<v-btn fab dark small top right absolute color="pink" :href="'/calendars/'+ calendar_id +'/payments/remainder/create'">
						<v-icon dark>edit</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>

					<table class="table table-hover">
						<thead>
						<tr>

							<th scope="col">Company</th>
							<th scope="col">IBAN</th>
							<th scope="col">Amount</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="transfer in payments">
							<template v-if="transfer.payment_type == 2">
								<th scope="row">{{transfer.company}}</th>
								<td>{{transfer.iban}}</td>
								<td>{{transfer.amount}}</td>
							</template>
						</tr>
						</tbody>
            <tfoot>
            <tr>
              <th scope="col"><b>TOTAL</b></th>
              <th scope="col"></th>
              <th scope="col">{{advanceTotal}}</th>
            </tr>
            </tfoot>
					</table>

					<hr>
					<template v-if="remainderDetails">
					<b>Note:</b> {{ remainderDetails.note }}
					</template>

				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {},
		computed: {
			calendar_id() {
				return window.calendar_id;
			},
      remainderTotal: function(){
        let sum = 0;
        this.payments.forEach(function(item) {
          if(item.payment_type === 1) {
            sum += parseFloat(item.amount);
          }
        });
        return sum;
      },
      advanceTotal: function(){
        let sum = 0;
        this.payments.forEach(function(item) {
          if(item.payment_type === 2) {
            sum += parseFloat(item.amount);
          }
        });
        return sum;
      }
		},
		data() {
			return {
				payments: [],
				remainderDetails: {
					date: '',
					note: ''
				},
				advanceDetails: {
					date: '',
					note: ''
				},
			}
		},
		watch: {},
		components: {},
		methods: {
			init() {
				this.$http.get('/calendars/payments/' + this.calendar_id).then((result) => {
					this.payments = result.data.payments;
					this.remainderDetails = result.data.paymentDetails.remainder;
					this.advanceDetails = result.data.paymentDetails.advance;
				});
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
