// import API from '@/api/ApiService'
// console.log(API)
import {tourResource} from '@/api/API'

export const namespaced = true

export const state = {
	tours: [],
	toursTotal: 0,
	tour: {}
}

export const mutations = {
	ADD_TOUR(state, tour) {
		state.events.push(tour)
	},
	SET_TOURS(state, data) {
		state.tours = data
	},
  REMOVE_TOUR(state, tour) {
	  // state.tours.splice(id) ?
  }
}

export const actions = {
  async fetchTour({ state, commit, rootState }, data) {
    console.log(data)
    return await tourResource.readOne({params: {id: data }})
  },
  async fetchTours({ state, commit, rootState }) {
    const tours = await tourResource.read()
    if (tours) commit('SET_TOURS', tours.data)
  },
	async storeTour({ state, commit, rootState }, data) {
    const status = await tourResource.create({data: data})
    return status
	},
  async patchTour({ state, commit, rootState }, data) {
    const status = await tourResource.update({data, params: {id: data.tour.id}})
    return status
  },
	async DELETE_TOUR({ state }) {
    await tourResource.remove()
	},
  async GET_TOURS({ state, commit, rootState }) {
	  const tours = await tourResource.read()
    if (tours) commit('SET_TOURS', tours.data)
	},

}

export const getters = {
  tours: state => state.tours
}
