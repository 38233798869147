<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Templates
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="ml-auto" color="info" :to="{'name': 'checklists-template-create'}">Create template</v-btn>
      </v-card-title>
      <v-card-text>

        <v-data-table
            :items="checklistTemplates"
            :headers="headers"
            :search="search"
            :options="options"
            :footer-props="footerProps"
        >
          <template v-slot:item.name="{ item }">
            <v-btn text :to="{'name': 'checklists-template-show', params: { id: item.id }}">
              {{ item.name }}
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn :to="{'name': 'checklists-template-show', params: { id: item.id }}" color="primary" icon>
              <v-icon small>visibility</v-icon>
            </v-btn>
            <v-btn icon :to="{'name': 'checklists-template-edit', params: { id: item.id }}">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn dark icon small color="pink tiny" @click="remove(item.id)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistsTemplates',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      headers: [
        {text: '#ID', value: 'id'},
        {text: 'Name' , value: 'name'},
        {text: 'Actions', value: 'actions'}
      ],
      search: '',
      options: {
        itemsPerPage: 50,
      },
      footerProps: {'items-per-page-options': [50, 100, 250, 500]},
    }
  },
  computed: {
    ...mapState('checklistTemplate', ['checklistTemplates']),
  },
  methods: {
    ...mapActions('checklistTemplate', ['getChecklistTemplates']),
    remove(id) {
      if (id) {
        this.$http.delete('/api/templates/' + id).then((response) => {
          location.reload();
        }).catch((error) => {
          console.log(error);
        });
      }

      this.$emit('remove', id);
    }
  },
  created() {
    this.getChecklistTemplates()
  }
}
</script>

<style></style>
