<template>
	<div>

		<div class="row">
			<div class="col-md-5">
				<v-card>
					<v-card-title>Artwork</v-card-title>
					<v-card-text>
						Please upload only square images above 1600 x 1600 px.
						<Uploader @load="(e) => track.image = e"></Uploader>
						<!--<v-image-input-->
								<!--v-model="track.image"-->
								<!--:image-quality="1"-->
								<!--clearable-->
								<!--image-format="jpeg"-->
								<!--full-height="true"-->
								<!--full-width="true"-->
						<!--/>-->
					</v-card-text>
				</v-card>
				<br>
			</div>
			<div class="col-md-7">
				<v-card>
					<v-card-title>Release Information</v-card-title>
					<v-card-text>

						<v-text-field
								v-model="track.title"
								label="Title"
						></v-text-field>

            <v-text-field
              v-model="track.genre"
              label="Genre"
            ></v-text-field>

						<div style="position: relative">
							<div v-for="(artist, index) in track.artists.primary">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="track.artists.primary.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-6">
										<v-autocomplete attach v-model="artist.id" :items="artists" item-text="artist_name"
														item-value="id" label="Primary artist"></v-autocomplete>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.artists.primary.push({id: null})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<hr>
						<div style="position: relative">
							<div v-for="(artist, index) in track.artists.featuring">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="track.artists.featuring.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-6">
										<v-autocomplete attach v-model="artist.id" :items="artists" item-text="artist_name"
														item-value="id" label="Featuring artist"></v-autocomplete>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.artists.featuring.push({id: null})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-6">
				<v-card>
					<v-card-title>Аsset Information - Sound Recording</v-card-title>
					<v-card-text>
						<div style="position: relative">
							<div class="row">
								<div class="col-sm-12 text-center">
									<b>Track Producer Artists</b>
								</div>
							</div>
							<div v-for="(artist, index) in track.producers.artists">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="track.producers.artists.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-6">
										<v-autocomplete attach v-model="artist.id" :items="artists" item-text="artist_name"
														item-value="id" label="Track Producer Artist"></v-autocomplete>
									</div>
									<div class="col-md-5">
										<v-text-field v-model="artist.share" label="Share"></v-text-field>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.producers.artists.push({id: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<hr>
						<div style="position: relative">
							<div class="row">
								<div class="col-sm-12 text-center">
									<b>Track Producer Labels</b>
								</div>
							</div>
							<div v-for="(label, index) in track.producers.labels">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="track.producers.labels.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-6">
										<v-autocomplete attach v-model="label.id" :items="labels" item-text="label_name"
														item-value="id" label="Track Producer Label"></v-autocomplete>
									</div>
									<div class="col-md-5">
										<v-text-field v-model="label.share" label="Share"></v-text-field>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.producers.labels.push({id: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
					</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6">
				<v-card>
					<v-card-title>Asset Information - Composition</v-card-title>
					<v-card-text>
						<div class="row relative">
							<template v-for="(author, index) in track.composition.author">
								<div class="col-md-1">
									<v-btn dark fab small color="pink tiny" @click="track.composition.author.splice(index, 1)">
										<v-icon>remove</v-icon>
									</v-btn>
								</div>
								<div class="col-md-5">
									<v-text-field v-model="author.name" label="Author (Lyrics)"></v-text-field>
								</div>
								<div class="col-md-6">
									<v-text-field v-model="author.share" label="Share"></v-text-field>
								</div>
							</template>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.composition.author.push({name: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<hr>
						<div class="row relative">
							<template v-for="(composer, index) in track.composition.composer">
								<div class="col-md-1">
									<v-btn dark fab small color="pink tiny" @click="track.composition.composer.splice(index, 1)">
										<v-icon>remove</v-icon>
									</v-btn>
								</div>
								<div class="col-md-5">
									<v-text-field v-model="composer.name" label="Composer (Music)"></v-text-field>
								</div>
								<div class="col-md-6">
									<v-text-field v-model="composer.share" label="Share"></v-text-field>
								</div>
							</template>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.composition.composer.push({name: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<hr>
						<div class="row relative">
							<template v-for="(arranger, index) in track.composition.arranger">
								<div class="col-md-1">
									<v-btn dark fab small color="pink tiny" @click="track.composition.arranger.splice(index, 1)">
										<v-icon>remove</v-icon>
									</v-btn>
								</div>
								<div class="col-md-5">
									<v-text-field v-model="arranger.name" label="Arranger"></v-text-field>
								</div>
								<div class="col-md-6">
									<v-text-field v-model="arranger.share" label="Share"></v-text-field>
								</div>
							</template>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.composition.arranger.push({name: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<hr>
						<div class="row relative">
							<template v-for="(publisher, index) in track.composition.publisher">
								<div class="col-md-1">
									<v-btn dark fab small color="pink tiny" @click="track.composition.publisher.splice(index, 1)">
										<v-icon>remove</v-icon>
									</v-btn>
								</div>
								<div class="col-md-5">
									<v-text-field v-model="publisher.name" label="Publisher"></v-text-field>
								</div>
								<div class="col-md-6">
									<v-text-field v-model="publisher.share" label="Share"></v-text-field>
								</div>
							</template>
							<v-btn absolute dark fab bottom small right color="pink" @click="track.composition.publisher.push({name: null, share: 0})">
								<v-icon>add</v-icon>
							</v-btn>
						</div>




					</v-card-text>
				</v-card>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-4">
				<v-card>
					<v-card-title>Metadata</v-card-title>
					<v-card-text>
						<div class="row">
							<div class="col-md-12 d-flex">
								<v-text-field v-model="track.audio_isrc" label="Audio ISRC"></v-text-field>
								<!--Generate Audio ISRC: <v-checkbox v-model="track.isrc.audio.auto" hide-details></v-checkbox> or enter custom ISRC:-->
								<!--<v-text-field :disabled="track.isrc.audio.auto" v-model="track.isrc.audio.value" label="ISRC"></v-text-field>-->
							</div>

							<div class="col-md-12 d-flex">
								<v-text-field v-model="track.video_isrc" label="Video ISRC"></v-text-field>
								<!--Generate Video ISRC: <v-checkbox v-model="track.isrc.video.auto" hide-details></v-checkbox> or enter custom ISRC:-->
								<!--<v-text-field :disabled="track.isrc.video.auto" v-model="track.isrc.video.value" label="ISRC"></v-text-field>-->
							</div>

							<div class="col-md-12">
								<v-text-field v-model="track.upc" label="UPC"></v-text-field>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>

			<div class="col-md-4">
				<v-card>
					<v-card-title>Asset Links</v-card-title>
					<v-card-text>
						<v-text-field v-model="track.assets.youtube" label="URL to YouTube" hint="Please insert only Video ID, for example '0WN79cA4Cds'"></v-text-field>
						<v-text-field v-model="track.assets.wave" label="URL to Wave"></v-text-field>
						<v-text-field v-model="track.assets.mp3" label="URL to Mp3"></v-text-field>
						<v-text-field v-model="track.assets.tv" label="URL to Video for TV"></v-text-field>
					</v-card-text>
				</v-card>
			</div>

			<div class="col-md-4">
				<v-card>
					<v-card-title>

							<div class="row">
								<div class="col-sm-10">License management</div>
								<div class="col-sm-2"><v-switch class="switch-fix" v-model="licenseEnabled"></v-switch></div>
							</div>
					</v-card-title>
					<v-card-text>
						<v-select
								attach
								:items="licenseTypes"
								label="For this song I am"
								:disabled="!licenseEnabled"
								v-model="track.license_type"
						></v-select>
<!--						<v-autocomplete  v-model="artist.id" :items="artists" item-text="artist_name"-->
<!--										item-value="id" label="Primary artist"></v-autocomplete>-->


						<div class="row">
							<div class="col-sm-6">
								<v-menu
										attach
										ref="menu1"
										v-model="menu1"
										:close-on-content-click="false"
										lazy
										transition="scale-transition"
										full-width
										max-width="290px"
										min-width="290px"
								>
									<template v-slot:activator="{ on }">
										<v-text-field
												v-model="track.license_from"
												label="From date"
												v-on="on"
												:disabled="!licenseEnabled"
										></v-text-field>
									</template>
									<v-date-picker v-model="track.license_from" no-title @input="menu1 = false" first-day-of-week="1"></v-date-picker>
								</v-menu>
							</div>
							<div class="col-sm-6">
								<v-menu
										attach
										ref="menu2"
										v-model="menu2"
										:close-on-content-click="false"
										lazy
										transition="scale-transition"
										full-width
										max-width="290px"
										min-width="290px"
										left
								>
									<template v-slot:activator="{ on }">
										<v-text-field
												v-model="track.license_to"
												label="To date"
												v-on="on"
												:disabled="!licenseEnabled"
										></v-text-field>
									</template>
									<v-date-picker v-model="track.license_to" no-title @input="menu2 = false" first-day-of-week="1"></v-date-picker>
								</v-menu>
							</div>
						</div>
						<v-textarea
								v-model="track.license_terms"
								name="input-7-4"
								label="Terms"
								value=""
								:disabled="!licenseEnabled"
						></v-textarea>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<v-card>
					<v-card-title>Description</v-card-title>
					<v-card-text>
						<v-textarea v-model="track.description"></v-textarea>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<v-btn class="pull-right" @click="save" color="primary">Save</v-btn>
			</div>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'
	import Uploader from '../../Misc/Uploader.vue'

	export default {
		props: {},
		computed: {
			track_id() {
				return window.track_id;
			}
		},
		data() {
			return {
				artists: [],
				labels: [],
				track: {
					image: null,
					title: '',
          genre: '',
					artists: {
						primary: [{}],
						featuring: [{}],
					},
					producers: {
						artists: [{}],
						labels: [{}]
					},
					composition: {
						author: [{name: null, share: 0}],
						composer: [{name: null, share: 0}],
						arranger: [{name: null, share: 0}],
						publisher: [{name: null, share: 0}]
					},
					isrc: {
						audio: {
							auto: true,
							value: ''
						},
						video: {
							auto: true,
							value: ''
						},
					},
					upc: '',
					assets: {
						youtube: '',
						wave: '',
						mp3: '',
						tv: ''
					},
					description: '',
					license_type: null,
					license_from: null,
					license_to: null,
					license_terms: null,
				},
				licenseEnabled: false,
				licenseTypes: [
					{value: 0, text: "== SELECT =="},
					{value: 1, text: "Licensor"},
					{value: 2, text: "Licensee"},
				],
				menu1: false,
				menu2: false,
			}
		},
		watch: {
			licenseEnabled: function (newVal, oldVal) {
				if(!newVal) {
					this.track.license_type = null
					this.track.license_from = null
					this.track.license_to = null
					this.track.license_terms = null
				}
			}
		},
		components: {
			Uploader
		},
		methods: {
			init() {
				this.$http.get('/api/tracks/'+ track_id +'/edit').then((result) => {
					this.track = result.data.track;
					this.track.assets = result.data.additional.assets;
					this.track.isrc = result.data.additional.isrc;
					this.track.producers = {
						artists: result.data.track.producers,
						labels: result.data.track.producers_label
					};

					_.forEach(this.track.producers.artists, (item) => {
						item.share = item.pivot.share;
					});

					_.forEach(this.track.producers.labels, (item) => {
						item.share = item.pivot.share;
					});

					this.track.artists = {
						primary: result.data.track.artists,
						featuring: result.data.track.featuring
					};

					this.artists = result.data.artists;
					this.labels = result.data.labels;

					if(!this.track.composition) {
						this.track.composition = {
							author: [{name: null, share: 0}],
							composer: [{name: null, share: 0}],
							arranger: [{name: null, share: 0}],
							publisher: [{name: null, share: 0}]
						}
					}

					console.log(!!this.track.license_type)
					this.licenseEnabled = !!this.track.license_type
				})

				this.track.image = window.track_image;
			},
			save(){
				this.$http.patch('/tracks/' + this.track_id , this.track).then((response) => {
					window.location = '/tracks'
				}).catch((error) => {
					alert('error');
				})
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
