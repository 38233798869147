<template>
  <div>
    <div v-for="(checklist, index) in checklists">
      <v-row v-if="checklists.length > 0">
        <v-col cols="12" class="mb-0 pb-0">
          <kbd>Level: {{ level }}</kbd>
        </v-col>
        <v-col cols="10">
          <v-text-field
            label="Checklists"
            v-model="checklist.title"
            v-validate="'required|max:200'"
            data-vv-name="title"
            :error-messages="errors.collect('checklist.title')"
          ></v-text-field>
        </v-col>
        <v-col cols="2" align-self="center">
          <v-btn dark fab x-small color="red tiny" @click="deleteItem(checklist.id, index)">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
              <v-btn @click="checklist.subs.push({subs: []})"
                     color="pink"
                     dark
                     block
                     outlined
                     left small>
                <v-icon small>add</v-icon> add subitem
              </v-btn>
        </v-col>
        <v-col>
          <template v-if="index+1 == checklists.length">
            <v-btn @click="checklists.push({subs: []})"
                   color="pink"
                   dark
                   block
                   outlined
                   left small>
              <v-icon small>add</v-icon> new Level {{ level }} element
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" class="m-0 pa-0">
          <v-divider class="ma-0"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" offset="1">
            <ChecklistItem :checklists="checklist.subs" :level="level+1"></ChecklistItem>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistItem',
  components: {},
  mixins: [],
  props: {
    checklists: {
      type: Array,
      default: function () {
        return []
      }
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      template: {},
      loading: false,
    }
  },
  computed: {
    ...mapState('errors', ['errors']),
  },
  watch: {},
  mounted() {

  },
  methods: {
    async deleteItem(id, index) {
      if (id) {
        let result = await this.deleteChecklist(id)
      }

      this.checklists.splice(index, 1)
    },
    ...mapActions('checklist', ['deleteChecklist']),
  }
}
</script>

<style></style>
