import { checklistTemplateResource } from '@/api/API'
export const namespaced = true

export const state = {
  checklistTemplates: [],
  checklistTemplatesTotal: 0,
  checklistTemplate: {}
}

export const mutations = {
  ADD_CHECKLIST_TEMPLATE(state, checklistTemplate) {
    state.checklistTemplates.push(checklistTemplate)
  },
  SET_CHECKLIST_TEMPLATES(state, checklistTemplates) {
    state.checklistTemplates = checklistTemplates
  },
  REMOVE_CHECKLIST_TEMPLATE(state, checklistTemplate) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeChecklistTemplate({ state, commit, rootState }, checklistTemplate) {
    const status = await checklistTemplateResource.create({ data: checklistTemplate })

    if (status) {
      commit('ADD_CHECKLIST_TEMPLATE', checklistTemplate)
    }

    return status
  },
  async deleteChecklistTemplate({ state }, id) {
    return await checklistTemplateResource.remove({params: {id: id}})
  },
  async getChecklistTemplates({ state, commit, rootState }) {
    const checklistTemplates = await checklistTemplateResource.read()
    if (checklistTemplates) commit('SET_CHECKLIST_TEMPLATES', checklistTemplates.data)
  },
  async fetchChecklistTemplate({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await checklistTemplateResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateChecklistTemplate({ state, commit, rootState }, checklistTemplate) {
    const status = await checklistTemplateResource.update({ data: checklistTemplate, params: {id: checklistTemplate.id}})
    return status
  }

}

export const getters = {
  checklistTemplates: state => state.checklistTemplates
}
