<template>
	<div>
		this is template body - Index
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {},
		computed: {},
		data() {
			return {
				item: ''
			}
		},
		watch: {},
		components: {},
		methods: {},
		mounted: function () {

		}
	}
</script>
