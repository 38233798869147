import { earningResource } from '@/api/API'
export const namespaced = true

export const state = {
  earnings: [],
  earningsTotal: 0,
  earning: {}
}

export const mutations = {
  ADD_EARNING(state, earning) {
    state.earnings.push(earning)
  },
  SET_EARNINGS(state, earnings) {
    state.earnings = earnings;
  },
  REMOVE_EARNING(state, earning) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeEarning({ state, commit, rootState }, earning) {
    const status = await earningResource.create({ data: earning })

    if (status) {
      commit('ADD_EARNING', earning)
    }

    return status
  },
  async deleteEarning({ state }) {
    await earningResource.remove()
  },
  async getEarnings({ state, commit, rootState }) {
    const earnings = await earningResource.read()
    if (earnings) commit('SET_EARNINGS', earnings.data)
  },
  async fetchEarning({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await earningResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateEarning({ state, commit, rootState }, earning) {
    const status = await earningResource.update({ data: earning, params: {id: earning.id}})
    return status
  }

}

export const getters = {
  earnings: state => state.earnings
}
