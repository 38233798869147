<template>
  <div class="container">
    <v-card>
      <v-card-title>User</v-card-title>
      <v-card-text>
        <v-text-field
          label="User name"
          v-model="user.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-text-field
          label="User email"
          v-model="user.email"
          v-validate="'required|max:200'"
          data-vv-name="email"
          :error-messages="errors.collect('email')"
        ></v-text-field>

        <v-text-field
          label="User Gmail"
          v-model="user.google_email"
          data-vv-name="google_email"
          :error-messages="errors.collect('google_email')"
        ></v-text-field>

        <v-text-field
          :type="'password'"
          label="Password"
          v-model="user.password"
          data-vv-name="password"
          :error-messages="errors.collect('password')"
        >

        </v-text-field>
        <v-btn color="info" @click.native="save" :loading="loading">Add User</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'UserCreate',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        user: {
          name: '',
          email: '',
          google_email: '',
          password: '',
        },
        loading: false
      }
    },
    computed: {
      ...mapState('errors', ['errors']),
    },
    watch: {},
    mounted() {
    },
    methods: {
      async save() {
        this.loading = true
        const result = await this.storeUser(this.user)

        if (result) {
          this.$router.push({'name': 'user-index'})
        }
        this.loading = false
      },
      ...mapActions('user', ['storeUser']),
    }
  }
</script>

<style></style>
