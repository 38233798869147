<template>
	<div class="row">
		<div class="col-md-1">
			<v-btn dark fab small color="pink tiny" @click="remove">
				<v-icon>remove</v-icon>
			</v-btn>
		</div>
		<div class="col-md-3">
		<v-select
				attach
				:items="artists"
				v-model="labelArtist.artist_id"
				item-text="artist_name"
				label="Artist"
				item-value="id"
		>
		</v-select>
		</div>
		<div class="col-md-3">

			<v-text-field
					label="Rate"
					v-model="labelArtist.fee"
			></v-text-field>
		</div>
		<div class="col-md-3">
			<v-select
					attach
					:items="rate_values"
					label="Value type"
					item-text="title"
					v-model="labelArtist.fee_type"
					:value="labelArtist.fee_type"
			></v-select>
		</div>
		<div class="col-md-2">
			<v-btn @click="save()">Save</v-btn>
		</div>


	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: ['labelArtist', 'artists'],
		computed: {},
		data() {
			return {
				rate_values: [
					{title: 'Flat rate', value: 1},
					{title: 'Percentage %', value: 2}
				],
			}
		},
		watch: {},
		components: {},
		methods: {
			init() {

			},
			save() {
				this.$http.post('/api/label-fees', this.labelArtist).then((response) => {
					console.log(response);
				});
			},
			remove() {
				if(this.labelArtist.hasOwnProperty('id')) {
					this.$http.delete('/api/label-fees/' + this.labelArtist.id).then((response) => {

					}).catch((error) => {
						console.log(error);
					});
				}

				this.$emit('remove', this.labelArtist);
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>