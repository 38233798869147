<template>
  <div class="container">
    <v-card>
      <v-card-title>Budget</v-card-title>
      <v-card-text>
        <v-text-field
          label="Budget Title"
          v-model="budget.title"
          v-validate="'required|max:200'"
          data-vv-name="title"
          :error-messages="errors.collect('title')"
        ></v-text-field>

        <v-autocomplete attach v-model="budget.client_id" :items="companies" item-text="company_name"
                        item-value="id" label="Client"></v-autocomplete>

        <v-autocomplete attach v-model="budget.agency_id" :items="companies" item-text="company_name"
                        item-value="id" label="Agency"></v-autocomplete>

        <v-autocomplete attach v-model="budget.artist_id" :items="artists" item-text="artist_name"
                        item-value="id" label="Artist"></v-autocomplete>

        <v-textarea label="Description"
                    v-model="budget.description"
                    v-validate="'max:1200'"
                    data-vv-name="description"
                    :error-messages="errors.collect('description')">
        </v-textarea>

        <v-textarea label="Keywords"
                    v-model="budget.keywords"
                    v-validate="'max:1200'"
                    data-vv-name="keywords"
                    :error-messages="errors.collect('keywords')">
        </v-textarea>

        <v-text-field
          label="Budget"
          v-model="budget.budget"
          v-validate="'required|max:10'"
          data-vv-name="budget"
          :error-messages="errors.collect('budget')"
        ></v-text-field>

        <v-autocomplete attach v-model="budget.currency" :items="currencies" item-text="title"
                        item-value="id" label="Currency"></v-autocomplete>

        <v-btn color="info" @click.native="save" :loading="loading">Add Budget</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'BudgetCreate',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        budget: {
          title: '',
          client_id: '',
          agency_id: '',
          artist_id: '',
          description: '',
          keywords: '',
          budget: '',
          currency: '',
        },
        currencies: [
          {
            'id': 'BGN',
            'title': 'BGN'
          },
          {
            'id': 'EUR',
            'title': 'EUR',
          },
          {
            'id': 'USD',
            'title': 'USD',
          }
        ],
        loading: false
      }
    },
    computed: {
      ...mapState('errors', ['errors']),
      ...mapState('company', ['companies']),
      ...mapState('artist', ['artists']),
    },
    watch: {},
    mounted() {
      !this.companies.length ? this.getCompanies() : false
      !this.artists.length ? this.getArtists() : false
    },
    methods: {
      async save() {
        this.loading = true
        const result = await this.storeBudget(this.budget)
        if (result) {
          this.$router.push({'name': 'budget-show', params: { id: result.data.id }})
        }
        this.loading = false
      },
      ...mapActions('budget', ['storeBudget']),
      ...mapActions('company', ['getCompanies']),
      ...mapActions('artist', ['getArtists']),
    }
  }
</script>

<style></style>
