<template>
	<div>
		<div class="col-xs-12 col-sm-8">
			<v-card>
				<v-card-title>Artists</v-card-title>
				<v-card-text>
					<template v-for="(labelArtist, index) in labelArtists">
						<CustomRateComponent :labelArtist="labelArtist" :artists="artists" @remove="labelArtists.splice(index, 1)"></CustomRateComponent>
					</template>


					<v-btn absolute dark fab bottom small right color="pink" @click="addArtist">
						<v-icon>add</v-icon>
					</v-btn>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<style>

</style>
<script>
	import CustomRateComponent from './CustomRateComponent'

	export default {
		props: {},
		computed: {
			label_id() {
				return 43;
			}
		},
		data() {
			return {
				labelArtists: [],
				artists: [],
			}
		},
		watch: {},
		components: {
			CustomRateComponent
		},
		methods: {
			init() {
				this.$http.get('/api/label-fees/'+ this.label_id +'/edit').then((result) => {
					this.artists = result.data.artists;
					this.labelArtists = result.data.fees;
				});
			},
			addArtist() {
				this.labelArtists.push({
					artist_id: null,
					label_id: this.label_id,
					fee: 0,
					fee_type: 2
				});
			}
		},
		mounted: function () {
			this.init()
		}
	}
</script>
