<template>
  <div class="container">
    <v-card>
      <v-card-title>User</v-card-title>
      <v-card-text>
        <v-text-field
          label="User name"
          v-model="user.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-text-field
          label="User email"
          v-model="user.email"
          v-validate="'required|max:200'"
          data-vv-name="email"
          readonly
          :error-messages="errors.collect('email')"
        ></v-text-field>

        <v-text-field
          label="User Gmail"
          v-model="user.google_email"
          data-vv-name="google_email"
          :error-messages="errors.collect('google_email')"
        ></v-text-field>

        <v-text-field
          :type="'password'"
          label="Password"
          v-model="user.password"
          data-vv-name="password"
          :error-messages="errors.collect('password')"
        >

        </v-text-field>
        <v-btn color="info" @click.native="save" :loading="loading">Update User</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'UserEdit',
    components: {},
    mixins: [],
    props: {
      id: {}
    },
    data() {
      return {
        user: {
          name: '',
          email: '',
          google_email: '',
          password: '',
        },
        loading: false
      }
    },
    computed: {
      ...mapState('errors', ['errors']),
    },
    watch: {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const result = await this.fetchUser(parseInt(this.id))
        this.user = result.data
      },
      async save() {
        this.loading = true
        const result = await this.updateUser(this.user, this.user.id)
        this.loading = false
      },
      ...mapActions('user', ['updateUser', 'fetchUser']),
    }
  }
</script>

<style></style>
