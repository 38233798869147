import { artistResource } from '@/api/API'
export const namespaced = true

export const state = {
  artists: []
}

/*
  Legacy -> SET_ARTIST -> SET_ARTISTS
 */

export const mutations = {
  ADD_ARTIST(state, tour) {
    state.artists.push(tour)
  },
  SET_ARTIST(state, data) {
    state.artists = data
  },
  SET_ARTISTS(state, data) {
    state.artists = data
  }
}

export const actions = {
  storeArtist() {},
  deleteArtist() {},
  async getArtists({ state, commit, rootState }) {
    const artists = await artistResource.read()
    if (artists) commit('SET_ARTIST', artists.data)
  },
}

export const getters = {
  // artists: state => state.artists,
  getArtistById: (state, getters) => (id) => {
    return state.artists.find(artist => artist.id === id)
  }
}
