<template>
  <div class="container">
    <v-card>
      <v-card-title>City</v-card-title>
      <v-card-text>
        <v-text-field
          label="City name"
          v-model="city.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-btn color="info" @click.native="save" :loading="loading">Add City</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  export default {
    name: 'CityCreate',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        city: {
          name: ''
        },
        loading: false
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      async save() {
        this.loading = true
        const result = await this.storeCity(this.city)

        if (result) {
          this.$router.push({'name': 'city-index'})
        }
        this.loading = false
      },
      ...mapActions('city', ['storeCity']),
    }
  }
</script>

<style></style>
