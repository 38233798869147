<template>
	<div>
		<div class="row">
			<div class="col-sm-8">
				<h3>Licenses</h3>
			</div>
			<div class="col-md-4 text-right">
				<a class="btn btn-info" href="/tracks">Tracks</a>
			</div>
		</div>
		<div class="row">
		<div class="col-md-12" v-if="loader">
			<v-progress-linear :indeterminate="true"></v-progress-linear>
		</div>
		<div class="col-md-12" v-if="!loader">
			<v-card>
				<v-card-text>
					<div class="table-responsive">
					<table class="table table-hover">
						<thead>
						<tr>
							<th scope="col">Title</th>
							<th scope="col">Artist</th>
							<th scope="col">License</th>
							<th scope="col">From</th>
							<th scope="col">To</th>
							<th scope="col">Terms</th>
						</tr>
						</thead>
						<tbody>
						<template v-for="track in tracks">
							<tr>
								<td class="text-nowrap"><a :href="`/tracks/${track.id}`">{{ track.title }}</a></td>
								<td class="text-nowrap">
									<template v-if="track.artists.length > 0" v-for="artist in track.artists">
										{{ artist.artist_name }}
									</template>
								</td>
								<td>{{ track.license_type }}</td>
								<td class="text-nowrap">{{ formatDate(track.license_from) }}</td>
								<td class="text-nowrap">{{ formatDate(track.license_to) }}</td>
								<td>{{ track.license_terms }}</td>
							</tr>
						</template>
						</tbody>
					</table>
					</div>
				</v-card-text>
			</v-card>
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LicenseIndex',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				loader: true,
				tracks: []
			}
		},
		computed: {},
		watch: {},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.$http.get('/api/tracks/licenses').then((result) => {
					this.tracks = result.data
				}).finally(() => {
					this.loader = false
				});
			},
			formatDate(d) {
				return moment(d).format("DD-MM-Y").toString();
			},
		}
	}
</script>

<style></style>