// import API from '@/api/ApiService'
// console.log(API)
import { contactResource } from '@/api/API'
import {contactCategoryResource} from "../../api/API";
export const namespaced = true

export const state = {
  contactCategories: [],
  contactCategoriesTotal: 0
}

export const mutations = {
  ADD_CONTACT(state, item) {
    state.contactCategories.push(item)
  },
  SET_CONTACT_CATEGORIES(state, items) {
    state.contactCategories = items
  },
  // REMOVE_TOUR(state, tour) {
  //   // state.tours.splice(id) ?
  // }
}

export const actions = {
  async storeContactCategory({ state, commit, rootState }, data) {
    const status = await contactCategoryResource.create({ data: data })

    if (status) {
      commit('ADD_CONTACT', data)
    }

    return status
  },
  async deleteContactCategory({ state }, id) {
    await contactCategoryResource.remove(id)
  },
  async getContactCategories({ state, commit, rootState }) {
    const response = await contactCategoryResource.read()
    if (response) commit('SET_CONTACT_CATEGORIES', response.data)
  },

}

export const getters = {
  contacts: state => state.contactCategories
}
