<template>
	<div>


			<div class="row">
				<div class="col-lg-12">
					<h1 class="page-header">Track - {{ track.title}}</h1>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-10">
								<a class="btn btn-info artist-listing" href="">Back to
									Artists</a>
							</div>
							<div class="col-md-2 artist-action-buttons">
								<a class="btn btn-primary" :href="'/tracks/'+ track.id +'/edit'">Edit</a>
								<!--								{{ Form::open(['method' => 'DELETE', 'route' => ['tracks.destroy',$track->id]]) }}-->
								<!--								{{ Form::submit('Delete', ['class' => 'btn btn-danger', 'id' => 'delete', 'data-toggle'-->
								<!--								=> "confirmation"]) }}-->
								<!--								{{ Form::close() }}-->
							</div>
						</div>

						<div class="row top-space"></div>

						<div class="row artist-main-info artist-section">
							<div class="col-md-4">
								<div class="row">
									<div class="col-md-12">
										<div class="panel panel-default">
											<div class="panel-heading">
												<b>ARTISTS</b>
											</div>
											<div class="panel-body">
												<template v-if="track.artists">
													Artists:
													<template v-for="artist in track.artists">
														<a :href="'/artists/' + artist.id">{{ artist.artist_name}}</a>,
													</template>
													<hr>
												</template>

												<template v-if="track.featuring">
													Featurings:
													<template v-for="artist in track.featuring">
														<a :href="'/artists/' + artist.id">{{ artist.artist_name}}</a>,
													</template>
													<hr>
												</template>
												<p>
													<small class="pull-right">(Last updated at
														{{ formatDate(track.updated_at) }})
													</small>
												</p>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>META DATA</b>
									</div>
									<div class="panel-body">
										<p>Audio ISRC: <b>{{ track.audio_isrc}}</b></p>
										<p>Video ISRC: <b>{{track.video_isrc}}</b></p>
										<p>UPC: <b>{{ track.upc}}</b></p>
									</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>REVENUE SHARE</b>
									</div>
									<div class="panel-body">
										<template v-if="track.producer_artist_id">
											<p>
												Produce Share - {{ track.producer_artist.artist_name}}:
												<b>{{ track.producer_artist_share}} %</b>
											</p>
										</template>
										<template v-if="track.producer_label_id">
											<p>
												Label Share - {{ track.producer_label.label_name}}:
												<b>{{track.producer_label_share}} %</b>
											</p>
										</template>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-12 text-uppercase center">
									<h3></h3>
								</div>
							</div>

							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>ASSETS</b>
									</div>
									<div class="panel-body">
										<template v-if="track.url_youtube">
											<p>Youtube URL: <b><a :href="'https://youtu.be/' + track.url_youtube" target="_blank">Link</a></b></p>
										</template>

										<template v-if="track.url_wave">
											<p>Wave URL: <b><a :href="track.url_wave" target="_blank">Link</a></b></p>
										</template>

										<template v-if="track.url_mp3">
											<p>MP3 URL: <b><a :href="track.url_mp3" target="_blank">Link</a></b></p>
										</template>

										<template v-if="track.url_video_tv">
											<p>Video TV URL: <b><a :href="track.url_video_tv" target="_blank">Link</a></b></p>
										</template>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>DESCRIPTION</b>
									</div>
									<div class="panel-body">

										{{ track.description }}
									</div>
								</div>

							</div>

              <div class="col-md-4">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <b>LICENSE</b>
                  </div>
                  <div class="panel-body" v-if="track.license_type != null">
                    <b>License Type:</b> {{ track.license_type == 1 ? 'Licensor' : 'Licensee' }} <br>
                    <b>From:</b> {{track.license_from}} <br>
                    <b>To:</b> {{track.license_to}} <br>
                    <b>License Description:</b> {{ track.description }}<br>
                  </div>
                  <div v-else>
                    <p>-</p>
                  </div>
                </div>

              </div>

							<div class="col-md-12">

								<hr style="border-color:#D8D8D8">

							</div>


							<div class="row">

								<div class="col-md-12 text-uppercase center">

									<h3>REVENUE</h3>
								</div>
							</div>

							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>TOTAL REVENUE</b>
									</div>

									<div class="panel-body">

										<p>
											Youtube: {{ revenue.total }}
										</p>
									</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>LAST MONTH REVENUE</b>
									</div>

									<div class="panel-body">

										<p>
											Youtube: {{ revenue.monthly }}
										</p>
									</div>
								</div>
							</div>

							<div class="col-md-4">
								<div class="panel panel-default">
									<div class="panel-heading">
										<b>LAST 12 MONTHS REVENUE</b>
									</div>

									<div class="panel-body">

										<p>
											Youtube: {{ revenue.yearly }}
										</p>
									</div>
								</div>
							</div>

						</div>
					</div>


				</div>
			</div>
	</div>

</template>

<script>
	export default {
		name: 'TrackShow',
		components: {},
		mixins: [],
		props: {
			id: {
				type: Number,
				required: true,
			}
		},
		data() {
			return {
				track: {},
				revenue: {}
			}
		},
		computed: {},
		watch: {},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.$http.get('/api/tracks/' + this.id).then((response) => {
					this.revenue = response.data.revenue
					this.track = response.data.track
				})
			},
			formatDate(date) {
				return moment(date).format('DD/MM/YYYY HH:mm:ss')
			}
		}
	}
</script>

<style></style>
