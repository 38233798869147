<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <v-btn small text><i class="far fa-bold"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <v-btn small text><i class="far fa-italic"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <v-btn small text><i class="far fa-strikethrough"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <v-btn small text><i class="far fa-underline"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <v-btn small text><i class="far fa-code"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <v-btn small text><i class="far fa-paragraph"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          <v-btn small text><i class="far fa-h1"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          <v-btn small text><i class="far fa-h2"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          <v-btn small text><i class="far fa-h3"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <v-btn small text><i class="far fa-list-ul"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <v-btn small text><i class="far fa-list-ol"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          @click="commands.link"
        >
          <v-btn small text><i class="far fa-link"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          @click="commands.undo"
        >
          <v-btn small text><i class="far fa-undo"></i></v-btn>
        </button>

        <button
          class="menubar__button"
          @click="commands.redo"
        >
          <v-btn small text><i class="far fa-redo"></i></v-btn>
        </button>

      </div>
    </editor-menu-bar>

    <br>
    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

  export default {
    props: {
      content: {
        type: String,
      }
    },
    components: {
      EditorContent,
      EditorMenuBar,
    },
    watch: {
      'content': function(newVal, oldVal) {
        this.editor.setContent(newVal)
      }
    },
    data() {
      return {
        editor: new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History(),
          ],
          content: this.content,
          onUpdate: ({ getHTML }) => {
            // get new content on update
            const newContent = getHTML()
            this.$emit('update:content', newContent)
          },
        }),
      }
    },
    beforeDestroy() {
      this.editor.destroy()
    },
  }
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &:focus {
      outline: none;
    }
  }

  .ProseMirror {
    outline: none !important;
  }

  $color-black: #000000;
  $color-white: #ffffff;
  $color-grey: #dddddd;

  .editor {
    position: relative;
    max-width: 30rem;
    margin: 0 auto 5rem auto;

  &__content {

     overflow-wrap: break-word;
     word-wrap: break-word;
     word-break: break-word;

  * {
    caret-color: currentColor;
  }

  pre {
    padding: 0.7rem 1rem;
    border-radius: 5px;
    background: $color-black;
    color: $color-white;
    font-size: 0.8rem;
    overflow-x: auto;

  code {
    display: block;
  }
  }

  p code {
    display: inline-block;
    padding: 0 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  blockquote {
    border-left: 3px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
    padding-left: 0.8rem;
    font-style: italic;

  p {
    margin: 0;
  }
  }

  img {
    max-width: 100%;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

  td, th {
    min-width: 1em;
    border: 2px solid $color-grey;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
  > * {
    margin-bottom: 0;
  }
  }

  th {
    font-weight: bold;
    text-align: left;
  }

  .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0; right: 0; top: 0; bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }

  .column-resize-handle {
    position: absolute;
    right: -2px; top: 0; bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }
  }

  .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  }
  }
</style>
