import Vue from 'vue';
import axios, { AxiosInstance } from 'axios'
import VueAxios from 'vue-axios'

function errorResponseHandler(error) {
  // check for errorHandle config
  if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    console.log('interceptor')
    console.log(error.response)
    addToErrorBag(error.response)
  }
}

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  errorResponseHandler(error)
  return Promise.reject(error);
});

Vue.use(VueAxios, axios)



/* Error validation for laravel */

/**
 * Adds errors to the vee-validate error bag
 *
 * @param   {obj}  errorResponse  [axios error.response]
 *
 * @return  {?obj}                 [returns the all errors with keys]
 */
function addToErrorBag(errorResponse) {
  // only allow this function to be run if the validator exists
  if (!hasProperty(window.app, '$validator')) return null;

  // check if there is data in the response
  if (!hasProperty(errorResponse, 'data')) return null;

  // clear errors
  window.app.$validator.errors.clear();

  // check if errors exist
  if (!hasProperty(errorResponse.data, 'errors')) return null;

  return loopThroughErrors.call(window.app, errorResponse.data);
}

const hasProperty = (obj, key) => {
  if (!obj) return false;

  const has = Object.prototype.hasOwnProperty;

  return has.call(obj, key);
};

function loopThroughErrors(data) {
  if (!data) {
    return null;
  }

  // Attempt to parse Laravel-structured validation errors.
  try {
    const messages = {};

    Object.keys(data.errors).forEach((key) => {
      messages[key] = data.errors[key].join(', ');

      window.app.$validator.errors.add({
        field: key,
        msg: messages[key],
      });
    });

    return messages;
  } catch (e) {
    return data;
  }
}
