<template>
	<div>
		<form>
			<div class="row">
				<div class="col-md-5">
					<v-card>
						<v-card-title>Crew</v-card-title>
						<v-card-text>
							<v-text-field
									label="Crew name"
									v-model="crew.name"
									v-validate="'required|max:100'"
									data-vv-name="name"
									:error-messages="errors.collect('name')"
							></v-text-field>
						</v-card-text>
					</v-card>
					<br>
					<v-card>
						<v-card-title>Label (Permanent Crew)</v-card-title>
						<v-card-text>
							<div v-for="(label, index) in crew.labels">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="crew.labels.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-6">
										<v-autocomplete attach v-model="label.id" :items="labels" item-text="label_name"
														item-value="id" label="Label"></v-autocomplete>
									</div>
									<div class="col-md-5">
										<el-input
												placeholder="Rate"
												v-model="label.fee"
												class="input-with-select"
												v-validate="'required|max:10'"
												data-vv-name="rate"
										>
											<el-select v-model="label.fee_type" slot="append"
													   placeholder="Type">
												<el-option label="Fixed" :value="feeTypes.FIXED"></el-option>
												<el-option label="%" :value="feeTypes.PERCENT"></el-option>
											</el-select>
										</el-input>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="addLabel">
								<v-icon>add</v-icon>
							</v-btn>
						</v-card-text>
					</v-card>
				</div>

				<div class="col-md-7 mb-3">
					<v-card>
						<v-card-title>Leading artists (Permanent Crew)</v-card-title>
						<v-card-text>
							<div v-for="(leadingArtist, index) in crew.leadingArtists">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="crew.leadingArtists.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-4">
										<v-autocomplete attach v-model="crew.leadingArtists[index].artist" :items="artists"
														item-text="artist_name" label="Artist"
														return-object></v-autocomplete>
									</div>
									<div class="col-md-3">
										<v-text-field
												v-model="crew.leadingArtists[index].position"
												label="Position"
										></v-text-field>
									</div>
									<div class="col-md-4">
										<el-input
												placeholder="Rate"
												v-model="crew.leadingArtists[index].fee"
												class="input-with-select"
												v-validate="'required|max:10'"
												data-vv-name="rate"
										>
											<el-select v-model="crew.leadingArtists[index].fee_type" slot="append"
													   placeholder="Type">
												<el-option label="Fixed" :value="feeTypes.FIXED"></el-option>
												<el-option label="%" :value="feeTypes.PERCENT"></el-option>
											</el-select>
										</el-input>
									</div>
								</div>
							</div>

							<v-btn absolute dark fab bottom small right color="pink" @click="addLeadingArtist">
								<v-icon>add</v-icon>
							</v-btn>
						</v-card-text>
					</v-card>
				</div>

				<div class="col-md-7">
					<v-card>
						<v-card-title>Secondary artist (Non-permanent Crew)</v-card-title>
						<v-card-text>
							<div v-for="(secondaryArtist, index) in crew.secondaryArtists">
								<div class="row">
									<div class="col-md-1">
										<v-btn dark fab small color="pink tiny" @click="crew.secondaryArtists.splice(index, 1)">
											<v-icon>remove</v-icon>
										</v-btn>
									</div>
									<div class="col-md-4">
										<v-autocomplete attach v-model="crew.secondaryArtists[index].artist" :items="artists"
														item-text="artist_name" label="Artist"
														return-object></v-autocomplete>
									</div>
									<div class="col-md-3">
										<v-text-field
												v-model="crew.secondaryArtists[index].position"
												label="Position"
										></v-text-field>
									</div>
									<div class="col-md-4">
										<el-input
												placeholder="Rate"
												v-model="crew.secondaryArtists[index].fee"
												class="input-with-select"
												v-validate="'required|max:10'"
												data-vv-name="rate"
										>
											<el-select v-model="crew.secondaryArtists[index].fee_type" slot="append"
													   placeholder="Type">
												<el-option label="Fixed" :value="feeTypes.FIXED"></el-option>
												<el-option label="%" :value="feeTypes.PERCENT"></el-option>
											</el-select>
										</el-input>
									</div>
								</div>
							</div>
							<v-btn absolute dark fab bottom small right color="pink" @click="addSecondaryArtist">
								<v-icon>add</v-icon>
							</v-btn>
						</v-card-text>
					</v-card>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<br>
					<v-btn class="pull-right" @click="save" color="primary">Save</v-btn>
				</div>
			</div>

		</form>
	</div>
</template>
<style>
	#test {
		right: 6px;
		bottom: -6px;
		background: #ff335b;
		color: white !important;
		padding: 4px 5px;
		border-radius: 3px;
		position: absolute;
		width: auto;
		font-size: 12px;
	}
</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {},
		computed: {},
		data() {
			return {
				feeTypes: {
					'FIXED': 1,
					'PERCENT': 2,
				},
				val: 0,
				rate_values: [
					{title: 'Flat rate', value: 1},
					{title: 'Percentage %', value: 2}
				],
				artists: [],
				labels: [],
				crew: {
					name: '',
					leadingArtists: [],
					secondaryArtists: [],
					labels: [],
				}
			}
		},
		watch: {},
		components: {},
		methods: {
			init() {
				this.$http.get('/api/crews/create').then((result) => {
					console.log(result);
					this.artists = result.data.artists;
					this.labels = result.data.labels;
				});
			},
			addLeadingArtist() {
				this.crew.leadingArtists.push({
					id: null,
					role: 1,
					position: '',
					fee: 0,
					fee_type: 1
				});
			},
			addSecondaryArtist() {
				this.crew.secondaryArtists.push({
					id: null,
					role: 2,
					position: '',
					fee: 0,
					fee_type: 1
				});
			},
			addLabel() {
				this.crew.labels.push({
					id: null,
					role: 3,
					position: '',
					fee: 0,
					fee_type: 1
				})
			},
			save() {
				this.$validator.validateAll();
				this.$http.post('/crews', this.crew).then((result) => {
					window.location.href="/crews";
				}).catch((error) => {
					console.log(error);
				});
			}
		},
		mounted: function () {
			this.init()
			this.addLeadingArtist()
			this.addSecondaryArtist()
			this.addLabel()
		}
	}
</script>
