<template>
	<div class="container">
    <div class="row">
      <div class="col-sm-2 mb-2">
        <v-card>
          <v-card-text>
            <router-link :to="{'name': 'city-index'}"><v-icon>home</v-icon> Cities</router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>
		<div class="row">
			<div class="col-md-12">
				<v-card>
					<v-card-title>Settings</v-card-title>
					<v-card-text>
						<template v-for="setting in settings">
							<template v-if="setting.type == 'bool'">
								<v-switch
										:label="setting.name"
										v-model="setting.value"
								></v-switch>
							</template>
						</template>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {},
		computed: {},
		data() {
			return {
				settings: false
			}
		},
		watch: {
			settings: {
				handler: function (newVal, oldVal) {
					if(oldVal) {
						this.save();
					}
				},
				deep: true
			}
		},
		components: {},
		methods: {
			save(){
				this.$http.post('/settings/setting', this.settings).then((result) => {
					console.log('saved');
				})
			}
		},
		mounted: function () {
			this.settings = localData;
		}
	}
</script>
