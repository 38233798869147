import { ResourceBuilder } from 'axios-rest-resource'
import axios, { AxiosInstance } from 'axios'

function errorResponseHandler(error) {
  // check for errorHandle config
  if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    console.log('interceptor')
    console.log(error.response)
    addToErrorBag(error.response)
  }
}

let resourceBuilderObj = new ResourceBuilder({
  baseURL: '/api/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
})

// apply interceptor on response
resourceBuilderObj.axiosInstance.interceptors.response.use(
  response => response,
  errorResponseHandler
);

export const resourceBuilder = resourceBuilderObj

// const api = axios.create({
//   baseURL: '/api/',
//   headers: {
//     'X-Requested-With': 'XMLHttpRequest',
//     'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
//   }
// });


// export default {
// 	getTours() {
// 	  return api.get('/tours')
// 	},
//   getCompanies() {
// 	  return api.get('/companies')
//   },
//   getArtists() {
//     return api.get('/artists')
//   }
// }

/* Error validation for laravel */

/**
 * Adds errors to the vee-validate error bag
 *
 * @param   {obj}  errorResponse  [axios error.response]
 *
 * @return  {?obj}                 [returns the all errors with keys]
 */
function addToErrorBag(errorResponse) {
  // only allow this function to be run if the validator exists
  if (!hasProperty(window.app, '$validator')) return null;

  // check if there is data in the response
  if (!hasProperty(errorResponse, 'data')) return null;

  // clear errors
  window.app.$validator.errors.clear();

  // check if errors exist
  if (!hasProperty(errorResponse.data, 'errors')) return null;

  return loopThroughErrors.call(window.app, errorResponse.data);
}

const hasProperty = (obj, key) => {
  if (!obj) return false;

  const has = Object.prototype.hasOwnProperty;

  return has.call(obj, key);
};

function loopThroughErrors(data) {
  if (!data) {
    return null;
  }

  // Attempt to parse Laravel-structured validation errors.
  try {
    const messages = {};

    Object.keys(data.errors).forEach((key) => {
      messages[key] = data.errors[key].join(', ');

      window.app.$validator.errors.add({
        field: key,
        msg: messages[key],
      });
    });

    return messages;
  } catch (e) {
    return data;
  }
}
