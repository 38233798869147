<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Projects
        <v-btn class="ml-auto" color="info" :to="{'name': 'project-create'}">Create project</v-btn>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="3" v-for="project in projects" :key="projects.id">
        <v-card>
          <v-card-title>
            <router-link :to="{'name': 'project-show', params: { id: project.id }}">{{ project.name }}</router-link>
          </v-card-title>
          <v-card-text>
            <br/>
            <p>
              <b>Client</b>
              : {{ project.client ? project.client.company_name : '' }}
            </p>
            <p>
              <b>Contractor</b>
              : {{ project.contractor ? project.contractor.company_name : '' }}
            </p>
            <p>
              <b>Transaction IDs</b>
              : {{ project.transaction_id }}
            </p>
            <p>
              <b>Documents Folder</b>
              : <a :href="project.documents_folder" target="_blank">LINK</a>
            </p>
            <p>
              <b>Budget</b>
              : {{ project.budget ? project.budget.budget : 0 | numeralFormat('0,000') }} {{ project.budget ?  project.budget.currency : '' }}
            </p>
            <p>
              <b>Current Balance</b>
              : {{ project.budget ? project.budget.balance : 0 | numeralFormat('0,000') }} {{ project.budget ? project.budget.currency : '' }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ProjectIndex',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('project', ['projects']),
  },
  methods: {
    ...mapActions('project', ['getProjects']),
  },
  created() {
    this.getProjects()
  }
}
</script>

<style></style>
