<template>
	<div class="row">
		<div class="col-xs-12 col-sm-6">
			<v-card>
				<v-card-title>Default Rate from Shows</v-card-title>
				<v-card-text>
					<div class="row">
						<div class="col-xs-4">
							<v-text-field
									label="Rate"
									v-model="default_show_rate.value"
							></v-text-field>
						</div>
						<div class="col-xs-4">
							<v-select
									v-model="default_show_rate.type"
									:items="rate_values"
									attach
									label="Value type"
									item-text="title"
							></v-select>
						</div>
						<div class="col-xs-4">
							<v-btn @click="saveDefaultRate">Save</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</div>
		<div class="col-xs-12 col-sm-6">
			<v-card>
				<v-card-title>Custom Rate from Shows</v-card-title>
				<v-card-text>
					<div v-for="(customRate, index) in custom_rates">
						<CustomRate :customRate="customRate" :artists="artists" @remove="custom_rates.splice(index, 1)"></CustomRate>
					</div>


					<v-btn absolute dark fab bottom small right color="pink" @click="addCustomArtist">
						<v-icon>add</v-icon>
					</v-btn>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<style>

</style>
<script>
	import CustomRate from './CustomRateComponent'

	export default {
		props: {},
		computed: {
			agent_id() {
				return window.agent_id;
			}
		},
		data() {
			return {
				agent: {},
				default_show_rate: {
					value: 0,
					type: 0
				},
				custom_rates: [],
				rate_values: [
					{title: 'Flat rate', value: 1},
					{title: 'Percentage %', value: 2}
				],
				artists: [],
			}
		},
		watch: {

		},
		components: {
			CustomRate
		},
		methods: {
			init() {
				this.$http.get('/api/agent-fees/' + this.agent_id + '/edit').then((result) => {
					this.artists = result.data.artists;
					this.custom_rates = result.data.fees;
					this.agent = result.data.agent;
					this.default_show_rate.value = result.data.agent.share_shows;
					this.default_show_rate.type = result.data.agent.fee_type;
				});
			},
			saveDefaultRate() {
				let data = {
					share_shows: this.default_show_rate.value,
					fee_type: this.default_show_rate.type,
				}

				this.$http.post('/api/agent-fees/' + this.agent_id + '/updateDefaultRate', data).then((result) => {

				}).catch((error) => {

				});
			},
			addCustomArtist() {
				this.custom_rates.push({
					artist_id: null,
					agent_id: this.agent_id,
					fee: 0,
					fee_type: 2
				});
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
