<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<v-card>
					<v-card-title>
						Payment of remainder for {{ artist.artist_name }} @ {{ event.venue.venue_name }} - {{ event.start | DD-MM-YYYY }}
					</v-card-title>
					<v-card-text>

						<b>Fee: {{event.fee}}</b> <br>

						Advance Payment: {{ event.deposit }} <br>
						Payment method: -
					</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6">
				<v-card>
					<v-card-title>
						Remainder payment
					</v-card-title>
					<v-card-text>
						<v-text-field
								label="Fee"
								v-model="remainder_payment.fee"
								v-validate="'required|max:10'"
								data-vv-name="fee"
								:error-messages="errors.collect('fee')"
						></v-text-field>

						Payment method:
						<v-radio-group v-model="remainder_payment.paymentType" row>
							<v-radio key="1" label="Bank" :value="paymentTypes.BANK"></v-radio>
							<v-radio key="2" label="Cash" :value="paymentTypes.CASH"></v-radio>
						</v-radio-group>

					</v-card-text>
				</v-card>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-6">
				<v-card>
					<v-card-title>
						Date of payment
					</v-card-title>
					<v-card-text>
						<v-menu
								ref="menu"
								:close-on-content-click="false"
								v-model="menu"
								:nudge-right="40"
								:return-value.sync="date"
								lazy
								transition="scale-transition"
								offset-y
								full-width
								min-width="290px"
						>
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-on="on"
                    v-model="computedDateFormatted"
                    label="Date of payment"
                    prepend-icon="event"
                    readonly
                ></v-text-field>
              </template>
							<v-date-picker v-model="date" @input="$refs.menu.save(date)" first-day-of-week="1"></v-date-picker>
						</v-menu>
					</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6">
				<v-card>
					<v-card-title>
						Promoter
					</v-card-title>
					<v-card-text>
						<div v-if="event.promoter_id">
							Promoter payment : Inclusive in Booking Agent Rate
							<!--<v-radio-group v-model="promoter.paymentType" row>-->
							<!--<v-radio key="1" label="Standalone" :value="promoterPaymentTypes.STANDALONE"></v-radio>-->
							<!--<v-radio key="2" label="Inclusive in Booking Agent Rate" :value="promoterPaymentTypes.INCLUSIVE"></v-radio>-->
							<!--</v-radio-group>-->
						</div>
						<div v-else>
							No promoter for this event.
						</div>
					</v-card-text>
				</v-card>

				<v-btn color="primary" @click="next()">Continue</v-btn>
			</div>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: [
			'artist',
			'event'
		],
		computed: {
      computedDateFormatted () {
        return this.format_date(this.date)
      },
		},
		data() {
			return {
				promoterPaymentTypes: {
					STANDALONE: 1,
					INCLUSIVE: 2
				},
				paymentTypes: {
					'CASH': 1,
					'BANK': 2
				},
				feeTypes: {
					'FIXED': 1,
					'PERCENT': 2,
				},
				date: moment().format("Y-MM-D").toString(),
				menu: false,
				fee: 0,
				paymentType: 1,
				advance_payment: {
					fee: 0,
					paymentType: 1,
				},
				remainder_payment: {
					fee: 0,
					paymentType: 1,
				},
				promoter: {
					paymentType: 1,
				}
			}
		},
		watch: {
			'advance_payment.fee': function(val) {
				this.remainder_payment.fee = this.event.fee - val;

				if(this.remainder_payment.fee < 0) {
					this.advance_payment.fee = this.event.fee / 2;
					this.remainder_payment.fee = this.event.fee / 2;
				}
			},
			'remainder_payment.fee': function(val) {
				this.advance_payment.fee = this.event.fee - val;

				if(this.advance_payment.fee < 0) {
					this.advance_payment.fee = this.event.fee / 2;
					this.remainder_payment.fee = this.event.fee / 2;
				}
			},
		},
		components: {},
		methods: {
			init() {
				this.advance_payment.fee = this.event.deposit;
				this.remainder_payment.fee = this.event.remainder;
			},
			next() {
				var data = {
					advance_payment: this.advance_payment,
					remainder_payment: this.remainder_payment,
					promoter: this.promoter,
					date: this.date
				}

				this.$emit('next', data);
			},
      format_date(date) {
        return moment(date).format('DD-MM-YYYY');
      }
		},
		mounted: function () {
			this.init();
		}
	}
</script>
