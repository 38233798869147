<template>
  <v-container>
    <v-row v-if="loadingData">
      <v-col>
        <v-progress-linear indeterminate rounded></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-else>
    <v-card height="100%">
      <v-card-title>Template # {{ template.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Template name"
              v-model="template.name"
              v-validate="'required|max:200'"
              data-vv-name="name"
              :error-messages="errors.collect('name')"
            ></v-text-field>

            <ChecklistItem :checklists="template.checklists" :level="1"></ChecklistItem>

            <v-btn @click="template.checklists.push({subs: []})"
                   color="pink"
                   block
                   dark
                  small>
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              label="Tags"
              v-model="template.tags"
            ></v-textarea>

            <v-textarea
              label="Note"
              v-model="template.notes"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" :to="{'name': 'checklists-template-show', params: { id: template.id }}">
          <v-icon small>keyboard_arrow_left</v-icon> Back
        </v-btn>
        <v-btn color="info" @click.native="update" :loading="loading">
          <v-icon small class="mr-4">save</v-icon> Save
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistsTemplateEdit',
  components: {},
  mixins: [],
  props: {
    id: {}
  },
  data() {
    return {
      template: {},
      loading: false,
      loadingData: true
    }
  },
  computed: {
    ...mapState('errors', ['errors']),
  },
  watch: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const result = await this.fetchChecklistTemplate(parseInt(this.id))
      this.template = result.data;
      this.template.checklists = result.data.checklists;
      this.loadingData = false
    },
    async update() {
      this.loading = true
      const result = await this.updateChecklistTemplate(this.template, this.id)

      console.log(result)

      if (result) {
        this.$router.push({'name': 'checklists-template-show', params: {id: result.data.id}})
      }

      this.loading = false
    },
    ...mapActions('checklistTemplate', ['updateChecklistTemplate', 'fetchChecklistTemplate']),
  }
}
</script>

<style></style>
