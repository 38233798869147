import Vue from 'vue'
import Vuex from 'vuex'

import * as artist from './modules/artist'
import * as calendar from './modules/calendar'
import * as city from './modules/city'
import * as company from './modules/company'
import * as contact from './modules/contact'
import * as contactCategory from './modules/contactCategory'
import * as tour from './modules/tour'
import * as track from './modules/track'
import * as budget from './modules/budget'
import * as user from './modules/user'
import * as project from './modules/project'
import * as earning from './modules/earning'
import * as checklistTemplate from './modules/checklistTemplate'
import * as checklist from './modules/checklist'

Vue.use(Vuex)

// strict: process.env.NODE_ENV !== 'production',
export default new Vuex.Store({
  strict: false,
	modules: {
		artist: artist,
    calendar: calendar,
    city: city,
    company: company,
    contact: contact,
    contactCategory: contactCategory,
    tour: tour,
    track: track,
    budget: budget,
    project: project,
    user: user,
    earning: earning,
    checklistTemplate: checklistTemplate,
    checklist: checklist,
	}
})
