require('./bootstrap')
require('./plugins/element-ui')
require('./plugins/axios-interceptor')




import Vue from 'vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import './plugins/filters'

/*
  TMP till refactor
 */


import VeeValidate from 'vee-validate'

Vue.use(VeeValidate)

import { ValidationProvider } from 'vee-validate';

// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider);

import VueLodash from 'vue-lodash'
Vue.use(VueLodash)

import VImageInput from 'vuetify-image-input';
Vue.component(VImageInput.name, VImageInput);

import VueMoment from 'vue-moment'
Vue.use(VueMoment);

import VueNumerals from 'vue-numerals'
Vue.use(VueNumerals, {
  locale: 'bg',
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
});

import VeeValidateLaravel from '@pmochine/vee-validate-laravel';
Vue.use(VeeValidateLaravel);

// Autoload all files from the components directory
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import store from "./store/store"

if(document.getElementById("app")) {
	let options = {
		el: '#app',
    vuetify,
		router,
		name: 'app',
    store,
		components: {},
		data: {},
		created: function () {},
		mounted() {}
	}

	window.app = new Vue(options);
}
