import { cityResource } from '@/api/API'
export const namespaced = true

export const state = {
  cities: [],
  citiesTotal: 0,
  city: {}
}

export const mutations = {
  ADD_CITY(state, city) {
    state.cities.push(city)
  },
  SET_CITIES(state, cities) {
    state.cities = cities
  },
  REMOVE_CITY(state, city) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeCity({ state, commit, rootState }, city) {
    const status = await cityResource.create({ data: city })

    if (status) {
      commit('ADD_CITY', city)
    }

    return status
  },
  async deleteCity({ state }) {
    await cityResource.remove()
  },
  async getCities({ state, commit, rootState }) {
    const cities = await cityResource.read()
    if (cities) commit('SET_CITIES', cities.data)
  },
  async fetchCity({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await cityResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateCity({ state, commit, rootState }, city) {
    const status = await cityResource.update({ data: city, params: {id: city.id}})
    return status
  }

}

export const getters = {
  cities: state => state.cities
}
