<template>
  <div class="container">
    <v-row>
      <div class="col-sm-12">
        <v-btn color="error" class="float-right" @click="deleteB">Delete</v-btn>
        <v-btn color="info" class="mr-2 float-right" :to="{name: 'budget-edit', params: {id: budget.id}}">Edit</v-btn>
        <v-btn color="secondary" class="mr-2 float-right" :to="{name: 'budget-index'}">Back</v-btn>
      </div>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <router-link :to="{ name: 'budget-show', params: { id: budget.id }}">{{ budget.title }}</router-link>
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="6">
                <p v-if="budget.client"><b>Client:</b> {{ budget.client.company_name }}</p>
                <p v-if="budget.agency"><b>Agency:</b> {{ budget.agency.company_name }}</p>
                <p v-if="budget.artist"><b>Artist:</b> {{ budget.artist.artist_name }}</p>
              </v-col>
              <v-col cols="6">
                <p v-if="budget.description"><b>Description:</b>
                  <v-dialog
                    v-model="descDialog"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                     <a v-bind="attrs" v-on="on">
                       {{ budget.description.substring(0, 100) + "..." }}
                     </a>
                    </template>
                    <v-card>
                      <v-card-text>
                      <p>{{ budget.description }}</p>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </p>
                <p v-if="budget.keywords"><b>Keywords:</b> {{ budget.keywords }}</p>
                <p v-if="budget.budget"><b>BUDGET:</b> {{ budget.budget | numeralFormat('0,000') }}
                  {{ budget.currency }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>TOTAL</v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="12">
                <b v-if="total">{{ total | numeralFormat('0,000') }} {{ budget.currency }}</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Income</v-card-title>
          <v-card-text class="mt-5">
            <v-row v-if="showEdit">
              <v-col cols="3">
                <v-textarea
                  v-model="incomes.description"
                  label="Description"
                  rows="1"
                >
                </v-textarea>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="incomes.value" label="Value">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="incomes.payment_from" label="Payment from">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="incomes.payment_to" label="Payment to">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-radio-group v-model="incomes.payment_method" label="Payment method">
                  <v-radio value="bank" label="Bank"></v-radio>
                  <v-radio value="cash" label="Cash"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="incomes.departments"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Select departments"
                  multiple
                  chips
                  :return-object="false"
                ></v-autocomplete>
                <v-btn
                  color="primary"
                  dark
                  small
                  @click.stop="dialog = true"
                > Add Department
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="incomes.note"
                  label="Note"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="info" @click.native="addIncomes" :loading="loading">Add</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Date</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Payment From</th>
                      <th class="text-left">Payment To</th>
                      <th class="text-left">Payment Method</th>
                      <th class="text-left">Departments</th>
                      <th class="text-left">Note</th>
                      <th class="text-left">Value</th>
                      <th class="text-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in budget.incomes" :key="item.id">
                      <td>{{ item.created_at | moment("DD.MM.YYYY") }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.payment_from }}</td>
                      <td>{{ item.payment_to }}</td>
                      <td>{{ item.payment_method }}</td>
                      <td>
                        <span v-for="department in item.departments">
                          {{ department.name }}, <br/>
                        </span>
                      </td>
                      <td>{{ item.note }}</td>
                      <td>{{ item.value | numeralFormat('0,000') }} {{ budget.currency }}</td>
                      <td>
                        <EditIncome :id="item.id"></EditIncome>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><b>Total</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b>{{ totalInc | numeralFormat('0,000') }} {{ budget.currency }}</b></td>
                    </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Expenses</v-card-title>
          <v-card-text class="mt-5">
            <v-row v-if="showEdit">
              <v-col cols="3">
                <v-textarea
                  v-model="expenses.description"
                  label="Description"
                  rows="1"
                >
                </v-textarea>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="expenses.value" label="Value">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="expenses.payment_from" label="Payment from">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="expenses.payment_to" label="Payment to">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-radio-group v-model="expenses.payment_method" label="Payment method">
                  <v-radio value="bank" label="Bank"></v-radio>
                  <v-radio value="cash" label="Cash"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="expenses.departments"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Select departments"
                  multiple
                  chips
                  :return-object="false"
                ></v-autocomplete>
                <v-btn
                  color="primary"
                  dark
                  small
                  @click.stop="dialog = true"
                > Add Department
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="expenses.note"
                  label="Note"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="info" @click.native="addExpenses" :loading="loading">Add</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Date</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Payment From</th>
                      <th class="text-left">Payment To</th>
                      <th class="text-left">Payment Method</th>
                      <th class="text-left">Departments</th>
                      <th class="text-left">Note</th>
                      <th class="text-left">Value</th>
                      <th class="text-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in budget.expenses" :key="item.id">
                      <td>{{ item.created_at | moment("DD.MM.YYYY") }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.payment_from }}</td>
                      <td>{{ item.payment_to }}</td>
                      <td>{{ item.payment_method }}</td>
                      <td>
                        <span v-for="department in item.departments">
                          {{ department.name }}, <br/>
                        </span>
                      </td>
                      <td>{{ item.note }}</td>
                      <td>{{ item.value | numeralFormat('0,000') }} {{ budget.currency }}</td>
                      <td><EditExpense :id="item.id"></EditExpense></td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><b>Total</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><b>{{ totalExp | numeralFormat('0,000') }} {{ budget.currency }}</b></td>
                    </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-if="showEdit"
              v-model="dialog"
              max-width="320"
    >
      <v-card>
        <v-card-title class="headline">Create new department?</v-card-title>
        <v-card-text>
          <v-text-field v-model="department.name" label="Name">
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="addDepartment"
          >
            Add Department
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import EditIncome from "./EditIncome";
import EditExpense from "./EditExpense";

export default {
  name: 'BudgetShow',
  components: {EditExpense, EditIncome},
  mixins: [],
  props: {
    id: {},
    showEdit: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      dialog: false,
      descDialog: false,
      department: {},
      budget: {},
      incomes: {},
      expenses: {},
      departments: [],
      loading: false
    }
  },
  computed: {
    total() {
      let totalExpenses = 0;
      _.forEach(this.budget.expenses, (label) => {
        totalExpenses += parseFloat(label.value);
      });

      let totalIncomes = 0;
      _.forEach(this.budget.incomes, (label) => {
        totalIncomes += parseFloat(label.value);
      });

      let total = '';

      total = totalIncomes - totalExpenses;

      return total;
    },
    totalExp() {
      let totalExp = 0;
      _.forEach(this.budget.expenses, (label) => {
        totalExp += parseFloat(label.value);
      });
      return totalExp;
    },
    totalInc() {
      let totalInc = 0;
      _.forEach(this.budget.incomes, (label) => {
        totalInc += parseFloat(label.value);
      });
      return totalInc;
    }
  },
  watch: {},
  mounted() {
    this.fetchData();
    this.getDepartments();
  },
  methods: {
    async fetchData() {
      const result = await this.fetchBudget(parseInt(this.id))
      this.budget = result.data;
    },
    async deleteB() {
      const result = await this.deleteBudget(parseInt(this.id))
      if (result) {
        this.$router.push({'name': 'budget-index'})
      }
    },
    async getDepartments() {
      this.$http.get('/api/departments').then((response) => {
        this.departments = response.data;
      }).catch((error) => {
        alert('error');
      })
    },
    async addDepartment() {
      this.$http.post('/api/departments', this.department).then((response) => {
        this.departments.push(response.data);
        this.dialog = false
      }).catch((error) => {
        alert('error');
      })
    },
    async addExpenses() {
      this.loading = true;
      let data = this.expenses;
      if (this.id) {
        data.budget_id = this.id
      }

      this.$http.post('/api/expenses', data).then((response) => {
        this.budget.expenses.push(response.data);
        this.expenses = {};
        this.loading = false;
      }).catch((error) => {
        alert('error');
        this.loading = false;
      })
    },
    async addIncomes() {
      this.loading = true;
      let data = this.incomes;
      if (this.id) {
        data.budget_id = this.id
      }

      this.$http.post('/api/incomes', data).then((response) => {
        this.budget.incomes.push(response.data);
        this.incomes = {};
        this.loading = false;
      }).catch((error) => {
        alert('error');
        this.loading = false;
      })
    },
    ...mapActions('budget', ['fetchBudget']),
    ...mapActions('budget', ['deleteBudget'])
  }
}
</script>

<style></style>
