<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<v-stepper v-model="stepper">
					<v-stepper-header>
						<v-stepper-step
								complete
								editable
								step="1"

						>
							Basic data
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step
								step="2"
						>Advance Payment Details</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step
								step="3"
						>
							Finish
						</v-stepper-step>
					</v-stepper-header>
				</v-stepper>
			</div>
		</div>
		<br>
		<div v-if="stepper == 1">
			<div v-if="artist && event">
				<Step1 :artist="artist" :event="event" :crews="crews" @next="toStep2"></Step1>
			</div>
		</div>
		<div v-if="stepper == 2">
			<Step2 :artist="artist" :event="event" :crew="crew" :data-from-step1="dataFromStep1" :escrow="escrow" @next="toStep3"></Step2>
		</div>
		<div v-if="stepper == 3">
      <v-card>
				<v-card-title>Success</v-card-title>
				<v-card-text>
					<table class="table table-hover">
						<thead>
						<tr>

							<th scope="col">Company</th>
							<th scope="col">IBAN</th>
							<th scope="col">Amount</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="transfer in bankTransfers">
							<th scope="row">{{transfer.company}}</th>
							<td>{{transfer.iban}}</td>
							<td>{{transfer.amount}}</td>
						</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>

      <v-card class="mt-4">
        <v-card-title>Change Status</v-card-title>
        <v-card-text>
          <br>
          <EditStatus :event_id="calendar_id"></EditStatus>
        </v-card-text>
      </v-card>

			<v-btn color="primary" :href="'/calendars/' + calendar_id +'/edit'">Back to Event</v-btn>
		</div>

	</div>
</template>
<style>

</style>
<script>
	import Step1 from './Advance/Step-1'
	import Step2 from './Advance/Step-2'
  import EditStatus from './EditStatus'

	export default {
		props: {},
		computed: {
			calendar_id() {
				return window.calendar_id;
			}
		},
		data() {
			return {
				stepper: 1,
				artist: null,
				event: null,
        escrow: false,
				crew: null,
				crews: null,
				dataFromStep1: null,
				bankTransfers: []
			}
		},
		watch: {},
		components: {
			Step1,
			Step2,
      EditStatus
		},
		methods: {
			init() {
				return new Promise((resolve, reject) => {
					this.$http.get('/calendars/' + this.calendar_id + '/payments/advance/create/data').then((result) => {
						this.event = result.data.calendar;

						if(this.event.promoter_id && this.event.promoter_fee == null) {
							this.event.promoter_fee = {
								fee: this.event.promoter.share_shows,
								fee_type: this.event.promoter.fee_type
							}
						}

						this.artist = result.data.artist;
						this.crew = result.data.crew;
						this.crews = result.data.crews;
						this.escrow = result.data.escrow;

						resolve();
					}).catch((error) => {
						reject();
					});
				});
			},
			toStep2(data) {
				console.log(data);

				if (data.selected_crew) {
					console.log('update crew');
					this.$http.post('/calendars/' + this.calendar_id + '/updateCrew', data.selected_crew).then((response) => {
						this.init().then(() => {
							console.log('Ajax completed');
							this.stepper = 2;
						});
					}).catch((error) => {

					});
				} else {
					this.stepper = 2;
				}

				this.dataFromStep1 = data;
			},
			toStep3(data) {
				this.stepper = 3;
				this.bankTransfers = data;
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
