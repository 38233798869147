import { resourceSchemaDefault } from 'axios-rest-resource'
import { resourceBuilder } from './ApiService'


/* Calendar Requests */
// export const calendarResource = resourceBuilder.build('/calendars')
export const calendarResource = resourceBuilder.build('/calendars', {
    ...resourceSchemaDefault,
    markEventAsDone: {
      method: 'post',
      url: '/{id}/mark-as-done'
    }
})

export const tourResource = resourceBuilder.build('/tours')
export const artistResource = resourceBuilder.build('/artists')
export const companyResource = resourceBuilder.build('/companies')
export const cityResource = resourceBuilder.build('/cities')
export const earningResource = resourceBuilder.build('/earnings')
export const userResource = resourceBuilder.build('/users')
export const contactResource = resourceBuilder.build('/contacts')
export const contactCategoryResource = resourceBuilder.build('/contacts/categories')
export const trackResource = resourceBuilder.build('/tracks')
export const budgetResource = resourceBuilder.build('/budgets')
export const projectResource = resourceBuilder.build('/projects')
export const checklistResource = resourceBuilder.build('/checklists')
export const checklistTemplateResource = resourceBuilder.build('/templates')
// export const tourResource = resourceBuilder.build('/tours')
