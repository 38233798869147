<template>
	<div>
<!--		{{ event_id }}-->
<!--    {{ event }}-->
    <v-select
      :items="statuses"
      v-model="event.status"
      label="Update Status"
      dense
      outlined
    ></v-select>
	</div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'

	export default {
		name: '',
		components: {},
		mixins: [],
		props: {
		  event_id: {
		    type: Number,
        required: true
      }
    },
		data() {
			return {
			  event: {},
        statuses: ['inquiry', 'contract_signed', 'deposit_invoice_send', 'deposit_paid', 'remainder_invoice_send', 'remainder_paid', 'advance_paid_to_artist', 'remainder_paid_to_artist', 'completed']
      }
		},
		computed: {
      ...mapGetters('calendar', ['getCalendarItemById']),
    },
		watch: {
      'event.status': function (newVal, oldVal) {
        console.log('status changed')
        console.log(oldVal)
        console.log(newVal)
        if(oldVal && newVal) {
          this.updateCalendar(this.event)
        }
      }
    },
		async created() {
		  this.event = this.getCalendarItemById(this.event_id)

      if(!this.event) {
        this.event = await this.fetchSingleCalendar(this.event_id);
      }
    },
		methods: {
      // async update(item) {
      //
      //   await this.updateCalendar(item)
      // },
      ...mapActions('calendar', ['fetchCalendar', 'markEventAsDone', 'updateCalendar', 'fetchSingleCalendar']),
    }
	}
</script>

<style></style>
