<template>
  <v-dialog
    v-model="dialogEditExpense"
    width="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs" v-on="on"
      >
        <v-icon>
          fa-edit
        </v-icon>
      </a>
    </template>
    <v-card>
      <v-card-title>Edit Expense #{{ this.id }}</v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="3">
            <v-textarea
              v-model="expenses.description"
              label="Description"
              rows="1"
            >
            </v-textarea>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="expenses.value" label="Value">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="expenses.payment_from" label="Payment from">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="expenses.payment_to" label="Payment to">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-radio-group v-model="expenses.payment_method" label="Payment method">
              <v-radio value="bank" label="Bank"></v-radio>
              <v-radio value="cash" label="Cash"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="expenses.departments"
              :items="departments"
              item-text="name"
              item-value="id"
              label="Select departments"
              multiple
              chips
              :return-object="false"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-textarea
              v-model="expenses.note"
              label="Note"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="info" @click.native="editExpenses" :loading="loading">Edit</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'EditExpense',
  components: {},
  mixins: [],
  props: {
    id: {},
  },
  data() {
    return {
      dialogEditExpense: false,
      expenses: {},
      departments: [],
      loading: false
    }
  },
  computed: {},
  mounted() {
    this.fetchData();
    this.getDepartments();
  },
  methods: {
    async fetchData() {
      this.$http.get('/api/expenses/' + this.id + '/edit').then((response) => {
        this.expenses = response.data;
        this.expenses.departments = response.data.departments;
      }).catch((error) => {
        alert('error');
      })
    },
    async getDepartments() {
      this.$http.get('/api/departments').then((response) => {
        this.departments = response.data;
      }).catch((error) => {
        alert('error');
      })
    },
    async editExpenses() {
      this.loading = true;
      let data = this.expenses;

      if (this.expenses.budget_id) {
        data.budget_id = this.expenses.budget_id
      }

      this.$http.put('/api/expenses/' + this.id, data).then((response) => {
        this.loading = false;
        this.dialogEditExpense = false;
        location.reload();
      }).catch((error) => {
        alert('error');
        this.loading = false;
      })
    },
  }
}
</script>

<style></style>
