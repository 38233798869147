// import API from '@/api/ApiService'
// console.log(API)
import {calendarResource} from '@/api/API'
import Vue from 'vue'

export const namespaced = true;

export const state = {
  calendars: [],
  calendar_status_list: ['inquiry', 'contract_signed', 'deposit_invoice_send', 'deposit_paid', 'remainder_invoice_send', 'remainder_paid', 'advance_paid_to_artist', 'remainder_paid_to_artist', 'completed']
};

export const mutations = {
  SET_CALENDAR(state, items) {
    state.calendars = items
  },
  REMOVE_CALENDAR(state, tour) {
    // state.tours.splice(id) ?
  },
  /*
    payload = {id: 1, data: {} }
   */
  UPDATE_CALENDAR_ITEM(state, payload) {

    let calendarIndex = state.calendars.findIndex( item => item.id === payload.id );
    Vue.set(state.calendars, calendarIndex, {...state.calendars[calendarIndex], ...payload.data});

  }
};

export const actions = {
  async fetchCalendar({ state, commit }) {
    const data = await calendarResource.read();
    if (data) {
      console.log(data);
      commit('SET_CALENDAR', data.data.calendars);
      commit('city/SET_CITIES', data.data.cities, { root: true });
      commit('artist/SET_ARTISTS', data.data.artists, { root: true });
      commit('company/SET_COMPANIES', data.data.companies, { root: true });
      commit('tour/SET_TOURS', data.data.tours, { root: true })
    }
  },
  async fetchSingleCalendar({ state, commit }, id) {
    const data = await calendarResource.readOne({params: {id: id}});

    // console.log('NOTGOOD')
    // console.log(data)

    return data.data
    // if (data) {
    //   console.log(data);
    //   commit('SET_CALENDAR', data.data.calendars);
    //   commit('city/SET_CITIES', data.data.cities, { root: true });
    //   commit('artist/SET_ARTISTS', data.data.artists, { root: true });
    //   commit('company/SET_COMPANIES', data.data.companies, { root: true });
    //   commit('tour/SET_TOURS', data.data.tours, { root: true })
    // }
  },
  async deleteCalendar({ state }, id) {
    return await calendarResource.remove({params: {id: id}})
  },
  async markEventAsDone({ state, commit, getters }, params) {

    const data = await calendarResource.markEventAsDone({data: {
        done_type: params.done_type
      }, params: {id: params.id}});

    if (data) {
      commit('UPDATE_CALENDAR_ITEM', {id: params.id, data: {done_at: true, done_by: true, status: 'Done'}})
    }
  },
  async updateCalendar({ state }, payload) {

    console.log(payload)

    const data = await calendarResource.update({data: payload, params: {id: payload.id}})

    console.log(data)
  }
};

export const getters = {
  tours: state => state.tours,
  getPerformancesByDateCreated: (state) => {
    // let filterType = filter ? filter : false;
    // console.log(filterType)
    let filtered = state.calendars.filter( (item) => {
      // if(filterType == 'NOT_DONE' && item.done_by) {
      //     return false
      // }

      if(item.type == 'performance') {
        return item;
      }
    });

    let ordered = _.sortBy(filtered, ['created_at']);
    return ordered.reverse();
  },
  getPerformancesByDate: (state) => {
    let filtered = state.calendars.filter( (item) => {
      if(item.type == 'performance' && moment(item.start).diff() > 0) {
        return item;
      }
    });

    let ordered = _.sortBy(filtered, ['date']);
    return ordered.reverse();
  },
  getCalendarItemById: (state) => (id) => {
    console.log('in2222')
    console.log(state.calendars)
    console.log('in222233333')
    let calendarItem = state.calendars.find(function (item) {
      if(item.id == id) {
        return item;
      }
    });
    console.log('in222233333aaaaaa')
    return calendarItem ? calendarItem : false
  }
};
