// import API from '@/api/ApiService'
// console.log(API)
import { contactResource } from '@/api/API'
export const namespaced = true

export const state = {
  contacts: [],
  contactsTotal: 0,
  editedContact: {}
}

export const mutations = {
  ADD_CONTACT(state, item) {
    state.contacts.push(item)
  },
  SET_CONTACTS(state, items) {
    state.contacts = items
  },
  SET_EDITED_CONTACT(state, item) {
    state.editedContact = item
  }
  // REMOVE_TOUR(state, tour) {
  //   // state.tours.splice(id) ?
  // }
}

export const actions = {
  async fetchContact({ state, commit, rootState }, data) {
    const response = await contactResource.readOne({params: {id: data }})
    if (response) commit('SET_EDITED_CONTACT', response.data)
    return response.data
  },
  async storeContact({ state, commit, rootState, dispatch }, data) {
    const status = await contactResource.create({ data: data })

    if (status) {
      // commit('ADD_CONTACT', data)
      dispatch('getContacts')
    }

    return status
  },
  async patchContact({ state, commit, rootState, dispatch }, data) {
    const status = await contactResource.update({ data: data, params: {id: data.id}})

    if (status) {
      dispatch('getContacts')
    }

    return status
  },
  async deleteContact({ state }, id) {
    await contactResource.remove(id)
  },
  async getContacts({ state, commit, rootState }) {
    const response = await contactResource.read()
    if (response) commit('SET_CONTACTS', response.data)
  },
  //  getContactById({ state, commit, rootState, dispatch }, id) {
  //   console.log(id)
  //   console.log(state.contacts)
  //   return;
  //   let contact = state.contacts.find(contact => contact.id === id)
  //   if (!contact) {
  //     dispatch('getContacts')
  //     contact = state.contacts.find(contact => contact.id === id)
  //   }
  //   return contact
  // }
}

export const getters = {
  contacts: state => state.contacts,
}
