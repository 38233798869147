<template>
  <div>
    <v-container grid-list-xl>
      <div class="row">
        <div class="col-12">
          <template v-if="loading">
            <v-skeleton-loader
              type="card-heading, list-item"
            ></v-skeleton-loader>
          </template>
          <template v-else>
            <v-card>
              <v-card-title>
                There are {{ stats.total }} issues on the TO DO list
              </v-card-title>
              <v-card-text>
                <v-chip
                  class="ma-2"
                  color="success"
                  outlined
                  :to="{'name': 'calendar-list', params: {id: 'new'}}"
                >
                  {{ stats.new }} issues in NEW EVENTS
                </v-chip>

                <v-chip
                  class="ma-2"
                  color="orange"
                  outlined
                  :to="{'name': 'calendar-list', params: {id: 'advance_payment'}}"
                >
                  {{ stats.advance_payment }} issues in ADVANCE PAYMENTS
                </v-chip>

                <v-chip
                  class="ma-2"
                  color="red"
                  outlined
                  :to="{'name': 'calendar-list', params: {id: 'logistics'}}"
                >
                  {{ stats.logistics }} issues in LOGISTICS
                </v-chip>

              </v-card-text>
            </v-card>
          </template>
        </div>
      </div>
      <v-layout row wrap class="d-flex">
        <v-flex xs12 sm3 v-for="(item, index) in items" :key="index">
            <v-card
              :color="item.color"
              :dark="item.dark"
            >
              <v-card-title primary-title>
                <div>
                  <div class="headline"><i class="fal" :class="item.icon"></i> {{ item.name }}</div>
                </div>
              </v-card-title>
              <v-card-actions>
                <template v-if="item.type == 'link'">
                  <v-btn text :href="item.to">{{ item.name }}</v-btn>
                </template>
                <template v-else>
                  <v-btn text :to="item.to">Go to {{ item.name }}</v-btn>
                </template>
              </v-card-actions>
            </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        stats: {},
        items: [
          {name: 'Artists', icon: 'fa-stars', type: 'link', to: '/artists', color: '#952175', dark: true},
          {name: 'Venues', icon: 'fa-map-marker-alt', type: 'link', to: '/venues', color: '#F9A825', dark: true},
          {name: 'Tracks', icon: 'fa-music', type: 'link', to: '/tracks', color: '#c2185b', dark: true},
          {name: 'Companies', icon: 'fa-building', type: 'link', to: '/companies', color: '#3F51B5', dark: true},
          {name: 'Agents', icon: 'fa-user-tie', type: 'link', to: '/agents', color: '#952175', dark: true},
          {name: 'Calendars', icon: 'fa-calendar-alt', type: 'router', to: '/calendars', color: '#F9A825', dark: true},
          {name: 'Earnings', icon: 'fa-money-check-alt', type: 'router', to: '/earnings', color: '#c2185b', dark: true},
          {name: 'Crews', icon: 'fa-users', type: 'link', to: '/crews', color: '#3F51B5', dark: true},
          {name: 'Tours', icon: 'fa-truck-ramp', type: 'router', to: '/tours', color: '#952175', dark: true},
          {name: 'Settings', icon: 'fa-cogs', type: 'router', to: '/settings', color: '#F9A825', dark: true},
          {name: 'Contacts', icon: 'fa-address-book', type: 'router', to: '/contacts', color: '#c2185b', dark: true},
          {name: 'Users', icon: 'fa-user-circle-o', type: 'router', to: '/users', color: '#3F51B5', dark: true},
          {name: 'Budgets', icon: 'fa-money-bill-alt', type: 'router', to: '/budgets', color: '#952175', dark: true},
          {name: 'Projects', icon: 'fa-project-diagram', type: 'router', to: '/projects', color: '#F9A825', dark: true},
          {name: 'Checklists', icon: 'fa-check', type: 'router', to: '/checklists/templates', color: '#952175', dark: true},
        ]
      }
    },
    created() {
      this.getStats()
    },
    mounted() {},
    methods: {
      getStats() {
        this.$http.get('/api/calendars/todo-list-stats').then(response => {
          this.stats = response.data
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
