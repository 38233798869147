<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Cities
        <v-btn class="ml-auto" color="info" :to="{'name': 'city-create'}">Create city</v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="city in cities">
          {{ city.name }} <v-btn icon :to="{'name': 'city-edit', params: { id: city.id }}"><v-icon small>edit</v-icon></v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'CitiesIndex',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {}
    },
    computed: {
      ...mapState('city', ['cities']),
    },
    methods: {
      ...mapActions('city', ['getCities'])
    },
    created() {
      this.getCities()
    }
  }
</script>

<style></style>
