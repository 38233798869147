<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Earnings - Last uploaded reports: &nbsp; <b>Youtube:</b> &nbsp; {{ earnings.lastUpdateMonth }} /
        {{ earnings.lastUpdateYear }}
        <br/>
        <v-btn class="ml-auto" color="info" href="/earnings/show-reports">Show Reports</v-btn>
        <v-btn class="ml-auto" color="info" href="/earnings/revenues">Import Revenues</v-btn>
        <v-btn class="ml-auto" color="info" href="/earnings/index/create">Manual Payment</v-btn>
      </v-card-title>
      <v-card-text>
        <h3>Artists</h3>
        <v-text-field
          v-model="search_artists"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers_artists"
          :items="earnings.artists"
          :search="search_artists"
          :items-per-page="-1"
        >
          <template v-slot:item.artist.artist_name="{ item }">
            <a :href="'/artists/'+item.artist.id">{{ item.artist.artist_name }} </a>
          </template>

          <template v-slot:item.total_payment="{ item }">
            {{ item.total_payment | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.price="{ item }">
            {{ item.last_payment_price | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.sum="{ item }">
            {{ item.sum | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.created_at="{ item }">
            {{ item.last_payment.created_at | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn v-if="item.sum > 0"
                     color="red"
                     dark
                     @click="paymentPost('artist', item.parent_id, item.sum)"
              >
                Pay
              </v-btn>
            </div>
          </template>

          <template slot="body.append">
            <tr>
              <th>TOTAL</th>
              <th><template v-if="totalArtist">{{ totalArtist | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
              <th></th>
              <th><template v-if="totalArtistRevenue">{{ totalArtistRevenue | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
            </tr>
          </template>

        </v-data-table>
      </v-card-text>

      <v-card-text>
        <h3>Agents</h3>
        <v-text-field
          v-model="search_agents"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers_agents"
          :items="earnings.agents"
          :search="search_agents"
          :items-per-page="-1"
        >

          <template v-slot:item.agent.agent_name="{ item }">
            <a :href="'/agents/'+item.agent.id">{{ item.agent.agent_name }} </a>
          </template>

          <template v-slot:item.total_payment="{ item }">
            {{ item.total_payment | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.price="{ item }">
            {{ item.last_payment.price | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.sum="{ item }">
            {{ item.sum | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.created_at="{ item }">
            {{ item.last_payment.created_at | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn v-if="item.sum > 0"
                     color="red"
                     dark
                     @click="paymentPost('agent', item.parent_id, item.sum)"
              >
                Pay
              </v-btn>
            </div>
          </template>

          <template slot="body.append">
            <tr>
              <th>TOTAL</th>
              <th><template v-if="totalAgent">{{ totalAgent | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
              <th></th>
              <th><template v-if="totalAgentRevenue">{{ totalAgentRevenue | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
            </tr>
          </template>

        </v-data-table>
      </v-card-text>

      <v-card-text>
        <h3>Labels</h3>
        <v-text-field
          v-model="search_labels"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers_labels"
          :items="earnings.labels"
          :search="search_labels"
          :items-per-page="-1"
        >

          <template v-slot:item.label.label_name="{ item }">
            <a :href="'/labels/'+item.label.id">{{ item.label.label_name }} </a>
          </template>

          <template v-slot:item.total_payment="{ item }">
            {{ item.total_payment | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.price="{ item }">
            {{ item.last_payment.price | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.sum="{ item }">
            {{ item.sum | numeralFormat('0,000') }} EUR
          </template>

          <template v-slot:item.last_payment.created_at="{ item }">
            {{ item.last_payment.created_at | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn v-if="item.sum > 0"
                     color="red"
                     dark
                     @click="paymentPost('labels', item.parent_id, item.sum)"
              >
                Pay
              </v-btn>
            </div>
          </template>

          <template slot="body.append">
            <tr>
              <th>TOTAL</th>
              <th><template v-if="totalLabel">{{ totalLabel | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
              <th></th>
              <th><template v-if="totalLabelRevenue">{{ totalLabelRevenue | numeralFormat('0,000') }} EUR</template></th>
              <th></th>
            </tr>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'EarningIndex',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      dialog: false,
      search_artists: '',
      headers_artists: [
        {text: 'Artist Name', value: 'artist.artist_name'},
        {text: 'Total', value: 'total_payment'},
        {text: 'Last Payment', value: 'last_payment.price'},
        {text: 'Last Payment Date', value: 'last_payment.created_at'},
        {text: 'Revenue', value: 'sum'},
        {text: 'Actions', value: 'actions'}
      ],
      search_agents: '',
      headers_agents: [
        {text: 'Agent Name', value: 'agent.agent_name'},
        {text: 'Total', value: 'total_payment'},
        {text: 'Last Payment', value: 'last_payment.price'},
        {text: 'Last Payment Date', value: 'last_payment.created_at'},
        {text: 'Revenue', value: 'sum'},
        {text: 'Actions', value: 'actions'}
      ],
      search_labels: '',
      headers_labels: [
        {text: 'Label Name', value: 'label.label_name'},
        {text: 'Total', value: 'total_payment'},
        {text: 'Last Payment', value: 'last_payment.price'},
        {text: 'Last Payment Date', value: 'last_payment.created_at'},
        {text: 'Revenue', value: 'sum'},
        {text: 'Actions', value: 'actions'}
      ]
    }
  },
  computed: {
    ...mapState('earning', ['earnings']),
    totalArtist() {
      let totalArtist = 0;
      _.forEach(this.earnings.artists, (item) => {
        totalArtist += parseFloat(item.total_payment);
      });
      return totalArtist;
    },
    totalArtistRevenue() {
      let totalArtistRevenue = 0;
      _.forEach(this.earnings.artists, (item) => {
        totalArtistRevenue += parseFloat(item.sum);
      });
      return totalArtistRevenue;
    },

    totalAgent() {
      let totalAgent = 0;
      _.forEach(this.earnings.agents, (item) => {
        totalAgent += parseFloat(item.total_payment);
      });
      return totalAgent;
    },
    totalAgentRevenue() {
      let totalAgentRevenue = 0;
      _.forEach(this.earnings.agents, (item) => {
        totalAgentRevenue += parseFloat(item.sum);
      });
      return totalAgentRevenue;
    },

    totalLabel() {
      let totalLabel = 0;
      _.forEach(this.earnings.labels, (item) => {
        totalLabel += parseFloat(item.total_payment);
      });
      return totalLabel;
    },
    totalLabelRevenue() {
      let totalLabelRevenue = 0;
      _.forEach(this.earnings.labels, (item) => {
        totalLabelRevenue += parseFloat(item.sum);
      });
      return totalLabelRevenue;
    },
  },
  methods: {
    ...mapActions('earning', ['getEarnings']),
    async paymentPost(module, parent_id, sum) {
      let conf = confirm("Confirm Pay?");
      if (conf === true) {
        let data = {};
        let url = '/api/earnings/' + parent_id;
        data.modules = module;
        data.price = sum;

        this.$http.put(url, data).then((response) => {
          location.reload();
        }).catch((error) => {
          alert('error');
        })
      }
    }
  },
  created() {
    this.getEarnings()
  }
}
</script>

<style></style>
