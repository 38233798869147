<template>
	<div>
		<v-card>
			<v-card-title>Reports</v-card-title>
			<v-card-text>
				<div class="row">

					<div class="col-md-3">
						<v-select v-model="reportType" :items="reportTypes" item-text="name" return-object
								  label="Report type"></v-select>
					</div>
					<div class="col-md-3">
						<template v-if="reportType.type == 1">
							<v-autocomplete
									:items="agents"
									:search-input.sync="search"
									v-model="selectedAgent"
									item-text="agent_name"
									flat
									hide-no-data
									hide-details
									return-object
									label="Select agent"
							>
								<template slot="item" slot-scope="data">
									<template v-if="data.item.favorite">
										<v-list-item-content>
											<v-list-item-title
													v-html="data.item.agent_name + ' <small>(favorite)</small>'"></v-list-item-title>
										</v-list-item-content>
									</template>
									<template v-else>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.agent_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</template>

						<template v-if="reportType.type == 2">
							<v-autocomplete
									:items="labels"
									:search-input.sync="search"
									v-model="selectedLabel"
									item-text="label_name"
									flat
									hide-no-data
									hide-details
									return-object
									label="Select label"
							>
								<template slot="item" slot-scope="data">
									<template v-if="data.item.favorite">
										<v-list-item-content>
											<v-list-item-title
													v-html="data.item.label_name + ' <small>(favorite)</small>'"></v-list-item-title>
										</v-list-item-content>
									</template>
									<template v-else>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.label_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</template>

						<template v-if="reportType.type == 3">
							<v-autocomplete
									:items="artists"
									:search-input.sync="search"
									v-model="selectedArtist"
									item-text="artist_name"
									flat
									hide-no-data
									hide-details
									return-object
									label="Select artist"
							></v-autocomplete>
						</template>

						<template v-if="reportType.type == 4">
							<v-autocomplete
									:items="crews"
									:search-input.sync="search"
									v-model="selectedCrew"
									item-text="crew_name"
									flat
									hide-no-data
									hide-details
									return-object
									label="Select crew"
							></v-autocomplete>
						</template>
					</div>
					<div class="col-md-3">
						<v-menu
								ref="menu"
								:close-on-content-click="false"
								v-model="menu"
								:nudge-right="40"
								:return-value.sync="dateFrom"
								transition="scale-transition"
								offset-y
								full-width
								min-width="290px"
						>
              <template v-slot:activator="{ on }">
							<v-text-field
                  v-on="on"
									v-model="dateFrom"
									label="Events from"
									prepend-icon="event"
									readonly
							></v-text-field>
              </template>
							<v-date-picker v-model="dateFrom" @input="$refs.menu.save(dateFrom)"></v-date-picker>
						</v-menu>
					</div>
					<div class="col-md-3">
						<v-menu
								ref="menu2"
								:close-on-content-click="false"
								v-model="menu2"
								:nudge-right="40"
								:return-value.sync="dateTo"
								transition="scale-transition"
								offset-y
								full-width
								min-width="290px"
						>
              <template v-slot:activator="{ on }">
							<v-text-field
                  v-on="on"
									v-model="dateTo"
									label="Events to"
									prepend-icon="event"
									readonly
							></v-text-field>
              </template>
							<v-date-picker v-model="dateTo" @input="$refs.menu2.save(dateTo)"></v-date-picker>
						</v-menu>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 text-right">
						<v-btn @click="loadTable()" color="primary">Generate report</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>
		<div class="row">
			<br>
			<br>
			<div class="col-md-9" v-if="loader">
				<v-progress-linear :indeterminate="true"></v-progress-linear>
			</div>
			<div class="col-md-9" v-if="reportType.type == 1 && !loader">
				<div v-for="(events, index) in tableData" v-if="tableData">
					<v-card>
						<v-card-text>
							<table class="table table-hover">
								<thead>
								<tr>
									<th scope="col">Payment date</th>
									<th scope="col">Artist</th>
									<th scope="col">Venue</th>
									<th scope="col">Show date</th>
									<th scope="col">Show fee</th>
									<th scope="col">Agent fee</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="event in events">
									<tr>
										<td v-html="listDates(event.paymentsForAgentAll)"></td>
										<td>{{ event.artist.artist_name }}</td>
										<td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
										<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
											formatDate(event.start) }}</a></td>
										<td>{{ event.paymentsForSelectedPeriod }}</td>
										<td>{{ event.paymentsForAgent }}</td>
									</tr>
								</template>
								<tr>
									<td><b>Total:</b></td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>{{ total[index].show }}</td>
									<td>{{ total[index].agent }}</td>
								</tr>
								</tbody>
							</table>
						</v-card-text>
					</v-card>
					<br>
				</div>
			</div>

			<div class="col-md-9" v-if="reportType.type == 2 && !loader">
				<div v-for="(events, index) in tableData" v-if="tableData">
					<v-card>
						<v-card-text>
							<table class="table table-hover">
								<thead>
								<tr>
									<th scope="col">Payments date</th>
									<th scope="col">Artist</th>
									<th scope="col">Venue</th>
									<th scope="col">Show date</th>
									<th scope="col">Show fee</th>
									<th scope="col">Artist fee</th>
									<th scope="col">Label fee</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="event in events">
									<tr>
										<td v-html="listDates(event.paymentsForLabelAll)"></td>
										<td>{{ event.artist.artist_name }}</td>
                    <td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
										<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
											formatDate(event.start) }}</a></td>
										<td>{{ event.paymentsForSelectedPeriod }}</td>
										<td>{{ event.paymentsForArtist }}</td>
										<td>{{ event.paymentsForLabel }}</td>
									</tr>
								</template>
								<tr>
									<td><b>Total:</b></td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>{{ total[index].show }}</td>
									<td>{{ total[index].artist }}</td>
									<td>{{ total[index].label }}</td>
								</tr>
								</tbody>
							</table>
						</v-card-text>
					</v-card>
					<br>
				</div>
			</div>

			<div class="col-md-9" v-if="reportType.type == 3 && !loader">
				<v-card v-if="tableData">
					<v-card-text>
						<table class="table table-hover">
							<thead>
							<tr>
								<th scope="col">Payment date</th>
								<th scope="col">Venue</th>
								<th scope="col">Show date</th>
								<th scope="col">Show fee</th>
								<th scope="col">Crew fee</th>
								<th scope="col">Booking fee</th>
								<th scope="col">Label fee</th>
								<th scope="col">Artist fee</th>
							</tr>
							</thead>
							<tbody>
							<template v-for="event in tableData">
								<tr>
									<td v-html="listDates(event.paymentsForArtistAll)"></td>
                  <td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
									<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
										formatDate(event.start) }}</a></td>
									<td>{{ event.paymentsForSelectedPeriod }}</td>
									<td>{{ event.paymentsForCrew }}</td>
									<td>{{ event.paymentsForAgent }}</td>
									<td>{{ event.paymentsForLabel }}</td>
									<td>{{ event.paymentsForArtist }}</td>
								</tr>
							</template>

							<tr>
								<td><b>Total:</b></td>
								<td>-</td>
								<td>-</td>
								<td>{{ total.show }}</td>
								<td>{{ total.crew }}</td>
								<td>{{ total.booking }}</td>
								<td>{{ total.label }}</td>
								<td>{{ total.artist }}</td>
							</tr>
							</tbody>
						</table>
					</v-card-text>
				</v-card>
				<br>
			</div>

			<div class="col-md-9" v-if="reportType.type == 4 && !loader">
				<div v-for="(events, index) in tableData" v-if="tableData">
					<v-card>
						<v-card-text>
							<table class="table table-hover">
								<thead>
								<tr>
									<th scope="col">Payment date</th>
									<th scope="col">Artist</th>
									<th scope="col">Venue</th>
									<th scope="col">Show date</th>
									<th scope="col">Show fee</th>
									<th scope="col">Crew member fee</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="event in events">
									<tr>
										<td>{{ formatDate(event.date) }}</td>
										<td>{{ event.member.artist_name }}</td>
                    <td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
										<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
											formatDate(event.event.start) }}</a></td>
										<td>{{ event.event.fee }}</td>
										<td>{{ event.fee }}</td>
									</tr>
								</template>
								<tr>
									<td><b>Total:</b></td>
									<td>-</td>
									<td>-</td>
									<td>-</td>
									<td>{{ total[index].show }}</td>
									<td>{{ total[index].member }}</td>
								</tr>
								</tbody>
							</table>
						</v-card-text>
					</v-card>
					<br>
				</div>
			</div>

			<div class="col-md-9" v-if="reportType.type == 5 && !loader">
				<div v-if="tableData.agent">
					<h3>Agent</h3>
					<div v-for="(events, index) in tableData.agent.events">
						<v-card>
							<v-card-text>
								<table class="table table-hover">
									<thead>
									<tr>
										<th scope="col">Payment date</th>
										<th scope="col">Artist</th>
										<th scope="col">Venue</th>
										<th scope="col">Show date</th>
										<th scope="col">Show fee</th>
										<th scope="col">Agent fee</th>
									</tr>
									</thead>
									<tbody>
									<template v-for="event in events">
										<tr>
											<td v-html="listDates(event.paymentsForAgentAll)"></td>
											<td>{{ event.artist.artist_name }}</td>
                      <td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
											<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
												formatDate(event.start) }}</a></td>
											<td>{{ event.paymentsForSelectedPeriod }}</td>
											<td>{{ event.paymentsForAgent }}</td>
										</tr>
									</template>
									<tr>
										<td><b>Total:</b></td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>{{ tableData.agent.total[index].show }}</td>
										<td>{{ tableData.agent.total[index].agent }}</td>
									</tr>
									</tbody>
								</table>
							</v-card-text>
						</v-card>
						<br>
					</div>

					<h3>Label</h3>
					<div v-for="(events, index) in tableData.label.events">
						<v-card>
							<v-card-text>
								<table class="table table-hover">
									<thead>
									<tr>
										<th scope="col">Payments date</th>
										<th scope="col">Artist</th>
										<th scope="col">Venue</th>
										<th scope="col">Show date</th>
										<th scope="col">Show fee</th>
										<th scope="col">Artist fee</th>
										<th scope="col">Label fee</th>
									</tr>
									</thead>
									<tbody>
									<template v-for="event in events">
										<tr>
											<td v-html="listDates(event.paymentsForLabelAll)"></td>
											<td>{{ event.artist.artist_name }}</td>
                      <td>{{ event.venue ? event.venue.venue_name : '-' }}</td>
											<td><a :href="'/calendars/' + event.id + '/edit'" target="_blank">{{
												formatDate(event.start) }}</a></td>
											<td>{{ event.paymentsForSelectedPeriod }}</td>
											<td>{{ event.paymentsForArtist }}</td>
											<td>{{ event.paymentsForLabel }}</td>
										</tr>
									</template>
									<tr>
										<td><b>Total:</b></td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>{{ tableData.label.total[index].show }}</td>
										<td>{{ tableData.label.total[index].artist }}</td>
										<td>{{ tableData.label.total[index].label }}</td>
									</tr>
									</tbody>
								</table>
							</v-card-text>
						</v-card>
						<br>
					</div>
				</div>
			</div>

			<div class="col-md-3" v-if="selectedObject">
				<v-card>
					<v-card-text>
						<b>{{reportType.name}}</b>:
						<template v-if="reportType.type == 1">
							{{ selectedObject.agent_name }}
							<br>
							<b>Total Income: </b> {{ totalAgentPayment }}
						</template>
						<template v-if="reportType.type == 2">
							{{ selectedObject.label_name }}
							<br>
							<b>Total Income: </b> {{ totalLabelPayment }}
						</template>
						<template v-if="reportType.type == 3">
							{{ selectedObject.artist_name }}
							<br>
							<b>Total Income: </b> {{ totalArtistPayment }}
						</template>
						<template v-if="reportType.type == 4">
							{{ selectedObject.crew_name }}
						</template>
						<template v-if="reportType.type == 5 && tableData">
							<br>
							Agent Total: {{ tableData.agent.totalAgentPayment }} <br>
							Label Total: {{ tableData.label.totalLabelPayment }} <br>
							<b>Total:</b>  {{ tableData.agent.totalAgentPayment + tableData.label.totalLabelPayment }}
						</template>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: {},
		computed: {
			'selectedObject': function () {
				if (this.reportType.type == 1) {
					return this.selectedAgent;
				} else if (this.reportType.type == 2) {
					return this.selectedLabel;
				} else if (this.reportType.type == 3) {
					return this.selectedArtist;
				} else if (this.reportType.type == 4) {
					return this.selectedCrew;
				} else if (this.reportType.type == 5) {
					return {};
				}

				return false;
			}
		},
		data() {
			return {
				search: null,
				loader: false,
				reportTypes: [
					{type: 1, name: 'AGENT'},
					{type: 2, name: 'LABEL'},
					{type: 3, name: 'ARTIST'},
					{type: 4, name: 'CREW'},
					{type: 5, name: 'FTS Report'},
				],
				reportType: {},
				paymentTypes: {
					'ADVANCE': 1,
					'REMAINDER': 2
				},
				paymentMethods: {
					'CASH': 1,
					'BANK': 2
				},
				agents: [],
				labels: [],
				artists: [],
				crews: [],
				selectedAgent: null,
				selectedArtist: null,
				selectedLabel: null,
				selectedCrew: null,
				dateFrom: moment().startOf('month').format("Y-MM-DD").toString(),
				dateTo: moment().format("Y-MM-DD").toString(),
				menu: false,
				menu2: false,
				tableData: false,
				totalAgentPayment: 0,
				totalLabelPayment: 0,
				totalArtistPayment: 0,
				total: {}
			}
		},
		watch: {
			reportType: function () {
				this.search = null;
				this.selectedAgent = null;
				this.selectedArtist = null;
				this.selectedLabel = null;
				this.selectedCrew = null;
				this.tableData = false;
			},
			selectedAgent: function () {
				this.loadTable();
			},
			selectedArtist: function () {
				this.loadTable();
			},
			selectedLabel: function () {
				this.loadTable();
			},
			selectedCrew: function () {
				this.loadTable();
			},
			dateFrom: function () {
				this.loadTable();
			},
			dateTo: function () {
				this.loadTable();
			},
		},
		components: {},
		methods: {
			init() {
				this.$http.get('/earnings/show-reports/showData').then((result) => {
					this.agents = result.data.agents;
					this.labels = result.data.labels;
					this.artists = result.data.artists;
					this.crews = result.data.crews;

					this.pushHardcodedValues();
				});
			},
			loadTable() {
				this.totalAgentPayment = 0;
				this.totalLabelPayment = 0;
				this.totalArtistPayment = 0;

				if (!this.selectedObject) {
					return;
				}

				let data = {
					reportType: this.reportType,
					dateFrom: this.dateFrom,
					dateTo: this.dateTo,
					entity: this.selectedObject
				};

				let callback;

				if (this.reportType.type == 1) {
					callback = (result) => {
						this.tableData = result.data.events;
						this.totalAgentPayment = result.data.totalAgentPayment;
						this.total = result.data.total;
					}
				} else if (this.reportType.type == 2) {
					callback = (result) => {
						this.tableData = result.data.events;
						this.totalLabelPayment = result.data.totalLabelPayment;
						this.total = result.data.total;
					}
				} else if (this.reportType.type == 3) {
					callback = (result) => {
						this.tableData = result.data.events;
						this.totalArtistPayment = result.data.totalArtistPayment;
						this.total = result.data.total;
					}
				} else if (this.reportType.type == 4) {
					callback = (result) => {
						this.tableData = result.data.events;
						// this.totalAgentPayment = result.data.totalAgentPayment;
						this.total = result.data.total;
					}
				} else if (this.reportType.type == 5) {
					callback = (result) => {
						this.tableData = result.data;
						// this.total = result.data.total;
					}
				}

				this.loader = true;
				this.$http.post('/earnings/show-reports/get-report', data).then((result) => {
					callback(result)
					this.loader = false
				});
			},
			formatDate(d) {
				return moment(d).format("DD-MM-Y").toString();
			},
			listDates(d) {
				let html = '';
				_.each(d, (item, key) => {
					html += "<small>" + this.formatDate(item.date) + "</small> | "
				});

				return html
			},
			pushHardcodedValues() {
				this.agents.unshift(
					{id: 2, agent_name: "Facing The Sun", favorite: true},
				)

				this.labels.unshift(
					{id: 46, label_name: "Facing The Sun", favorite: true},
					{id: 5, label_name: "Adamand", favorite: true},
				)
			}
		},
		mounted: function () {
			this.init();
		}
	}
</script>
