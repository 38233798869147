<template>
  <div class="container">
    <v-card>
      <v-card-title>
        Users
        <v-btn class="ml-auto" color="info" :to="{'name': 'user-create'}">Create user</v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="user in users">
          {{ user.name }}
          <v-btn icon :to="{'name': 'user-edit', params: { id: user.id }}">
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn dark fab small color="pink tiny" @click="remove(user.id)">
            <v-icon>remove</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'UserIndex',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {}
    },
    computed: {
      ...mapState('user', ['users']),
    },
    methods: {
      ...mapActions('user', ['getUsers']),
      remove(id) {
        if (id) {
          this.$http.delete('/api/users/' + id).then((response) => {
            location.reload();
          }).catch((error) => {
            console.log(error);
          });
        }

        this.$emit('remove', id);
      }
    },
    created() {
      this.getUsers()
    }
  }
</script>

<style></style>
