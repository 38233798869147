<template>
	<div>
    <tour-form :loading="loading" :tour.sync="tour" :artists="artists" :companies="companies" :cities="cities" @save="save()"></tour-form>
	</div>
</template>

<script>
  import TourForm from './TourForm'
  import {mapState, mapActions} from 'vuex'
	export default {
		name: 'TourCreate',
		components: {
      TourForm
    },
		mixins: [],
		props: {},
		data() {
			return {
        loading: false,
			  tour: {
			    tour: {},
			    artists: [],
          dates: [],
        },
      }
		},
		computed: {
      ...mapState('artist', ['artists']),
      ...mapState('company', ['companies']),
      ...mapState('city', ['cities']),
    },
		watch: {},
		mounted() {
      !this.artists.length ? this.getArtists() : false
      !this.companies.length ? this.getCompanies() : false
      !this.cities.length ? this.getCities() : false
		},
		methods: {
		  async save() {
		    this.loading = true
        const result = await this.storeTour(this.tour)
        this.loading = false
        if (result) {
          this.$router.push('/tours')
        }
      },
      ...mapActions('tour', ['storeTour']),
      ...mapActions('artist', ['getArtists']),
      ...mapActions('company', ['getCompanies']),
      ...mapActions('city', ['getCities'])
    }
	}
</script>

<style></style>
