// import API from '@/api/ApiService'
// console.log(API)
import { trackResource } from '@/api/API'
export const namespaced = true

export const state = {
  tracks: [],
  tracksTotal: 0
}

export const mutations = {
  ADD_TRACK(state, item) {
    state.tracks.push(item)
  },
  SET_TRACKS(state, items) {
    state.tours = items
  },
}

export const actions = {
  async fetchTrackById({ state, commit, rootState }, id) {
    const response = await trackResource.readOne({params: {id: id}})
    if (response) commit('ADD_TRACK', response.data)

    return response.data
  },
  async fatchTracks({ state, commit, rootState }) {
    const response = await trackResource.read()
    if (response) commit('SET_TRACKS', response.data)
  },

}

export const getters = {
  tracks: state => state.tracks,
  getTrackById: (state, getters) => (id) => {
    return state.tracks.find(track => track.id === id)
  }
}
