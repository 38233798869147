import { userResource } from '@/api/API'
export const namespaced = true

export const state = {
  users: [],
  usersTotal: 0,
  user: {}
}

export const mutations = {
  ADD_USER(state, user) {
    state.users.push(user)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  REMOVE_USER(state, user) {
    // state.tours.splice(id) ?
  }
}

export const actions = {
  async storeUser({ state, commit, rootState }, user) {
    const status = await userResource.create({ data: user })

    if (status) {
      commit('ADD_USER', user)
    }

    return status
  },
  async deleteUser({ state }) {
    await userResource.remove()
  },
  async getUsers({ state, commit, rootState }) {
    const users = await userResource.read()
    if (users) commit('SET_USERS', users.data)
  },
  async fetchUser({ state, commit, rootState }, id) {
    console.log('starting fetch')
    const status = await userResource.readOne({ params: { id: id } })
    console.log('ending fetch')
    return status
  },
  async updateUser({ state, commit, rootState }, user) {
    const status = await userResource.update({ data: user, params: {id: user.id}})
    return status
  }

}

export const getters = {
  users: state => state.users
}
