<template>
  <div class="container">
    <v-card height="100%">
      <v-card-title>Template</v-card-title>
      <v-card-text>
        <div class="col-md-6">
          <v-text-field
            label="Template name"
            v-model="template.name"
            v-validate="'required|max:200'"
            data-vv-name="name"
            :error-messages="errors.collect('name')"
          ></v-text-field>

          <ChecklistItem :checklists="template.checklists" :level="1"></ChecklistItem>

          <div class="row">
            <div class="col-md-12">
              <v-btn @click="template.checklists.push({subs: []})"
                     absolute
                     bottom
                     color="pink"
                     dark
                     fab
                     left small>
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <v-textarea
            label="Tags"
            v-model="template.tags"
            data-vv-name="tags"
            :error-messages="errors.collect('tags')"
          ></v-textarea>

          <v-textarea
            label="Note"
            v-model="template.notes"
            data-vv-name="notes"
            :error-messages="errors.collect('notes')"
          ></v-textarea>
          <v-btn color="info" @click.native="save" :loading="loading">Add Template</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import ChecklistItem from "./ChecklistItem";

export default {
  name: 'ChecklistsTemplateCreate',
  components: {
    ChecklistItem
  },
  mixins: [],
  props: {},
  data() {
    return {
      template: {
        name: '',
        tags: '',
        notes: '',
        checklists: [
          {
            title: '',
            subs: []
          }
        ]
      },
      loading: false
    }
  },
  computed: {
    ...mapState('errors', ['errors']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    async save() {
      this.loading = true
      const result = await this.storeChecklistTemplate(this.template)
      if (result) {
        this.$router.push({'name': 'checklists-template-show', params: {id: result.data.id}})
      }
      this.loading = false
    },
    ...mapActions('checklistTemplate', ['storeChecklistTemplate']),
  }
}
</script>

<style></style>
