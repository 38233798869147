<template>
	<div>
    <v-card class="mb-2">
      <v-card-text class="justify-content-end text-right">
        <v-btn color="yellow" href="/calendars/calendarIntegrationStatus">Integration status</v-btn>
        <v-btn color="primary darken-2" :to="{name: 'calendar-list'}" target="_blank">TO-DO</v-btn>
      </v-card-text>
    </v-card>
		<v-card class="mb-2">
		<v-card-text>
			<template v-if="loading">
				<v-progress-linear :indeterminate="true"></v-progress-linear>
			</template>
			<template v-else>
				<FullCalendar
            ref="calendar"
						:aspect-ratio="2"
						defaultView="dayGridMonth"
						:plugins="calendarPlugins"
						:editable="true"
						:events="filteredEvents"
						:event-limit="true"
						:firstDay="1"
						:header="calendarHeader"
						:selectable="true"
						:displayEventTime="false"
						:views="calendarViews"
            :defaultDate="defaultDate"
						@eventDrop="changeEventDate"
						@eventClick="eventClick"
						@select="dateSelected"
            :datesRender="localStorageDate"
				/>
			</template>
		</v-card-text>
		</v-card>
		<v-card>
			<v-card-text>
				<div class="row">
					<div class="col-12 col-md-3">
						<v-autocomplete
								v-model="artistFilter"
								:items="artists"
								item-text="artist_name"
								item-value="id"
								label="Select Artist"
								clearable
                @change="localStorageArtistFilter(artistFilter)"
						></v-autocomplete>
					</div>
					<div class="col-12 col-md-3">
						<v-autocomplete
								v-model="venueFilter"
								:items="venues"
								item-text="venue_name"
								item-value="id"
								label="Select Venue"
								clearable
						></v-autocomplete>
					</div>
					<div class="col-12 col-md-3">
						<v-autocomplete
								v-model="cityFilter"
								:items="cities"
								item-text="name"
								item-value="id"
								label="Select City"
								clearable
						></v-autocomplete>
					</div>
					<div class="col-12 col-md-3">
						<v-switch
								v-model="showOnlyPerformances"
								label="Performances Only"
                @change="localStoragePerformanceFilter"
						></v-switch>
            <v-switch
              v-model="hideCanceledPerformances"
              label="Hide GC Canceled"
              @change="localStorageCanceledFilter"
            ></v-switch>
            <v-switch
              v-model="showOldEvents"
              label="Show old events"
              @change="localStorageCanceledFilter"
            ></v-switch>
					</div>
				</div>
			</v-card-text>
		</v-card>

		<EventCreate
				:agents="agents"
				:artists="artists"
				:companies="companies"
				:dialog.sync="createEventDialog"
				:event-date="createEventDate"
				:events="events"
        :tours="tours"
				:users="users"
				:venues="venues"
				@eventCreated="loadEvents"
		></EventCreate>
	</div>
</template>

<script>
	import FullCalendar from '@fullcalendar/vue'
	import listPlugin from '@fullcalendar/list';
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin from '@fullcalendar/interaction';

	import EventCreate from './EventCreate'

	export default {
		name: 'CalendarIndex',
		components: {
			FullCalendar,
			EventCreate
		},
		mixins: [],
		props: {},
		data() {
			return {
				artists: [],
				agents: [],
				cities:[],
				cityFilter: null,
				companies: [],
        defaultDate: '',
        showOnlyPerformances: null,
        hideCanceledPerformances: null,
				artistFilter: null,
				venueFilter: null,
				calendarHeader: {
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,dayGridWeek,listWeek,listMonth'
				},
				calendarPlugins: [ dayGridPlugin, interactionPlugin, listPlugin],
				calendarViews: {
					month: {
						eventLimit: 5 // adjust to 6 only for agendaWeek/agendaDay
					}
				},
        showOldEvents: false,
				events: [],
				venues: [],
				loading: true,
				createEventDialog: false,
				createEventDate: {
					start: null,
					end: null,
				},
        tours: [],
				users: []
			}
		},
		computed: {
			filteredEvents() {
				let events = this.events;

				if (this.showOnlyPerformances) {
					events = _.filter(events, ['type', "performance"]);
				}

				if (this.artistFilter) {
					events = _.filter(events, ['artist.id', this.artistFilter])
				}

				if (this.venueFilter) {
					events = _.filter(events, ['venue.id', this.venueFilter])
				}

				if (this.cityFilter) {
					events = _.filter(events, ['venue.city', this.cityFilter])
				}

        if (this.hideCanceledPerformances) {
          events = _.reject(events, ['gc_status', 'cancelled'])
        }

				return events
			},
		},
		watch: {
      showOldEvents: function (from, to) {
        this.loadEvents()
      }
    },
		mounted() {
			this.loadEvents()
      this.showOnlyPerformances = this.$ls.get('show_only_performances', false)
      this.hideCanceledPerformances = this.$ls.get('hide_canceled_performances', false)
      this.artistFilter = this.$ls.get('artist_filter', false)
		},
		methods: {
			loadEvents() {
				this.loading = true
        let data = {
				  all: this.showOldEvents
        }

				this.$http.post('/api/calendars', data).then((response) => {
					this.artists = response.data.artists
					this.agents = response.data.agents
					this.cities = response.data.cities
					this.companies = response.data.companies
					this.venues = response.data.venues
					this.events = response.data.calendars
					this.users = response.data.users
          this.tours = response.data.tours

					_.each(this.events, (ev) => {
						ev.title = ev.calendar_title
					})


				}).finally(() => {
					this.loading = false
          this.defaultDate = this.$ls.get('calendar_date', null)
				})
			},
			async changeEventDate (data) {

				if (data.event.id > 0) {
					const start = new Date(data.event.start).toDateString();
					const end = new Date(data.event.end).toDateString();

					await this.$http.post('/Calendars/updateDate', {
						id: data.event.id,
						start: start,
						end: end
					})
				}
			},
			eventClick (data) {
				data.jsEvent.preventDefault();

				if (data.event.url) {
					window.open(data.event.url);
					return false;
				}
			},
			dateSelected (data) {
				this.createEventDialog = true
				this.createEventDate.start = moment(data.startStr).format("YYYY-MM-DD HH:mm:ss")
				this.createEventDate.end = moment(data.endStr).format("YYYY-MM-DD HH:mm:ss")

        this.localStorageDate(data)
			},
      localStorageDate(data) {
        this.$ls.set('calendar_date', data.view.currentStart)
        this.defaultDate = data.view.currentStart
      },
      localStoragePerformanceFilter(data) {
        this.$ls.set('show_only_performances', data)
      },
      localStorageCanceledFilter(data) {
        this.$ls.set('hide_canceled_performances', data)
      },
      localStorageArtistFilter(data) {
        this.$ls.set('artist_filter', data)
      }
		}
	}
</script>

<style lang='scss'>

	@import '~@fullcalendar/core/main.css';
	@import '~@fullcalendar/daygrid/main.css';
	@import '~@fullcalendar/list/main.css';

	.fc-scroller {
		overflow: auto !important;
		height: auto !important;
	}
</style>
