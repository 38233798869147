<template>
	<div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <v-card>
          <v-card-text>
            <v-select
              attach
              :items="contactCategories"
              v-model="value.category_id"
              item-text="name"
              label="Category"
              item-value="id"
              :error-messages="errors.collect('category_id')"
            >
            </v-select>
            <v-text-field
              label="Name"
              v-model="value.name"
              data-vv-name="name"
              :error-messages="errors.collect('name')"
            ></v-text-field>
            <v-text-field
              label="Phone"
              v-model="value.phone"
              data-vv-name="phone"
              :error-messages="errors.collect('name')"
            ></v-text-field>
            <v-text-field
              label="Email"
              v-model="value.email"
              v-validate="'required|max:200'"
              data-vv-name="email"
              :error-messages="errors.collect('email')"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
	export default {
		name: "ContactForm",
    props: {
		  contact: {
		    type: Object,
		    default: () => {}
      },
      contactCategories: {
        type: Array,
        default: () => []
      },
    },
    data() {
		  return {
		    value: {}
      }
    },
    mounted() {},
    watch: {
		  value: {
		    handler: function (value) {
          this.$emit('update:value', value)
        },
        deep: true
      },
      contact: {
        handler: function (value) {
          this.value = value
        },
        deep: true
      }
    }
	}
</script>

<style scoped>

</style>
