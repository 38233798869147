<template>
	<div class="container">
    <template v-if="tour.dates.length === 0 && loading">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </template>
    <template v-else>
    <div class="row">
      <div class="col-sm-12 mb-4">
        <v-card>
          <v-card-title>Tour</v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-sm-6">
                <v-text-field
                  label="Tour name"
                  v-model="tour.tour.name"
                  v-validate="'required|max:100'"
                  data-vv-name="name"
                  :error-messages="errors.collect('name')"
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-autocomplete attach v-model="tour.tour.company_id" :items="companies" item-text="company_name"
                                item-value="id" label="Company"></v-autocomplete>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-5">
        <v-card>
          <v-card-title>Dates</v-card-title>
          <v-card-text>
            <template v-for="(date, index) in tour.dates ">
              <div class="row">
                <div class="col-sm-1">
                  <v-btn dark fab small color="pink tiny" @click="tour.dates.splice(index, 1)">
                    <v-icon>remove</v-icon>
                  </v-btn>
                </div>
                <div class="col-sm-5">
                  <v-autocomplete attach v-model="date.city_id" :items="cities" item-text="name"
                                  item-value="id" label="City"></v-autocomplete>
                </div>
                <div class="col-sm-6">
                  <v-menu
                    ref="date.menu"
                    v-model="date.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date.date"
                        label="Date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date.date" no-title scrollable first-day-of-week="1">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="date.menu = false">Close</v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </template>

            <v-btn absolute dark fab bottom small right color="pink" @click.native="addTourDate">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-sm-7">
        <v-card>
          <v-card-title>Artists</v-card-title>
          <v-card-text>
            <div v-for="(artist, index) in tour.artists">
              <div class="row">
                <div class="col-md-1">
                  <v-btn dark fab small color="pink tiny" @click="tour.artists.splice(index, 1)">
                    <v-icon>remove</v-icon>
                  </v-btn>
                </div>
                <div class="col-md-4">
                  <v-autocomplete attach v-model="artist.id" :items="artists" item-text="artist_name"
                                  item-value="id" label="Artist"></v-autocomplete>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    label="Fee"
                    v-model="artist.fee"
                    v-validate="'required|max:100'"
                    data-vv-name="name"
                    :error-messages="errors.collect('artist.fee')"
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-select
                    v-model="artist.fee_type"
                    :items="showTypes"
                    menu-props="auto"
                    label="Select"
                    hide-details
                    single-line
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </div>
              </div>
            </div>
            <v-btn absolute dark fab bottom small right color="pink" @click.native="addArtist">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr>
        <v-alert
          type="error"
          :value="disabled"
        >
          You can not edit tour from mass editor if there is a changed event. To enable this editing delete the changed event.
        </v-alert>
        <v-btn :disabled="disabled" color="info" @click.native="$emit('save')" :loading="loading">{{ btnText }}</v-btn>
      </div>
    </div>
    </template>
	</div>
</template>

<script>
	export default {
		name: 'TourForm',
		components: {},
		mixins: [],
		props: {
		  artists: {
		    type: Array,
        default: () => []
      },
      cities: {
        type: Array,
        default: () => []
      },
      companies: {
        type: Array,
        default: () => []
      },
      disabled: {
		    type: Boolean,
        default: false
      },
		  loading: {
		    type: Boolean,
        default: false
      },
      tour: {
		    type: Object,
        default: () => {
          return {
            tour: {},
            artists: [],
            dates: [],
          }
        }
      },
      btnText: {
		    type: String,
        default: "Create Tour"
      },

    },
		data() {
			return {
        menu2: false,
        showTypes: [
          {'id': 1, 'name': 'Per show'},
          {'id': 2, 'name': 'Whole tour'}
        ],
      }
		},
		computed: {},
		watch: {
		  'tour': {
		    deep: true,
        handler: function (value) {
          this.$emit('update:tour', value)
        }
      }
    },
		mounted() {
		},
		methods: {
      addArtist() {
        this.tour.artists.push({})
      },
      addTourDate() {
        this.tour.dates.push({})
      },
    }
	}
</script>

<style></style>
