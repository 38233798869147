<template>
  <div class="container">
    <v-row>
      <div class="col-sm-12">
        <v-btn color="error" class="float-right" @click="remove(project.id)">Delete</v-btn>
        <v-btn color="secondary" class="mr-2 float-right" :to="{name: 'project-index'}">Back</v-btn>
      </div>
      <v-col cols="12">
    <v-card>
      <v-card-title>Project # {{project.name}}</v-card-title>
      <v-card-text>
        <v-text-field
          label="Project name"
          v-model="project.name"
          v-validate="'required|max:200'"
          data-vv-name="name"
          :error-messages="errors.collect('name')"
        ></v-text-field>

        <v-autocomplete attach v-model="project.client_id" :items="companies" item-text="company_name"
                        item-value="id" label="Client"></v-autocomplete>

        <v-autocomplete attach v-model="project.contractor_id" :items="companies" item-text="company_name"
                        item-value="id" label="Contractor"></v-autocomplete>

        <v-text-field
          label="Transaction ID"
          v-model="project.transaction_id"
          v-validate="'required|max:200'"
          data-vv-name="transaction_id"
          :error-messages="errors.collect('transaction_id')"
        ></v-text-field>

        <v-textarea
          label="Tags"
          v-model="project.tags"
          v-validate="'required|max:200'"
          data-vv-name="tags"
          :error-messages="errors.collect('tags')"
        ></v-textarea>

        <v-text-field
          label="Documents Folder"
          v-model="project.documents_folder"
          v-validate="'required|max:200'"
          data-vv-name="documents_folder"
          :error-messages="errors.collect('documents_folder')"
        ></v-text-field>

        <v-textarea
          label="Deal Memo"
          v-model="project.deal_memo"
          data-vv-name="deal_memo"
          :error-messages="errors.collect('deal_memo')"
        ></v-textarea>

        <v-autocomplete attach v-model="project.budget_id" :items="budgets" item-text="title"
                        item-value="id" label="Budget"></v-autocomplete>

        <v-autocomplete
          v-model="project.artists"
          :items="artists"
          item-text="artist_name"
          item-value="id"
          label="Select Artists"
          multiple
          chips
          :return-object="false"
        ></v-autocomplete>

        <v-btn color="info" @click.native="update" :loading="loading">Update Project</v-btn>
      </v-card-text>
    </v-card>

    <v-row>

      <BudgetShow v-if="budgetLoading" :id="project.budget_id" :showEdit="false"/>
      <template v-else>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </template>

    </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import BudgetShow from "../Budgets/BudgetShow";

  export default {
    name: 'ProjectShow',
    components: {BudgetShow},
    mixins: [],
    props: {
      id: {}
    },
    data() {
      return {
        project: {},
        loading: false,
        budgetLoading: false
      }
    },
    computed: {
      ...mapState('errors', ['errors']),
      ...mapState('company', ['companies']),
      ...mapState('artist', ['artists']),
      ...mapState('budget', ['budgets']),
    },
    watch: {},
    mounted() {
      this.fetchData();
      !this.companies.length ? this.getCompanies() : false
      !this.artists.length ? this.getArtists() : false
      !this.budgets.length ? this.getBudgets() : false
    },
    methods: {
      async fetchData() {
        const result = await this.fetchProject(parseInt(this.id))
        this.project = result.data;
        if(result.data){
          this.budgetLoading = true
        }
      },
      async update() {
        this.loading = true
        const result = await this.updateProject(this.project, this.id)
        if (result) {
          this.$router.push({'name': 'project-show', params: {id: result.data.id}})
        }
        this.loading = false
      },
      async remove(id) {
        const result = await this.deleteProject(id);
        if (result) {
          this.$router.push({'name': 'project-index'})
        }
      },
      ...mapActions('project', ['deleteProject']),
      ...mapActions('project', ['updateProject']),
      ...mapActions('company', ['getCompanies']),
      ...mapActions('artist', ['getArtists']),
      ...mapActions('budget', ['getBudgets']),
      ...mapActions('project', ['fetchProject']),
    }
  }
</script>

<style></style>
